import { Query } from 'history';
import React from 'react';
import { connect } from 'react-redux';
import {
  LoaderFunctionArgs,
  Navigate,
  NavigateFunction,
  RouteObject,
  useLoaderData,
} from 'react-router-dom';

import { UserRole } from '../constants';
import * as filterModule from '../modules/filters';
import * as filterActionCreators from '../modules/filters/action-creators';
import { AppState, AppStore } from '../modules/flux-store';
import { CurrentUser } from '../modules/user/action-creators';
import * as userActionCreators from '../modules/user/action-creators';
import { ImmutableCurrentUserElection } from '../modules/user/reducers';
import * as LocalStorageService from '../services/local-store-service';
import * as LoginService from '../services/login-service';
import { setUserContext } from '../services/sentry-service';
import { ApiCurrentUser } from '../services/user-service';
import { Awaitable, MapFromJs } from '../utils/types';

/**
 * Short for “renderer route.” (Abbreviated name so that this scans well in the
 * routes list.)
 *
 * Wraps React Router’s {@link RouteObject} definition to add type checking to
 * the loader pattern.
 *
 * This takes an object that matches {@link RouteObject} except instead of
 * `element` it has a `renderer` function. We use TypeScript to ensure that the
 * argument to the `renderer` matches the awaited type of the `loader` function.
 *
 * This uses {@link LoaderDataWrapper} in order to hide the use of React
 * Router’s {@link useLoaderData}.
 *
 * Overall this ergonomically addresses the two biggest design flaws with loader
 * functions, that their return values are not provided in a type-checked way
 * and that they’re only available through a hook without any seams for testing.
 */
export function rrte<T extends object>(
  route: Omit<RouteObject, 'element' | 'loader'> & {
    renderer: (props: T) => React.ReactElement;
    loader: (arg: LoaderFunctionArgs) => Awaitable<T>;
  }
): RouteObject {
  const { renderer, index, children, ...routeRest } = route;

  const out = {
    ...routeRest,
    element: <LoaderDataWrapper renderer={renderer} />,
  };

  // Slight shenanigans because RouteObject is a discriminated union. “index”
  // routes are not allowed to have children.
  return index
    ? {
        index,
        ...out,
      }
    : children
    ? {
        children,
        ...out,
      }
    : {
        ...out,
      };
}

/**
 * Typesafe wrapper around {@link useLoaderData}. Allows us to both put a type
 * on the data that comes out of `loader` as well as keep our `Page` components
 * from depending on `useLoaderData` during tests.
 */
export function LoaderDataWrapper<T>({
  renderer,
}: {
  renderer: (props: T) => React.ReactElement;
}): React.ReactElement {
  const props = useLoaderData() as T;

  return renderer(props);
}

/**
 * @see UserNotAvailableError
 */
export type UserNotAvailableReason = 'NOT_LOGGED_IN' | 'USER_LOADING_ERROR';

/**
 * @see UserNotReadyError
 */
export type UserNotReadyReason =
  | 'NO_ELECTIONS'
  | 'MISSING_EULA'
  | 'NOT_AUTHORIZED';

/**
 * Exceptions thrown by {@link loadCurrentUser} indicating why a user is not
 * able to be loaded.
 */
export class UserNotAvailableError extends Error {
  readonly reason;

  constructor(reason: UserNotAvailableReason) {
    super();

    this.reason = reason;
  }
}

/**
 * Exceptions thrown by {@link loadCurrentUser} indicating why a logged in user
 * is not “ready” for LBJ and shouldn’t have access to the whole UI.
 *
 * Unlike {@link UserNotAvailableError}, we do have a user object and possibly a
 * current user election in this case.
 */
export class UserNotReadyError extends Error {
  readonly reason;
  readonly currentUser;
  readonly currentUserElection;

  constructor(
    reason: UserNotReadyReason,
    currentUser: MapFromJs<ApiCurrentUser>,
    currentUserElection: ImmutableCurrentUserElection | null
  ) {
    super();

    this.reason = reason;
    this.currentUser = currentUser;
    this.currentUserElection = currentUserElection;
  }
}

/**
 * Call this from React Router loaders to get the current user and current user
 * election from the Redux store. If they’re not available, will either load
 * them or throw an exception.
 *
 * There are two types of exceptions that this code can throw:
 *
 * * {@link UserNotAvailableError} for when the user is logged out or there’s a
 *   backend error trying to get the current user data.
 * * {@link UserNotReadyError} for when the user is logged in, but their account
 *   is in a state that means they’re not ready for the app (e.g. haven’t signed
 *   the EULA).
 *
 * This function does not throw if the user hasn’t confirmed their email, as
 * that is handled via a redirect in LoggedInApp’s loader.
 *
 * @see UserAuthBoundary
 */
export async function loadCurrentUser(
  fluxStore: AppStore,
  opts: { allowedRoles?: UserRole[] } = {}
): Promise<{
  currentUser: MapFromJs<ApiCurrentUser>;
  currentUserElection: ImmutableCurrentUserElection;
}> {
  let currentUser: MapFromJs<ApiCurrentUser> | null = null;
  let currentUserElection: ImmutableCurrentUserElection | null = null;

  try {
    if (!LoginService.isLoggedIn()) {
      // We could throw a redirect here instead, but are instead doing a more
      // typed error to separate the concerns of “there’s no one logged in” from
      // “what do we do if no one is logged in?”
      throw new UserNotAvailableError('NOT_LOGGED_IN');
    }

    // We have to use the global flux store here because this is all happening
    // outside of the component rendering hierarchy.
    let { user } = fluxStore.getState();

    currentUser = user.currentUser.userData;
    currentUserElection = user.currentUser.currentUserElection;

    if (
      !currentUser ||
      (currentUserElection &&
        currentUserElection.get('id') !==
          LocalStorageService.getCurrentUserElectionId())
    ) {
      // The local storage check is designed to catch if the user has changed
      // their election in another tab and now loads a new route in this one.
      //
      // See: https://democrats.atlassian.net/browse/LBJ-87

      // TODO(fiona): handle de-duping this if nested routes call
      // `loadCurrentUser` simultaneously.
      await fluxStore.dispatch(userActionCreators.getCurrentUserAsync());

      user = fluxStore.getState().user;

      // getCurrentUserAsync resolves in both success and failure cases, so we
      // need to check the store to see the status.
      if (user.currentUser.requestErred || !user.currentUser.userData) {
        throw new UserNotAvailableError('USER_LOADING_ERROR');
      }

      currentUser = user.currentUser.userData;
      currentUserElection = user.currentUser.currentUserElection;

      // Sets up internal defaults for things like filtering by state.
      //
      // TODO(fiona): This should be handled at the page level.
      fluxStore.dispatch(
        filterActionCreators.setCurrentUserDefaults(currentUser)
      );
    }

    if (!currentUserElection) {
      throw new UserNotReadyError(
        'NO_ELECTIONS',
        currentUser,
        currentUserElection
      );
    } else if (!currentUser.get('signed_eula')) {
      throw new UserNotReadyError(
        'MISSING_EULA',
        currentUser,
        currentUserElection
      );
    } else if (
      opts.allowedRoles &&
      !opts.allowedRoles.includes(currentUser.get('role'))
    ) {
      throw new UserNotReadyError(
        'NOT_AUTHORIZED',
        currentUser,
        currentUserElection
      );
    }

    return { currentUser, currentUserElection };
  } finally {
    // Update Sentry, regardless of whether things succeeded or not.
    setUserContext(currentUser ? { id: currentUser.get('id').toString() } : {});
  }
}

export function storeAppFilters(
  fluxStore: AppStore,
  queryParams: Query,
  appSection: keyof filterActionCreators.FiltersBySection
) {
  const { dispatch } = fluxStore;
  const { setFiltersAsync } = filterModule.actionCreators;
  return dispatch(setFiltersAsync(queryParams, appSection));
}

/**
 * Returns the path to redirect a user to if they’re unauthorized for the place
 * they’ve gotten to.
 */
export function findHome(currentUser: MapFromJs<CurrentUser> | null) {
  if (!currentUser) {
    return '/';
  }

  const issueRoles: UserRole[] = [
    'vpd',
    'deputy_vpd',
    'boiler_room_leader',
    'boiler_room_user',
    'hotline_manager',
    'hotline_worker',
    'poll_observer',
    'view_only',
  ];

  const homeRoles: UserRole[] = ['poll_observer'];

  if (homeRoles.includes(currentUser.get('role'))) {
    return '/home';
  }

  if (issueRoles.includes(currentUser.get('role'))) {
    return '/issues';
  } else {
    return '/profile';
  }
}

/**
 * For restricted routes, redirect users back to root if they stumble upon areas
 * they should not.
 *
 * TODO(fiona): Make this throw a 403 and handle the redirect in an error
 * boundary.
 *
 * @return flag to indicate that the route handler should stop execution.
 */
export function redirectUnauthorizedUser(
  fluxStore: AppStore,
  navigate: NavigateFunction,
  allowedRoles: UserRole[] = ['vpd', 'deputy_vpd']
) {
  const { getState } = fluxStore;
  const currentUser = getState().user.currentUser.userData;

  if (!currentUser || !allowedRoles.includes(currentUser.get('role'))) {
    navigate(findHome(currentUser));
    return true;
  }

  return false;
}

/**
 * Wrapper component that renders its children if the user has one of the
 * allowed roles. Otherwise redirects away.
 */
const UnconnectedRequireRole: React.FunctionComponent<{
  currentUser: MapFromJs<ApiCurrentUser> | null;
  allowedRoles: UserRole[];
}> = ({ currentUser, allowedRoles, children }) => {
  if (!currentUser) {
    return <Navigate to="/" />;
  } else if (!allowedRoles.includes(currentUser.get('role'))) {
    return <Navigate to={findHome(currentUser)} />;
  } else {
    return <>{children}</>;
  }
};

export const RequireRole = connect((state: AppState) => ({
  currentUser: state.user.currentUser.userData,
}))(UnconnectedRequireRole);

/**
 * Component that directs users to the appropriate home page
 */
export const HomeRouteRedirector: React.FunctionComponent<{
  currentUser: MapFromJs<ApiCurrentUser>;
}> = ({ currentUser }) => {
  return <Navigate to={findHome(currentUser)} />;
};

/**
 * Route loader for {@link HomeRouteRedirector}.
 */
export async function loadHomeRouteRedirector(
  fluxStore: AppStore
): Promise<React.ComponentProps<typeof HomeRouteRedirector>> {
  const { currentUser } = await loadCurrentUser(fluxStore);

  return {
    currentUser,
  };
}
