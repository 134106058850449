import cx from 'classnames';
import { Immutable } from 'immer';
import React from 'react';
import { Item } from 'react-stately';

import { FilteredMenu, TextButton } from '../../../components/form';
import { EDAY_ROLES, EVOTE_ROLES } from '../../../constants';
import { ApiLocationHours } from '../../../services/assignment-service';

import { DateString, formatTime } from '../../../services/common';
import { ApiVolunteerAvailability } from '../../../services/volunteer-availability-service';
import { useLazyIterable } from '../../../utils/hooks';

import {
  AssignmentLocation,
  AssignmentRecord,
  AssignmentUser,
} from '../assignment-state';
import { DistanceMiLookupFunc } from '../assignment-utils';

import { ShiftBox } from './LocationDayCell';
import { PEOPLE_ROW_HEIGHT_PX } from './PersonRowHeader';

type LocationListItem = {
  location: AssignmentLocation;
  hasHours: boolean;
  distanceMi: number | null;
};

/**
 * Component for a table cell for a user’s assignments
 */
const PersonDayCell: React.FunctionComponent<{
  user: AssignmentUser;
  date: DateString;
  isEDay: boolean;
  /** Assignments on this particular day */
  assignments: AssignmentRecord[];
  /** Custom availability for this particular day */
  availability: Immutable<
    Pick<
      ApiVolunteerAvailability,
      | 'time'
      | 'custom_times'
      | 'allowed_location_cities'
      | 'allowed_location_county_slugs'
      | 'respect_travel_distance_tag'
      | 'availability_group_id'
    >
  >[];
  locationsById: Immutable<Map<number, AssignmentLocation>>;
  expanded?: boolean | undefined;
  selectLocationDay: (locationId: number, day: DateString) => void;
  doOpenNewAssignmentModal: (
    location: AssignmentLocation,
    hours: ApiLocationHours,
    defaultUserId: number
  ) => void;
  getDistanceMi: DistanceMiLookupFunc;
}> = ({
  user,
  date,
  isEDay,
  assignments,
  availability,
  selectLocationDay,
  locationsById,
  doOpenNewAssignmentModal,
  getDistanceMi,
  expanded = false,
}) => {
  // TODO(fiona): Handle rendering of suggested assignments differently.

  const showAddAssignment =
    (isEDay && !!user.tags.find((t) => t in EDAY_ROLES)) ||
    (!isEDay && !!user.tags.find((t) => t in EVOTE_ROLES)) ||
    availability.length > 0;

  const doAssignToLocation = React.useCallback(
    (locationId: number) => {
      const location = locationsById.get(locationId)!;

      selectLocationDay(locationId, date);
      doOpenNewAssignmentModal(
        location,
        // Only locations w/ hours on this day are in the list.
        location.hours.find((h) => h.date === date)!,
        user.id
      );
    },
    [selectLocationDay, locationsById, date, user.id, doOpenNewAssignmentModal]
  );

  if (expanded) {
    return (
      <div className="flex flex-col justify-around gap-0.5 text-base">
        {assignments.map((a, idx) => (
          <div key={idx} className="flex items-center gap-2">
            <div className="w-6">
              <ShiftBox shift={a} />
            </div>

            <span className="font-bold">
              {formatTime(a.startTime, { tight: true })}–
              {formatTime(a.endTime, { tight: true })}:{' '}
            </span>
            <span>
              {a.type === 'poll' &&
                ((loc) => (
                  <button
                    className="cursor-pointer border-none font-normal text-gray-700 hover:underline"
                    onClick={() => loc && selectLocationDay(loc.id, date)}
                  >
                    {loc ? loc.name : 'Unknown Location'}
                  </button>
                ))(locationsById.get(a.locationId))}

              {a.type === 'board_of_elections' &&
                'Board of Elections or Other Location'}

              {a.type === 'boiler_room' && 'Boiler Room'}
              {a.type === 'hotline_center' && 'Hotline Center'}
            </span>
          </div>
        ))}

        {showAddAssignment && (
          <PickLocationButton
            user={user}
            date={date}
            locationsById={locationsById}
            getDistanceMi={getDistanceMi}
            doAssignToLocation={doAssignToLocation}
          />
        )}
      </div>
    );
  } else {
    return (
      <div
        className="flex flex-col justify-around gap-0.5"
        style={{ height: PEOPLE_ROW_HEIGHT_PX - 16 }}
      >
        {assignments.map((a, idx) => (
          <button
            key={idx}
            className={cx(
              'relative mx-auto flex h-8 w-full max-w-[8em] items-center justify-around self-stretch rounded-sm text-center text-sm font-bold text-white',
              {
                'bg-primary': a.source !== 'auto',
                'bg-purple-500': a.source === 'auto',
              }
            )}
            onClick={() =>
              a.type === 'poll' ? selectLocationDay(a.locationId, date) : void 0
            }
          >
            {a.source === 'auto' && (
              <span className="material-icons absolute left-2 text-base">
                smart_toy
              </span>
            )}
            <span>
              {/* AM/PM should be deducable from context, and space is tight. */}
              {formatTime(a.startTime, { noAmPm: true })}–
              {formatTime(a.endTime, { noAmPm: true })}
            </span>
          </button>
        ))}

        {showAddAssignment && (
          <div className="flex flex-1 flex-col justify-around">
            <PickLocationButton
              locationsById={locationsById}
              user={user}
              date={date}
              getDistanceMi={getDistanceMi}
              doAssignToLocation={doAssignToLocation}
            />
          </div>
        )}

        {availability.length > 0 && (
          <div className="flex items-center justify-around self-center rounded bg-gray-300 py-0.5 px-2">
            {availability
              .flatMap((a) =>
                a.time === 'custom'
                  ? (a.custom_times ?? []).map(
                      ([t1, t2]) =>
                        `${formatTime(t1, {
                          tight: true,
                          noAmPm: availability.length > 1,
                        })}–${formatTime(t2, {
                          tight: true,
                          noAmPm: availability.length > 1,
                        })}`
                    )
                  : [a.time]
              )
              .map((str) => (
                <span className="text-xs" key={str}>
                  {str}
                </span>
              ))}
          </div>
        )}
      </div>
    );
  }
};

/**
 * Component that makes a “+ Assign” button that pops up a filterable menu of
 * locations.
 */
const PickLocationButton: React.FunctionComponent<{
  user: AssignmentUser;
  locationsById: Immutable<Map<number, AssignmentLocation>>;
  date: DateString;
  getDistanceMi: DistanceMiLookupFunc;
  doAssignToLocation: (locationId: number) => void;
}> = ({ user, locationsById, date, getDistanceMi, doAssignToLocation }) => {
  // useLazyIterable so that we only run this when the menu is clicked open,
  // rather than for every user cell all the time.
  const items = useLazyIterable<LocationListItem>(
    () =>
      [...locationsById.values()]
        .map<LocationListItem>((location) => ({
          location,
          hasHours: !!location.hours.find((h) => h.date === date),
          distanceMi: getDistanceMi(location.id, user.id),
        }))
        .sort((itemA, itemB) => {
          // Sort is by distance, then alphabetically.
          const distanceA = itemA.distanceMi && Math.round(itemA.distanceMi);
          const distanceB = itemB.distanceMi && Math.round(itemB.distanceMi);

          if (distanceA === distanceB) {
            return itemA.location.name === itemB.location.name
              ? 0
              : itemA.location.name < itemB.location.name
              ? -1
              : 1;
          } else if (distanceA !== null && distanceB !== null) {
            return distanceA < distanceB ? -1 : 1;
          } else if (distanceA !== null) {
            // distanceB is null
            return -1;
          } else {
            return 1;
          }
        }),

    [locationsById, user.id, date]
  );

  return (
    <FilteredMenu
      shouldVirtualize
      itemHeight={50}
      contentWidthPx={600}
      selectionStyle="menu"
      filterFieldProps={{}}
      // If there’s no name filtering, we only show open locations that are
      // within the user’s distance. If you type in the filter box, we then
      // filter all locations.
      filterItem={(val, item) =>
        val === ''
          ? item.hasHours &&
            item.distanceMi !== null &&
            typeof user.max_distance_miles === 'number' &&
            item.distanceMi <= user.max_distance_miles
          : item.location.name.toLowerCase().startsWith(val.toLowerCase())
      }
      noMatchMessage="No locations found."
      items={items}
      onAction={(key) => doAssignToLocation(Number(key))}
      trigger={(props, ref) => (
        <TextButton
          buttonRef={ref}
          {...props}
          size="small"
          className="my-1 self-center"
        >
          + Assign
        </TextButton>
      )}
    >
      {({ location, distanceMi }) => (
        <Item key={location.id} textValue={location.name}>
          <div className="flex">
            <div className="flex flex-1 flex-col gap-0.5 text-base leading-none text-gray-700">
              <div className="font-bold">{location.name}</div>
              <div>
                {location.address}, {location.city}, {location.county.name}{' '}
                County
              </div>
            </div>

            {distanceMi !== null && (
              <div
                className={cx('leading-0 flex gap-1', {
                  'text-red-700':
                    user.max_distance_miles &&
                    distanceMi > user.max_distance_miles,
                })}
              >
                <span className="italic">{Math.round(distanceMi)}mi</span>
                {user.max_distance_miles &&
                  distanceMi > user.max_distance_miles && (
                    <span
                      className="material-icons text-base"
                      title={`Outside the volunteer’s ${Math.round(
                        user.max_distance_miles
                      )}mi distance preference`}
                    >
                      warning
                    </span>
                  )}
              </div>
            )}
          </div>
        </Item>
      )}
    </FilteredMenu>
  );
};

export default PersonDayCell;
