import { Immutable } from 'immer';
import Papa from 'papaparse';
import React from 'react';

import { FileActionButton, TextButton } from '../../../components/form';
import { usePapaParse } from '../../../components/hooks/papa-parse';
import { ModalDialog } from '../../../components/layout';
import { ApiLocationBulkUpdate } from '../../../services/assignment-service';

import { AssignmentLocation } from '../assignment-state';

import LocationMetadataResults from './LocationMetadataResults';

/**
 * Content for the metadata dialog when it’s first opened up and the user hasn’t
 * selected a file yet.
 */
const LocationMetadataDialogEmptyState: React.FunctionComponent<{
  isParsing: boolean;
  setCsvFile: (file: File | null) => void;
  downloadLocationCsv: () => void;
  csvError: Error | null;
}> = ({ isParsing, setCsvFile, downloadLocationCsv, csvError }) => {
  // TOOD(fiona): Bring in prose Tailwind plugin to style <p>s implicitly.
  const pClasses = 'my-2 text-legacy-base';

  return (
    <div className="max-w-2xl">
      <div>
        <p className={pClasses}>
          Use this dialog to upload a CSV with information about your state’s
          polling locations, such as your priority rankings or language
          requirements.
        </p>

        <p className={pClasses}>
          <strong>Note:</strong> You cannot use this form to change location
          names, addresses, or add or remove locations. That must be done
          through the DNC’s voting location pipeline.
        </p>

        <p className={pClasses}>
          Your CSV file should have columns at least for location name, address,
          and city, as well as a column for the location’s priority (lower is
          more important). Including more columns (ZIP code, county name) will
          improve the matching process. The first row of your CSV should be
          column names.
        </p>
      </div>

      <div className="item-center mt-4 flex justify-around">
        <div className="flex gap-4">
          <TextButton onPress={() => downloadLocationCsv()}>
            Download CSV Template
          </TextButton>

          <FileActionButton
            accept=".csv"
            onFiles={([file]) => setCsvFile(file)}
            isDisabled={isParsing}
          >
            Upload CSV
          </FileActionButton>
        </div>
      </div>

      {csvError && (
        // TODO(fiona): Make this better
        <p className={`${pClasses} text-error `}>
          <strong>There was an error loading this file:</strong> <br />
          {csvError.toString()}
        </p>
      )}
    </div>
  );
};

export const LocationMetadataDialogView: React.FunctionComponent<{
  doClose: () => void;
  applyBulkUpdates: (updates: ApiLocationBulkUpdate[]) => void;

  isParsing: boolean;
  setCsvFile: (file: File | null) => void;
  csvError: Error | null;
  downloadLocationCsv: () => void;

  locationsById: Immutable<Map<number, AssignmentLocation>>;
  csvResults: Papa.ParseResult<{ [key: string]: string }> | null;
  csvFileName: string | null;
}> = ({
  isParsing,
  doClose,
  setCsvFile,
  csvError,
  csvResults,
  downloadLocationCsv,
  csvFileName,
  locationsById,
  applyBulkUpdates,
}) => {
  return (
    <ModalDialog
      title="Upload Location Information"
      showClose
      doClose={doClose}
    >
      {csvResults ? (
        <LocationMetadataResults
          locationsById={locationsById}
          csvResults={csvResults}
          csvFileName={csvFileName}
          resetCsvFile={() => setCsvFile(null)}
          applyBulkUpdates={applyBulkUpdates}
          doClose={doClose}
        />
      ) : (
        <LocationMetadataDialogEmptyState
          isParsing={isParsing}
          setCsvFile={setCsvFile}
          downloadLocationCsv={downloadLocationCsv}
          csvError={csvError}
        />
      )}
    </ModalDialog>
  );
};

/**
 * Renders an open dialog that asks for a metadata upload and also offers to
 * download a template.
 */
const LocationMetadataDialog: React.FunctionComponent<{
  locationsById: Immutable<Map<number, AssignmentLocation>>;
  doClose: () => void;
  /**
   * Called after the dialog has commited bulk updates to the backend, to update the
   * local data store.
   */
  applyBulkUpdates: (updates: ApiLocationBulkUpdate[]) => void;
}> = ({ locationsById, doClose, applyBulkUpdates }) => {
  const [csvState, setCsvFile] = usePapaParse({ header: true });

  const downloadLocationCsv = () => {
    const csvStr = Papa.unparse(
      [...locationsById.values()].map((loc) => ({
        'LBJ ID': loc.id,
        Name: loc.name,
        Address: loc.address,
        City: loc.city,
        'ZIP Code': loc.zipcode,
        'County Name': loc.county.name,
        Rank: loc.state_rank,
      }))
    );

    const csvBlob = new Blob([csvStr], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);

    const a = document.createElement('a');
    a.href = csvUrl;
    a.download = 'locations.csv';
    a.click();

    URL.revokeObjectURL(csvUrl);
  };

  return (
    <LocationMetadataDialogView
      doClose={doClose}
      setCsvFile={setCsvFile}
      downloadLocationCsv={downloadLocationCsv}
      isParsing={csvState.status === 'parsing'}
      csvError={csvState.error ?? null}
      csvResults={csvState.results ?? null}
      csvFileName={csvState.fileName ?? null}
      locationsById={locationsById}
      applyBulkUpdates={applyBulkUpdates}
    />
  );
};

export default LocationMetadataDialog;
