import { FORM_ERROR } from 'final-form';
import { Immutable } from 'immer';
import Papa from 'papaparse';
import React from 'react';
import { Item, Section } from 'react-stately';
import useSWR from 'swr';

import {
  ActionButton,
  FileActionButton,
  FormLabel,
  Radio,
  RadioGroup,
  Select,
  TextButton,
} from '../../../components/form';
import { usePapaParse } from '../../../components/hooks/papa-parse';
import { ModalDialog } from '../../../components/layout';
import { DjangoRestValidationError } from '../../../services/api-client';
import * as VolunteerAvailabilityService from '../../../services/volunteer-availability-service';
import type {
  ApiVolunteerAvailabilityGroup,
  ApiVolunteerAvailability,
} from '../../../services/volunteer-availability-service';

import { AssignmentUser } from '../assignment-state';
import { LocationMunicipalities } from '../location-table/LocationFilter';

import AvailabilityGroupForm, {
  SaveAvailabiltyGroupFn,
} from './AvailabilityGroupForm';
import VolunteerAvailabilityDialogResults, {
  UploadAvailabilityFn,
} from './VolunteerAvailabilityDialogResults';

type VolunteerAvaliabilityDialogStep =
  | 'pick-group'
  | 'upload-csv'
  | 'check-results';

export type VolunteerAvailabilityCsvFormat = 'van-signup' | 'custom';

/**
 * Internal view for rendering {@link VolunteerAvailabilityDialog}.
 *
 * Factored out to allow for stories to show different versions.
 */
export const VolunteerAvailabilityDialogView: React.FunctionComponent<{
  step: VolunteerAvaliabilityDialogStep;
  setStep: (step: VolunteerAvaliabilityDialogStep) => void;

  doClose: () => void;

  municipalities: LocationMunicipalities;
  usersById: Immutable<Map<number, AssignmentUser>>;

  setCsvFile: (
    file: File | null,
    csvFormat: VolunteerAvailabilityCsvFormat
  ) => void;
  isParsing: boolean;
  csvFormat: VolunteerAvailabilityCsvFormat;
  csvResults: Papa.ParseResult<{ [key: string]: string }> | null;
  csvFileName: string | null;
  csvError: Error | null;

  availabilityGroups: ApiVolunteerAvailabilityGroup[] | undefined;
  saveAvailabilityGroup: SaveAvailabiltyGroupFn;
  selectedAvailabilityGroupId: number | 'new' | null;
  setSelectedAvailabilityGroupId: (id: number | 'new') => void;
  uploadAvailability: UploadAvailabilityFn;
}> = ({
  step,
  setStep,

  isParsing,
  doClose,
  setCsvFile,
  csvError,
  csvFormat,
  csvResults,
  csvFileName,
  usersById,

  municipalities,
  availabilityGroups,
  saveAvailabilityGroup,
  selectedAvailabilityGroupId,
  setSelectedAvailabilityGroupId,
  uploadAvailability,
}) => {
  const selectedAvailabilityGroup =
    selectedAvailabilityGroupId === 'new'
      ? null
      : availabilityGroups?.find((g) => g.id === selectedAvailabilityGroupId) ??
        null;

  return (
    <ModalDialog
      title="Upload Volunteer Availability"
      showClose
      doClose={doClose}
      aboveCenter
    >
      <div className="flex w-[650px] flex-col gap-4 overflow-hidden">
        {step === 'pick-group' && (
          <>
            <VolunteerGroupSelector
              availabilityGroups={availabilityGroups}
              selectedAvailabilityGroupId={selectedAvailabilityGroupId}
              setSelectedAvailabilityGroupId={setSelectedAvailabilityGroupId}
            />

            {selectedAvailabilityGroupId !== null && availabilityGroups && (
              <AvailabilityGroupForm
                // Use key to ensure that the form completely refreshes when
                // switching groups.
                key={selectedAvailabilityGroupId}
                municipalities={municipalities}
                availabilityGroup={selectedAvailabilityGroup}
                saveAvailabilityGroup={saveAvailabilityGroup}
                onCancel={doClose}
                onNext={() => {
                  setStep('upload-csv');
                }}
              />
            )}
          </>
        )}

        {step === 'upload-csv' && selectedAvailabilityGroup && (
          <VolunteerAvailabilityChooseCsvFormat
            isParsing={isParsing}
            availabilityGroup={selectedAvailabilityGroup}
            setCsvFile={setCsvFile}
            onBack={() => setStep('pick-group')}
            csvError={csvError}
          />
        )}

        {step === 'check-results' && !isParsing && selectedAvailabilityGroup && (
          <>
            {csvError ? (
              <div>
                <p>There was an error loading that csv: {csvError}</p>

                <TextButton onPress={() => setStep('upload-csv')}>
                  Try Again
                </TextButton>
              </div>
            ) : csvResults === null ? (
              <div>
                <p>No results when parsing that CSV</p>

                <TextButton onPress={() => setStep('upload-csv')}>
                  Try Again
                </TextButton>
              </div>
            ) : (
              <VolunteerAvailabilityDialogResults
                usersById={usersById}
                availabilityGroup={selectedAvailabilityGroup}
                isParsing={isParsing}
                csvFileName={csvFileName}
                csvFormat={csvFormat}
                csvResults={csvResults}
                onBack={() => setStep('upload-csv')}
                uploadAvailability={uploadAvailability}
              />
            )}
          </>
        )}
      </div>
    </ModalDialog>
  );
};

/**
 * Select box for the top of the form to choose an availability group.
 */
const VolunteerGroupSelector: React.FunctionComponent<{
  availabilityGroups: ApiVolunteerAvailabilityGroup[] | undefined;
  selectedAvailabilityGroupId: number | 'new' | null;
  setSelectedAvailabilityGroupId: (id: number | 'new') => void;
}> = ({
  availabilityGroups,
  selectedAvailabilityGroupId,
  setSelectedAvailabilityGroupId,
}) => {
  return (
    <div className="flex">
      {availabilityGroups === undefined && (
        <div className="flex items-center gap-2">
          <div className="lbj-loading-icon" />{' '}
          <span>Loading availability groups…</span>
        </div>
      )}

      {availabilityGroups && (
        <div className="flex items-center gap-2">
          <FormLabel
            type="label"
            id="availability-group-select-label"
            htmlFor="availability-group-select"
          >
            Upload to:
          </FormLabel>

          <Select<{
            name: string;
            items: { id: string | number; name: string }[];
          }>
            className="w-72"
            items={[
              { name: 'existing', items: availabilityGroups },
              {
                name: 'new',
                items: [{ id: 'new', name: 'Create new group…' }],
              },
            ]}
            id="availability-group-select"
            aria-labelledby="availability-group-select-label"
            placeholder="Select a group…"
            selectedKey={selectedAvailabilityGroupId}
            onSelectionChange={(key) =>
              setSelectedAvailabilityGroupId(key as number | 'new')
            }
          >
            {(section) => (
              <Section key={section.name} items={section.items}>
                {(group) => <Item>{group.name}</Item>}
              </Section>
            )}
          </Select>
        </div>
      )}
    </div>
  );
};

/**
 * Availability dialog step for selecting which format to use for the CSV
 * upload.
 */
const VolunteerAvailabilityChooseCsvFormat: React.FunctionComponent<{
  isParsing: boolean;
  availabilityGroup: ApiVolunteerAvailabilityGroup;
  setCsvFile: (
    file: File | null,
    csvFormat: VolunteerAvailabilityCsvFormat
  ) => void;
  onBack: () => void;
  csvError: Error | null;
}> = ({ isParsing, availabilityGroup, onBack, setCsvFile, csvError }) => {
  // TOOD(fiona): Bring in prose Tailwind plugin to style <p>s implicitly.
  const pClasses = 'my-2 text-legacy-base';

  const [csvFormat, setCsvFormat] =
    React.useState<VolunteerAvailabilityCsvFormat | null>(null);

  return (
    <div className="flex max-w-2xl flex-col gap-4">
      <div>
        <p className={pClasses}>
          Use this dialog to upload a CSV with volunteer availability
          information.
        </p>
      </div>

      <div>
        <strong>Uploading to:</strong> {availabilityGroup.name} [
        <TextButton onPress={() => onBack()}>change</TextButton>]
      </div>

      <div>
        <RadioGroup
          label="Choose an import format"
          value={csvFormat ?? ''}
          onChange={(newFormat) =>
            setCsvFormat(newFormat as VolunteerAvailabilityCsvFormat)
          }
        >
          <Radio value="van-signup">VAN Event Participant List</Radio>
          <Radio value="custom" isDisabled>
            {/* Need a <span> to preserve space before <i> because container is flex */}
            <span>
              Freeform <i>(not currently supported)</i>
            </span>
          </Radio>
        </RadioGroup>
      </div>

      <div className="item-center flex justify-end gap-2">
        <ActionButton role="secondary" onPress={() => onBack()}>
          Go Back
        </ActionButton>

        <FileActionButton
          accept=".csv"
          // We know csvFormat will exist because otherwise the button is
          // disabled.
          onFiles={([file]) => setCsvFile(file, csvFormat!)}
          isDisabled={isParsing || csvFormat === null}
        >
          Choose CSV File…
        </FileActionButton>
      </div>

      {csvError && (
        // TODO(fiona): Make this better
        <p className={`${pClasses} text-error `}>
          <strong>There was an error loading this file:</strong> <br />
          {csvError.toString()}
        </p>
      )}
    </div>
  );
};

/**
 * Renders an open dialog for uploading volunteer availability.
 */
const VolunteerAvailabilityDialog: React.FunctionComponent<{
  municipalities: LocationMunicipalities;
  usersById: Immutable<Map<number, AssignmentUser>>;
  doClose: () => void;
  updateAvailabilyForGroup: (
    availabilityGroupId: number,
    availability: ApiVolunteerAvailability[]
  ) => void;
}> = ({ municipalities, usersById, doClose, updateAvailabilyForGroup }) => {
  const [csvState, setCsvFile] = usePapaParse({ header: true });

  const [selectedAvailabilityGroupId, setSelectedAvailabilityGroupId] =
    React.useState<number | 'new' | null>(null);

  const [availabilityGroups, saveAvailabilityGroup] = useAvailabilityGroups({
    onSave: (g) => setSelectedAvailabilityGroupId(g.id),
  });

  const [step, setStep] =
    React.useState<VolunteerAvaliabilityDialogStep>('pick-group');

  const [csvFormat, setCsvFormat] =
    React.useState<VolunteerAvailabilityCsvFormat>('custom');

  return (
    <VolunteerAvailabilityDialogView
      step={step}
      setStep={setStep}
      doClose={doClose}
      setCsvFile={(file, csvFormat) => {
        setCsvFormat(csvFormat);
        setCsvFile(file);
        setStep('check-results');
      }}
      isParsing={csvState.status === 'parsing'}
      csvFormat={csvFormat}
      csvError={csvState.error ?? null}
      csvResults={csvState.results ?? null}
      csvFileName={csvState.fileName ?? null}
      municipalities={municipalities}
      usersById={usersById}
      availabilityGroups={availabilityGroups}
      saveAvailabilityGroup={saveAvailabilityGroup}
      selectedAvailabilityGroupId={selectedAvailabilityGroupId}
      setSelectedAvailabilityGroupId={setSelectedAvailabilityGroupId}
      uploadAvailability={async (availabilityGroup, availability) => {
        const newAvailability = (
          await VolunteerAvailabilityService.uploadAvailability(
            availabilityGroup.id,
            availability
          )
        ).availability;

        updateAvailabilyForGroup(availabilityGroup.id, newAvailability);
        doClose();
      }}
    />
  );
};

/**
 * Factored out hook that loads availability groups via useSWR and provides a
 * function to save new or updated ones.
 *
 * TODO(fiona): Possibly replace this with getting this data from the store.
 */
function useAvailabilityGroups({
  onSave,
}: {
  /** Called after a successful save */
  onSave?: (group: ApiVolunteerAvailabilityGroup) => void;
} = {}): [ApiVolunteerAvailabilityGroup[] | undefined, SaveAvailabiltyGroupFn] {
  const { data: availabilityGroups, mutate: setAvailabilityGroups } = useSWR(
    [
      'VolunteerAvailabilityService#getAvailabilityGroupList',
      // TODO(fiona): Handle pagination of availability group list
      { offset: 0, size: 200 },
    ] as const,

    async ([_, param]) => {
      return (
        await VolunteerAvailabilityService.getAvailabilityGroupList(param)
      ).availability_groups;
    }
  );

  /**
   * We need this function to create/update an availability group, and then,
   * with the result, update our local useSWR cache to include the change.
   */
  const saveAvailabilityGroup = React.useCallback<SaveAvailabiltyGroupFn>(
    async (groupId, group) => {
      try {
        let updatedGroup: ApiVolunteerAvailabilityGroup;

        if (groupId) {
          updatedGroup =
            await VolunteerAvailabilityService.updateAvailabilityGroup(
              groupId,
              group
            );
        } else {
          updatedGroup =
            await VolunteerAvailabilityService.createAvailabilityGroup(group);
        }

        // Update our useSWR cache of availability groups with the new result.
        // Re-sorts the items by name to match what the server does.
        const newAvailabilityGroups = (availabilityGroups ?? []).filter(
          (g) => g.id !== updatedGroup.id
        );
        newAvailabilityGroups.push(updatedGroup);
        newAvailabilityGroups.sort((g1, g2) => {
          if (g1.name < g2.name) {
            return -1;
          } else if (g1.name > g2.name) {
            return 1;
          } else {
            return 0;
          }
        });

        setAvailabilityGroups(newAvailabilityGroups);

        onSave?.(updatedGroup);

        // Undefined is success, returning values is an error.
        return undefined;
      } catch (e) {
        if (e instanceof DjangoRestValidationError) {
          return e.errorMessagesByField;
        } else {
          return { [FORM_ERROR]: [`An error occurred: ${e}`] };
        }
      }
    },
    [availabilityGroups, setAvailabilityGroups, onSave]
  );

  return [availabilityGroups, saveAvailabilityGroup];
}

export default VolunteerAvailabilityDialog;
