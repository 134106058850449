import { NewApiAssignment } from '../../services/assignment-service';
import { DateString, TimeString } from '../../services/common';
import { ApiElection } from '../../services/lbj-shared-service';

export type BulkAssignment = {
  email: string;
  locationId: number;
  type: 'poll';
  date: DateString;
  time: 'morning' | 'afternoon' | 'all day' | string;
  place: 'inside' | 'outside';
  otherFields: { [key: string]: string };
};

/**
 * Builds a {@link NewApiAssignment} entry
 */
export function bulkToApiAssignment(
  userIdsByCsvEmail: ReadonlyMap<string, number>,
  currentElection: Pick<
    ApiElection,
    | 'ev_shift_start_time'
    | 'ev_shift_change_time'
    | 'ev_shift_end_time'
    | 'eday_shift_start_time'
    | 'eday_shift_end_time'
    | 'eday_shift_change_time'
    | 'election_date'
  >,
  a: BulkAssignment
): NewApiAssignment {
  const isEday = a.date === currentElection.election_date;

  const shiftStartTime = isEday
    ? currentElection.eday_shift_start_time!
    : currentElection.ev_shift_start_time!;

  const shiftChangeTime = isEday
    ? currentElection.eday_shift_change_time!
    : currentElection.ev_shift_change_time!;

  const shiftEndTime = isEday
    ? currentElection.eday_shift_end_time!
    : currentElection.ev_shift_end_time!;

  let startTime: TimeString;
  let endTime: TimeString;

  switch (a.time.toLowerCase()) {
    case 'morning':
      startTime = shiftStartTime;
      endTime = shiftChangeTime;
      break;

    case 'afternoon':
      startTime = shiftChangeTime;
      endTime = shiftEndTime;
      break;

    case 'all day':
      startTime = shiftStartTime;
      endTime = shiftEndTime;
      break;

    default: {
      // Matches "0830-1645" 24h custom time range format
      const timeMatch = a.time.trim().match(/^(\d{2})(\d{2})-(\d{2})(\d{2})$/);

      if (!timeMatch) {
        throw new Error(`Unrecognized time value: ${a.time}`);
      }

      startTime = `${timeMatch[1]}:${timeMatch[2]}:00` as TimeString;
      endTime = `${timeMatch[3]}:${timeMatch[4]}:00` as TimeString;
    }
  }

  return {
    type: a.type,
    user: userIdsByCsvEmail.get(a.email)!,
    location: a.locationId,
    shift_date: a.date,
    start_time: startTime,
    end_time: endTime,
  };
}
