import { useResizeObserver } from '@react-aria/utils';
import { castDraft, Immutable, produce } from 'immer';
import { chunk } from 'lodash';
import React from 'react';
import { unstable_usePrompt as usePrompt } from 'react-router-dom';
import { TableBody, TableHeader } from 'react-stately';

import { PageHeader, PageTitle2 } from '../../components/common';
import { ActionButton, IconButton, TextButton } from '../../components/form';
import LoadingOverlay from '../../components/presentational/lbj/loading';
import Toast, { useToast } from '../../components/presentational/lbj/toast';
import { AppStore } from '../../modules/flux-store';
import { loadCurrentUser } from '../../route-handlers/app';
import trackGoogleAnalyticsEvent, {
  RawAnalyticsEvent,
} from '../../services/analytics-service';
import { ApiNotFoundError } from '../../services/api-client';
import type { ApiAssignmentCrudResponse } from '../../services/assignment-service';
import * as AssignmentService from '../../services/assignment-service';
import {
  ApiLocationHours,
  ApiLocationHourUpdates,
} from '../../services/assignment-service';
import { DateString, TimeString } from '../../services/common';
import {
  ApiElection,
  ApiElectionDay,
  ApiLocationTierConfiguration,
  ApiUserTag,
  getUserTags,
} from '../../services/lbj-shared-service';
import * as sentryService from '../../services/sentry-service';

import { assertUnreachable } from '../../utils/types';

import AssignmentLiveModeButtons from './AssignmentLiveModeButtons';
import AssignmentLoadingStatusLine from './AssignmentLoadingStatusLine';
import { AssignmentTable } from './AssignmentTable';

import {
  DEFAULT_ASSIGNMENT_STATE,
  AssignmentRecord,
  assignmentReducer,
  findExistingAssignments,
  getAssignmentsByLocationIdForDates,
  getUsersForLocationAssignment,
  getAssignmentsByUserIdForDates,
  LOADING_STAGE_TO_NOUN,
  AssignmentLocation,
} from './assignment-state';
import {
  getFilteredSortedLocationList,
  getFilteredSortedPersonList,
  getUserLanguageTagInfos,
  LocationFilterOptions,
  LocationGroupBy,
  LocationSortBy,
  LocationTableRow,
  makeDistanceMiLookupFunc,
  PersonFilterOptions,
  PersonGroupBy,
  PersonSortBy,
  useLoadInitialAssignmentState,
  shiftCountsForLocation,
  mergeLocationHours,
  DEFAULT_LOCATION_FILTERS,
  getVolunteerAttributes,
} from './assignment-utils';
import AutoAssignmentDialog from './auto-assignment-dialog/AutoAssignmentDialog';
import EditHoursModal from './location-day-detail/EditHoursModal';
import LocationDayDetailPane from './location-day-detail/LocationDayDetailPane';
import AssignmentModal from './location-table/AssignmentModal';
import { municipalitiesFromLocations } from './location-table/LocationFilter';
import { LOCATION_ROW_HEIGHT_PX } from './location-table/LocationRowHeader';
import {
  AssignmentUserCounts,
  DAY_COLUMN_MIN_EXPANDED_WIDTH_PX,
  DAY_COLUMN_MIN_WIDTH_PX,
  HEADER_ROW_HEIGHT_PX,
} from './location-table/LocationTableHeader';
import LocationTableToolbar from './location-table/LocationTableToolbar';
import { PEOPLE_ROW_HEIGHT_PX } from './location-table/PersonRowHeader';
import {
  AssignmentTableColumn,
  makeAssignmentTableColumnRenderer,
  makeLocationCellRenderer,
  makeLocationDayKey,
  makePeopleCellRenderer,
  parseLocationDayKey,
  PeopleTableRow,
} from './location-table/location-table-utils';
import LocationMetadataDialog from './metadata-dialog/LocationMetadataDialog';
import VolunteerAvailabilityDialog from './volunteer-availability-dialog/VolunteerAvailabilityDialog';

export type TableViewMode = 'locations' | 'people';

const DEFAULT_VISIBLE_DAYS = 7;

type AssignmentPageModal =
  | {
      /**
       * Modal for uploading a CSV of metadata info. AKA “Upload Rankings CSV”
       */
      type: 'metadata';
    }
  | {
      type: 'volunteer-availability';
    }
  | {
      type: 'auto-assignment';
    }
  | {
      type: 'edit-hours';
      location: AssignmentLocation;
      hours: ApiLocationHours;
    }
  | {
      type: 'edit-assignment';

      /** Without hours since they’re passed separately. */
      location: Omit<AssignmentLocation, 'hours'>;

      /** Specific {@link ApiLocationHours} for this location/day */
      hours: ApiLocationHours;

      /** Existing assignment to edit. */
      assignment: (AssignmentRecord & { type: 'poll' }) | null;

      defaultStartTime: TimeString | null;
      defaultEndTime: TimeString | null;

      /** Used when assigning from the People view. */
      defaultUserId: number | null;
    };

export type AssignmentPageGridBulkEditMode = {
  /** Editing # of shifts at location/day cells w/ +/- buttons. */
  type: 'shifts';

  /** The updated {@link ApiLocationHours} values for edited cells. */
  hoursByLocationId: Immutable<Map<number, ApiLocationHours[]>>;

  /** True if we’re commiting to the backend. */
  isSaving: boolean;
};

export type FocusedLocationDayPane = {
  type: 'location-day';

  /**
   * Index into the election’s `days` property for the day corresponding to the
   * selection in the detail pane. This is separate from the `calendar` property
   * on UX state so that when you close the detail pane the calendar you had
   * settings previously selected are maintained.
   */
  dayIndex: number;
  locationId: number | null;
};

/**
 * State for all of the dialog opening and other modes that the UI may get into.
 *
 * Combining it together so that it’s not spread across a bunch of different
 * `useState`s, and so we can enforce some invariants (_e.g._ only one modal
 * open at a time.)
 */
export type AssignmentPageUxState = Immutable<{
  /**
   * Controls what dates are shown as columns in the table.
   */
  calendar: {
    visibleDaysStartIndex: number;
    visibleDaysLength: number;
  };

  detailPane: FocusedLocationDayPane | null;
  bulkEditMode: AssignmentPageGridBulkEditMode | null;
  modal: AssignmentPageModal | null;
}>;

export type BulkEditModeType = AssignmentPageGridBulkEditMode['type'];

// Omit 'calendar' because it needs to be built dynamically based on the
// election’s dates.
export const DEFAULT_ASSIGNMENT_PAGE_UX_STATE: Omit<
  AssignmentPageUxState,
  'calendar'
> = {
  detailPane: null,
  bulkEditMode: null,
  modal: null,
};

export type CalendarMovement = 'prevPage' | 'prevDay' | 'nextDay' | 'nextPage';

export type AssignmentPageUxAction =
  | {
      type: 'OPEN_FOCUSED_LOCATION_DAY_PANE';

      dayIndex: number;
      locationId?: number | null | undefined;
    }
  | {
      type: 'CLOSE_DETAIL_PANE';
    }
  | {
      /**
       * If no location ID is set for the “single day” mode, updates it to the
       * first row’s location ID. Otherwise checks to make sure that the
       * currently-selected location ID is in the list of rows.
       */
      type: 'SYNC_SINGLE_DAY_LOCATION_TO_TABLE';
      /**
       * Whether to pick the first ev or eday location. Used so that we don’t
       * select a location that is not enabled at all for the day we’re showing.
       */
      filterToLocations: 'ev' | 'eday';
      locationTableRows: LocationTableRow[];
    }
  | {
      type: 'START_BULK_EDIT_SHIFTS';
    }
  | {
      type: 'STOP_BULK_EDIT_SHIFTS';
    }
  | {
      /**
       * Adds one or more {@link ApiLocationHours} instances to our in-memory
       * edit state. Overwrites any existing hours for those locations on the
       * same days.
       */
      type: 'ADD_BULK_EDIT_HOURS';
      hoursByLocationId: Map<number, ApiLocationHours[]>;
    }
  | {
      type: 'SET_BULK_EDIT_SHIFTS_SAVING';
      isSaving: boolean;
    }
  | {
      type: 'OPEN_METADATA_MODAL';
    }
  | {
      type: 'OPEN_AUTO_ASSIGNMENT_MODAL';
    }
  | {
      type: 'OPEN_VOLUNTEER_AVAILABILITY_MODAL';
    }
  | {
      type: 'OPEN_EDIT_HOURS_MODAL';

      location: AssignmentLocation;
      hours: ApiLocationHours;
    }
  | {
      type: 'OPEN_EDIT_ASSIGNMENT_MODAL';

      location: Omit<AssignmentLocation, 'hours'>;

      /** Specific {@link ApiLocationHours} for this location/day */
      hours: ApiLocationHours;

      assignment?: (AssignmentRecord & { type: 'poll' }) | undefined | null;

      defaultStartTime?: TimeString | undefined;
      defaultEndTime?: TimeString | undefined;

      defaultUserId?: number | undefined;
    }
  | {
      type: 'CLOSE_MODAL';
    }
  | {
      /**
       * Adjusts the visible dates.
       *
       * If the detail pane is open, shifts the shown day and moves the overall
       * window only to keep that day in range.
       */
      type: 'MOVE_CALENDAR';
      /**
       * Need to pass in days so we can keep from moving past the end of the
       * election.
       */
      days: ApiElectionDay[];
      movement: CalendarMovement;
    }
  | {
      /**
       * Sets the day and number of days shown in the table. Note that this is
       * “number of days” because the dates may not be contiguous (it is common
       * for there to be days between the end of EV and EDay).
       *
       * If the number of days is 1, automatically opens the location detail
       * pane.
       *
       * If `startIndex` + `length` would go past the end of `days`, adjusts it
       * back. If `length` exceeds `days.length`, caps it at `days.length`.
       */
      type: 'SET_CALENDAR';
      days: ApiElectionDay[];
      startIndex: number;
      length: number;
    };
export type AssignmentPageUxDispatch = (action: AssignmentPageUxAction) => void;

/**
 * Reducer for managing an instance of {@link AssignmentPageUxState}.
 */
function assignmentPageUxReducer(
  state: AssignmentPageUxState,
  action: AssignmentPageUxAction
): AssignmentPageUxState {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'START_BULK_EDIT_SHIFTS':
        draft.bulkEditMode = {
          type: 'shifts',
          isSaving: false,
          hoursByLocationId: new Map(),
        };
        break;

      case 'STOP_BULK_EDIT_SHIFTS':
        if (draft.bulkEditMode?.type === 'shifts') {
          draft.bulkEditMode = null;
        }
        break;

      case 'ADD_BULK_EDIT_HOURS':
        if (draft.bulkEditMode?.type !== 'shifts') {
          return;
        }

        for (const [locId, newHoursArr] of action.hoursByLocationId.entries()) {
          draft.bulkEditMode.hoursByLocationId.set(
            locId,
            castDraft(
              mergeLocationHours(
                draft.bulkEditMode.hoursByLocationId.get(locId) ?? [],
                newHoursArr
              )
            )
          );
        }
        break;

      case 'SET_BULK_EDIT_SHIFTS_SAVING':
        if (draft.bulkEditMode?.type !== 'shifts') {
          return;
        }

        draft.bulkEditMode.isSaving = action.isSaving;
        break;

      case 'OPEN_FOCUSED_LOCATION_DAY_PANE':
        // TODO(fiona): Update calendar so that it includes this date?

        draft.detailPane = {
          type: 'location-day',

          dayIndex: action.dayIndex,
          locationId: action.locationId ?? null,
        };
        break;

      case 'CLOSE_DETAIL_PANE':
        draft.detailPane = null;
        break;

      case 'SYNC_SINGLE_DAY_LOCATION_TO_TABLE': {
        if (draft.detailPane?.type !== 'location-day') {
          break;
        }

        const currentLocationId = draft.detailPane.locationId;

        const firstLocationTableItem = action.locationTableRows.find(
          (r): r is LocationTableRow & { type: 'item' } =>
            r.type === 'item' &&
            (action.filterToLocations === 'ev'
              ? r.value.location.early_vote
              : r.value.location.election_day)
        );

        // If there’s no current location ID, or if the current location ID
        // isn’t found in the table, then set the location ID to the first
        // location in the table (if it exists).
        if (
          currentLocationId === null ||
          !action.locationTableRows.find(
            (r) =>
              r.type === 'item' && r.value.location.id === currentLocationId
          )
        ) {
          draft.detailPane.locationId =
            firstLocationTableItem?.value.location.id ?? null;
        }
        break;
      }

      case 'OPEN_METADATA_MODAL':
        draft.modal = { type: 'metadata' };
        break;

      case 'OPEN_VOLUNTEER_AVAILABILITY_MODAL':
        draft.modal = { type: 'volunteer-availability' };
        break;

      case 'OPEN_AUTO_ASSIGNMENT_MODAL':
        draft.modal = { type: 'auto-assignment' };
        break;

      case 'OPEN_EDIT_HOURS_MODAL':
        draft.modal = {
          type: 'edit-hours',
          location: castDraft(action.location),
          hours: action.hours,
        };
        break;

      case 'OPEN_EDIT_ASSIGNMENT_MODAL':
        draft.modal = {
          type: 'edit-assignment',
          location: castDraft(action.location),
          hours: action.hours,
          assignment: action.assignment ?? null,
          defaultStartTime: action.defaultStartTime ?? null,
          defaultEndTime: action.defaultEndTime ?? null,
          defaultUserId: action.defaultUserId ?? null,
        };
        break;

      case 'CLOSE_MODAL':
        draft.modal = null;
        break;

      case 'MOVE_CALENDAR': {
        let delta;

        switch (action.movement) {
          case 'prevPage':
            delta = -draft.calendar.visibleDaysLength;
            break;

          case 'prevDay':
            delta = -1;
            break;

          case 'nextDay':
            delta = 1;
            break;

          case 'nextPage':
            delta = draft.calendar.visibleDaysLength;
            break;

          default:
            assertUnreachable(action.movement);
        }

        if (draft.detailPane?.type === 'location-day') {
          // In single-day mode, we change the “selected date” value by the
          // delta, and only adjust calendar.visibleDaysStartIndex to keep it in
          // frame.
          const curSingleDayIndex = draft.detailPane.dayIndex;

          const nextSingleDayIndex = Math.max(
            0,
            Math.min(curSingleDayIndex + delta, action.days.length - 1)
          );

          draft.detailPane.dayIndex = nextSingleDayIndex;

          // Scrolls the grid view’s calendar to match the single day. Will be
          // noticable when leaving single day view.
          if (nextSingleDayIndex < draft.calendar.visibleDaysStartIndex) {
            draft.calendar.visibleDaysStartIndex = nextSingleDayIndex;
          } else if (
            nextSingleDayIndex >=
            draft.calendar.visibleDaysStartIndex +
              draft.calendar.visibleDaysLength
          ) {
            draft.calendar.visibleDaysStartIndex =
              nextSingleDayIndex - draft.calendar.visibleDaysLength + 1;
          }
        } else {
          draft.calendar.visibleDaysStartIndex = Math.max(
            0,
            Math.min(
              draft.calendar.visibleDaysStartIndex + delta,
              action.days.length - draft.calendar.visibleDaysLength
            )
          );
        }
        break;
      }

      case 'SET_CALENDAR':
        if (action.length === 1) {
          // TODO(fiona): Update draft.calendar.visibleDaysStartIndex so that
          // this day is included?

          draft.detailPane = {
            type: 'location-day',
            dayIndex: action.startIndex,
            locationId:
              draft.detailPane?.type === 'location-day'
                ? draft.detailPane.locationId
                : null,
          };

          // We keep calendar the way it is so that when you close the pane you
          // see a full grid of days.
        } else {
          if (draft.detailPane?.type === 'location-day') {
            draft.detailPane = null;
          }

          const length = Math.min(
            action.length,
            draft.calendar.visibleDaysLength
          );

          draft.calendar.visibleDaysStartIndex = Math.min(
            action.startIndex,
            action.days.length - length
          );
          draft.calendar.visibleDaysLength = length;
        }

        break;

      default:
        assertUnreachable(action);
    }

    return draft;
  });
}

const ROW_HEADER_COLUMN_WIDTH_PX = 384;
const ROW_HEADER_COLUMN_SINGLE_DAY_WIDTH_PX = 484;

const DEFAULT_MI_LOCATION_TIER_CONFIGURATION: ApiLocationTierConfiguration[] = [
  {
    state_rank_gte: 1,
    state_rank_lte: 19,
  },
  {
    state_rank_gte: 20,
    state_rank_lte: 99,
  },
  {
    state_rank_gte: 100,
    state_rank_lte: 500,
  },
];

type LocationViewState = Immutable<{
  filters: LocationFilterOptions;
  groupBy: LocationGroupBy;
  sortBy: LocationSortBy;
}>;

type PeopleViewState = {
  // volunteerFilters represent
  // user-selected/optional filters
  // based on volunteer attributes (ex: experience)
  // or assignment status
  volunteerFilters: PersonFilterOptions;
  groupBy: PersonGroupBy;
  sortBy: PersonSortBy;
};

export type AssignmentViewState = {
  mode: TableViewMode;
  searchValue: string;
  locations: LocationViewState;
  people: PeopleViewState;
};

/**
 * High-density voting location assignments page, including auto-assignments.
 */
const LocationAssignmentPage: React.FunctionComponent<{
  currentElection: ApiElection;
  userTags: ApiUserTag[];
}> = ({ currentElection, userTags }) => {
  const startTimeMsRef = React.useRef(Date.now());

  const [toastData, { showToast, dismissToast }] = useToast();

  const [assignmentState, assignmentDispatch] = React.useReducer(
    assignmentReducer,
    DEFAULT_ASSIGNMENT_STATE
  );

  // Loads our data on mount.
  const [assignmentStateLoadingStatus, reloadServerState] =
    useLoadInitialAssignmentState(assignmentDispatch);

  const [isInitialLoadDone, setIsInitialLoadDone] = React.useState(false);

  React.useEffect(() => {
    if (assignmentStateLoadingStatus.status === 'done') {
      setIsInitialLoadDone(true);
    }
  }, [assignmentStateLoadingStatus.status]);

  React.useEffect(() => {
    if (isInitialLoadDone) {
      const duration = Date.now() - startTimeMsRef.current;
      const eventArgs: RawAnalyticsEvent = {
        category: 'Auto-Assignments - Page Ready (ms)',
        action: 'load_complete',
        label: `${currentElection.state} : ${currentElection.election_date} Election`,
        value: duration,
      };
      trackGoogleAnalyticsEvent('auto_assign_page_ready', eventArgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoadDone]);

  // allUserLanguageTagInfos is a pre-filtered list of languages
  // VPDs can use to filter volunteers, based on volunteer tags
  // languages the VPDs are using to filter will appear in
  // assignmentViewState.people.volunteerFilters.filterForLanguages
  const allUserLanguageTagInfos = React.useMemo(() => {
    return getUserLanguageTagInfos(assignmentState.usersById, userTags);
  }, [assignmentState.usersById, userTags]);

  const municipalities = React.useMemo(
    () => municipalitiesFromLocations(assignmentState.locationsById.values()),
    [assignmentState.locationsById]
  );

  // TODO(fiona): Maybe put these in QueryState??
  const [assignmentViewState, setAssignmentViewState] =
    React.useState<AssignmentViewState>({
      mode: 'locations',
      searchValue: '',
      locations: {
        groupBy: 'county',
        // TODO(fiona): Default to alphabetical if there are no ranks in the locations
        sortBy: 'ranking',
        filters: DEFAULT_LOCATION_FILTERS,
      },
      people: {
        groupBy: 'none',
        sortBy: 'last_name',
        volunteerFilters: {
          edayAssignmentStatus: 'any',
          filterForExperience: 'any',
          filterForLegalCommunity: 'any',
          filterForCarAccess: 'any',
          filterForLanguages: { type: 'all' },
        },
      },
    });

  const [pageUxState, pageUxDispatch] = React.useReducer(
    assignmentPageUxReducer,
    {
      ...DEFAULT_ASSIGNMENT_PAGE_UX_STATE,
      calendar: {
        visibleDaysLength: DEFAULT_VISIBLE_DAYS,
        // Hack to make sure that we show election day by default when the page
        // opens.
        visibleDaysStartIndex: Math.max(
          0,
          currentElection.days.length - DEFAULT_VISIBLE_DAYS
        ),
      },
    }
  );

  /**
   * If the location day detail sidebar is open, this is its date. Pulled out to
   * index `currentElection.days` in one place and so that hooks can depend on
   * the date string, which should be usefully stable.
   */
  const locationDayDetailPaneDate =
    pageUxState.detailPane?.type === 'location-day'
      ? currentElection.days[pageUxState.detailPane.dayIndex]?.date ?? null
      : null;

  /**
   * Convenience function for closing the modal via a dispatch. Pulled out since
   * we use it so often.
   */
  const closeModal = React.useCallback(
    () => pageUxDispatch({ type: 'CLOSE_MODAL' }),
    []
  );

  /**
   * We store the most recent assignment suggestions so we can do UI and
   * filtering for them.
   *
   * These are stored in component state (rather than extracted from the current
   * set of assignments) so that the UI can be stable as suggestions are
   * accepted/cleared. For example, this means that location rows won’t
   * disappear once you’ve dealt with all of their suggestions.
   */
  const [lastSuggestedAssignments, setLastSuggestedAssignments] =
    React.useState<(AssignmentRecord & { type: 'poll'; source: 'auto' })[]>([]);

  // If ever there are no suggested assignments, clear
  // `lastSuggestedAssignments` to hide its UI.
  React.useEffect(() => {
    const hasSuggestions = [...assignmentState.assignmentsByDate.values()].find(
      (assignments) => !!assignments.find((a) => a.source === 'auto')
    );

    if (!hasSuggestions) {
      setLastSuggestedAssignments([]);
      setAssignmentViewState((prev) =>
        !prev.locations.filters.withSuggestedAssignments
          ? prev
          : produce(prev, (draft) => {
              draft.locations.filters.withSuggestedAssignments = false;
            })
      );
    }
  }, [assignmentState.assignmentsByDate]);

  /**
   * Map of how many suggested assignments remain for each date.
   */
  const suggestionCountByDate = React.useMemo(
    () =>
      new Map(
        [...assignmentState.assignmentsByDate.entries()].map(
          ([d, assignments]) => [
            d,
            assignments.filter((a) => a.source === 'auto').length,
          ]
        )
      ),
    [assignmentState.assignmentsByDate]
  );

  /**
   * A {@link AssignmentUserCounts} object tallying the progress for the
   * selected day, if one is chosen.
   */
  const selectedDayUserCounts =
    React.useMemo<AssignmentUserCounts | null>(() => {
      if (!locationDayDetailPaneDate) {
        return null;
      }

      const isEday =
        locationDayDetailPaneDate === currentElection.election_date;
      const users = [...assignmentState.usersById.values()].filter(
        (u) =>
          (isEday && getVolunteerAttributes(u).isEdayVolunteer) ||
          (!isEday && getVolunteerAttributes(u).isEvVolunteer)
      );

      const assignmentsByUserId = getAssignmentsByUserIdForDates(
        assignmentState,
        [locationDayDetailPaneDate]
      );

      const out: AssignmentUserCounts = {
        assignedUserCount: 0,
        suggestedUserCount: 0,
        totalUserCount: users.length,
        suggestionCount: 0,
        suggestedLocationCount: 0,
      };

      const suggestedLocationIds = new Set<number>();

      for (const user of users) {
        const assignments = assignmentsByUserId.get(user.id) ?? [];

        assignments.forEach((a) => {
          if (a.source === 'auto') {
            out.suggestionCount++;
            suggestedLocationIds.add(a.locationId);
          }
        });

        if (assignments.length > 0) {
          if (assignments.find((a) => a.source !== 'auto')) {
            // If the user has any hard assignments, count them as assigned.
            out.assignedUserCount++;
          } else {
            // Otherwise all of their assignments must be auto and we count them
            // as suggested.
            out.suggestedUserCount++;
          }
        }
      }

      out.suggestedLocationCount = suggestedLocationIds.size;

      return out;
    }, [
      assignmentState,
      locationDayDetailPaneDate,
      currentElection.election_date,
    ]);

  /**
   * The days to show in our table header, depending on whether or not we’re in
   * single-day view.
   */
  const visibleElectionDays = React.useMemo(() => {
    if (pageUxState.detailPane?.type === 'location-day') {
      return [currentElection.days[pageUxState.detailPane.dayIndex]!];
    } else {
      return currentElection.days.slice(
        pageUxState.calendar.visibleDaysStartIndex,
        pageUxState.calendar.visibleDaysStartIndex +
          pageUxState.calendar.visibleDaysLength
      );
    }
  }, [currentElection.days, pageUxState.detailPane, pageUxState.calendar]);

  /**
   * If the location detail pane is open, this is a Map of location ID ->
   * assigments for its specific date.
   *
   * Otherwise it’s an empty Map.
   */
  const assignmentsByLocationIdForSelectedDay = React.useMemo(
    () =>
      locationDayDetailPaneDate
        ? getAssignmentsByLocationIdForDates(assignmentState, [
            locationDayDetailPaneDate,
          ])
        : new Map(),
    [assignmentState, locationDayDetailPaneDate]
  );

  const maxRanking = React.useMemo(() => {
    const rankings = [...assignmentState.locationsById.values()]
      .map((loc) => loc.state_rank)
      .filter((rank): rank is number => rank !== null);

    if (rankings.length === 0) {
      return null;
    } else {
      return Math.max(...rankings);
    }
  }, [assignmentState.locationsById]);

  const locationTierConfiguration =
    currentElection.location_tier_configuration.length === 0
      ? DEFAULT_MI_LOCATION_TIER_CONFIGURATION
      : currentElection.location_tier_configuration;

  /**
   * Ref to the primary <table> element, used to calculate how many days we can
   * show across at once.
   */
  const tableRef = React.useRef<HTMLTableElement | null>(null);

  /**
   * How many days we can fit across the current size of the window. Used to
   * limit the calendar range picker.
   */
  const [maxVisibleElectionDays, maxExpandedElectionDays] =
    useMaxVisibleElectionDays(tableRef, !!pageUxState.detailPane);

  /**
   * If there’s room, show the expanded version of the cells.
   */
  const expandCells = visibleElectionDays.length <= maxExpandedElectionDays;

  const locationTableRows = React.useMemo<LocationTableRow[]>(
    () =>
      getFilteredSortedLocationList({
        assignmentState,
        groupBy: assignmentViewState.locations.groupBy,
        sortBy: assignmentViewState.locations.sortBy,
        tierConfiguration: locationTierConfiguration,
        locationFilters: assignmentViewState.locations.filters,
        nameFilter: assignmentViewState.searchValue,
        bulkEditMode: pageUxState.bulkEditMode ?? null,
        lastSuggestedAssignments,
        expandCells,
      }),
    [
      assignmentState,
      assignmentViewState.locations,
      assignmentViewState.searchValue,
      pageUxState.bulkEditMode,
      locationTierConfiguration,
      lastSuggestedAssignments,
      expandCells,
    ]
  );

  const locationCount = React.useMemo(
    () =>
      locationTableRows.reduce(
        (count, row) => (row.type === 'item' ? count + 1 : count),
        0
      ),
    [locationTableRows]
  );

  // Keep selectedLocationDay showing a location in the table. Triggers if the
  // table changes or if the chosen date does.
  React.useLayoutEffect(() => {
    if (pageUxState.detailPane?.type === 'location-day') {
      const selectedDay = currentElection.days[pageUxState.detailPane.dayIndex];

      if (!selectedDay) {
        return;
      }

      pageUxDispatch({
        type: 'SYNC_SINGLE_DAY_LOCATION_TO_TABLE',
        filterToLocations: selectedDay.is_early_vote ? 'ev' : 'eday',
        locationTableRows: locationTableRows,
      });
    }
  }, [
    locationTableRows,
    currentElection.days,
    pageUxState.detailPane?.type,
    pageUxState.detailPane?.dayIndex,
  ]);

  const peopleTableRows = React.useMemo<PeopleTableRow[]>(
    () =>
      getFilteredSortedPersonList(
        assignmentState,
        currentElection.election_date,
        assignmentViewState.people.groupBy,
        assignmentViewState.people.volunteerFilters,
        assignmentViewState.searchValue
      ),
    [
      assignmentState,
      assignmentViewState.people.groupBy,
      assignmentViewState.people.volunteerFilters,
      assignmentViewState.searchValue,
      currentElection.election_date,
    ]
  );

  const peopleCount = React.useMemo(
    () =>
      peopleTableRows.reduce(
        (count, row) => (row.type === 'item' ? count + 1 : count),
        0
      ),
    [peopleTableRows]
  );

  const getDistanceMi = React.useMemo(
    () =>
      // We recreate a little `state` object so we can have this useMemo only
      // recalculate when locationsById or usersById changes.
      makeDistanceMiLookupFunc({
        locationsById: assignmentState.locationsById,
        usersById: assignmentState.usersById,
      }),
    [assignmentState.locationsById, assignmentState.usersById]
  );

  const reportError = React.useCallback(
    (message: string) => showToast({ message, type: 'error' }),
    [showToast]
  );

  const deleteAssignment = React.useCallback(
    async (assignment: AssignmentRecord & { type: 'poll' }) => {
      if (assignment.source === 'server') {
        try {
          await AssignmentService.deleteAssignment(
            { id: assignment.id },
            {
              listViewType: 'LOCATIONS',
              resourceId: assignment.locationId,
            }
          );
        } catch (e) {
          // We can safely ignore “not found” errors, since that means that the
          // assignment was deleted through other means, and just pretend we
          // succeeded.
          if (!(e instanceof ApiNotFoundError)) {
            reportError('Unable to delete that assignment');
            throw e;
          }
        }
      }

      assignmentDispatch({ type: 'REMOVE_ASSIGNMENT', assignment });
    },
    [reportError]
  );

  const saveAssignment = React.useCallback(
    async (
      assignment: AssignmentRecord & { type: 'poll' },
      originalAssignment: (AssignmentRecord & { type: 'poll' }) | null,
      replaceExistingAssignments: boolean
    ) => {
      if (replaceExistingAssignments) {
        const conflictingAssignments = findExistingAssignments(
          assignmentState,
          assignment.userId,
          assignment.date
        );

        for (const conflictingAssignment of conflictingAssignments) {
          if (conflictingAssignment.type === 'poll') {
            // This check is so that updating an assignment is done as a
            // modification rather than a delete and re-create.
            const conflictingAssignmentIsOriginalAssignment =
              conflictingAssignment.source === 'server' &&
              originalAssignment?.source === 'server' &&
              conflictingAssignment.id === originalAssignment.id;

            if (!conflictingAssignmentIsOriginalAssignment) {
              await deleteAssignment(conflictingAssignment);
            }
          } else {
            throw new Error(
              'Trying to save with conflicting assignment to non-poll location'
            );
          }
        }
      }

      let result: ApiAssignmentCrudResponse;

      if (originalAssignment?.source === 'server') {
        result = await AssignmentService.updateAssignment(
          {
            id: originalAssignment.id,
            type: 'poll',
            location: assignment.locationId,
            user: assignment.userId,
            shift_date: assignment.date,
            start_time: assignment.startTime,
            end_time: assignment.endTime,
          },
          {
            listViewType: 'LOCATIONS',
            resourceId: assignment.locationId,
          }
        );
      } else {
        result = await AssignmentService.createAssignment(
          {
            type: 'poll',
            location: assignment.locationId,
            user: assignment.userId,
            shift_date: assignment.date,
            start_time: assignment.startTime,
            end_time: assignment.endTime,
          },
          {
            listViewType: 'LOCATIONS',
            resourceId: assignment.locationId,
          }
        );
      }

      if (!result.assignment) {
        reportError('Unable to save that assignment');
        throw new Error('The assignment was not saved');
      }

      if (originalAssignment) {
        // We wait to remove this until after the API call succeeds, both to
        // ensure that there wasn’t an error and to prevent a flash of the
        // assignment going missing while the API call happens.
        assignmentDispatch({
          type: 'REMOVE_ASSIGNMENT',
          assignment: originalAssignment,
        });
      }

      assignmentDispatch({
        type: 'ADD_ASSIGNMENT',
        assignment: {
          ...assignment,
          id: result.assignment.id,
          source: 'server',
        },
      });
    },
    [assignmentState, reportError, deleteAssignment]
  );

  const [isSavingAutoAssignments, setSavingAutoAssignments] =
    React.useState(false);

  /**
   * Saves all current “auto” assignments on all dates to the server.
   */
  const acceptAllSuggestions = React.useCallback(
    async (date: DateString | null = null) => {
      setSavingAutoAssignments(true);

      const savedAssignments: (AssignmentRecord & { source: 'server' })[] = [];
      const errors: string[] = [];

      const assignmentsToSave = (
        date ? [date] : [...assignmentState.assignmentsByDate.keys()]
      ).flatMap((date) =>
        assignmentState.assignmentsByDate
          .get(date)!
          .filter(
            (d): d is AssignmentRecord & { source: 'auto' } =>
              d.source === 'auto'
          )
      );

      // Go in groups of 200 to not overload the endpoint.
      for (const assignmentChunk of chunk(assignmentsToSave, 200)) {
        try {
          const bulkCreationResult =
            await AssignmentService.bulkCreateAssignments(
              assignmentChunk.map((a) => ({
                type: 'poll',
                location: a.locationId,
                user: a.userId,
                shift_date: a.date,
                start_time: a.startTime,
                end_time: a.endTime,
              }))
            );

          savedAssignments.push(
            ...bulkCreationResult
              .filter(
                (
                  a
                ): a is AssignmentService.ApiPollAssignment & {
                  location: number;
                } =>
                  // We assume all responses here are going to be polls, but we
                  // want to check anyway for type safety.
                  a.type === 'poll' && a.location !== null
              )
              .map<AssignmentRecord & { source: 'server' }>((a) => ({
                source: 'server',
                type: 'poll',
                id: a.id,
                locationId: a.location,
                userId: a.user,
                date: a.shift_date,
                startTime: a.start_time,
                endTime: a.end_time,
              }))
          );
        } catch (e) {
          console.error(e);
          errors.push(String(e));
        }
      }

      // Get rid of all assignments on this date (since we just saved them) or
      // all dates (if we’re doing all dates).
      assignmentDispatch({
        type: 'CLEAR_ASSIGNMENTS',
        date,
        source: 'auto',
      });

      assignmentDispatch({
        type: 'ADD_ASSIGNMENTS',
        assignments: savedAssignments,
      });

      if (errors.length) {
        showToast({
          message: `${errors.length} suggestions were not saved.`,
          type: 'error',
        });
      }

      setSavingAutoAssignments(false);
    },
    [showToast, assignmentState]
  );

  const clearSuggestions = React.useCallback(
    (date: DateString | null = null) => {
      assignmentDispatch({
        type: 'CLEAR_ASSIGNMENTS',
        date,
        source: 'auto',
      });
    },
    []
  );

  const acceptAssignment = React.useCallback(
    async (assignment: AssignmentRecord & { type: 'poll' }) => {
      if (assignment.source === 'auto') {
        await saveAssignment(
          { ...assignment, source: 'manual' },
          assignment,
          false
        );
      }
    },
    [saveAssignment]
  );

  const rejectAssignment = React.useCallback(
    async (assignment: AssignmentRecord) => {
      if (assignment.source === 'auto') {
        await deleteAssignment(assignment);
      }
    },
    [deleteAssignment]
  );

  /**
   * Writes the given map of {@link ApiLocationHours} values to the server,
   * updates the saved state, and exits bulk edit mode.
   *
   * Presumably will only be used while in bulk edit shifts mode.
   */
  const saveBulkEditShifts = React.useCallback(
    async (hoursByLocationId: Immutable<Map<number, ApiLocationHours[]>>) => {
      try {
        pageUxDispatch({ type: 'SET_BULK_EDIT_SHIFTS_SAVING', isSaving: true });

        await AssignmentService.bulkUpdateVotingHours(
          [...hoursByLocationId.entries()].flatMap(([locId, hoursArr]) =>
            hoursArr.map(
              (h): ApiLocationHourUpdates => ({
                location_id: locId,
                ...h,
              })
            )
          )
        );

        assignmentDispatch({
          type: 'UPDATE_BULK_HOURS',
          hoursByLocationId,
        });

        pageUxDispatch({ type: 'STOP_BULK_EDIT_SHIFTS' });
      } catch (e) {
        reportError('There was a problem saving those shift changes.');

        pageUxDispatch({
          type: 'SET_BULK_EDIT_SHIFTS_SAVING',
          isSaving: false,
        });

        throw e;
      }
    },
    [reportError]
  );

  const dayColumns = React.useMemo(
    () => [
      ...visibleElectionDays.map<AssignmentTableColumn>((day) => ({
        type: 'day',
        key: day.date,
        day,
        userCounts:
          day.date === locationDayDetailPaneDate ? selectedDayUserCounts : null,
        suggestionCount: suggestionCountByDate.get(day.date) ?? 0,
      })),
    ],
    [
      visibleElectionDays,
      locationDayDetailPaneDate,
      selectedDayUserCounts,
      suggestionCountByDate,
    ]
  );

  const locationTableColumns = React.useMemo<AssignmentTableColumn[]>(
    () => [
      {
        type: 'location',
        key: 'location',
        locationCount,
      },
      ...dayColumns,
    ],
    [locationCount, dayColumns]
  );

  const peopleTableColumns = React.useMemo<AssignmentTableColumn[]>(
    () => [
      {
        type: 'people',
        key: 'people',
        peopleCount,
      },
      ...dayColumns,
    ],
    [peopleCount, dayColumns]
  );

  /**
   * True if there’s some pending state that should make the user pause before
   * leaving the page, such as bulk edit mode or a modal is active.
   *
   * TODO(fiona): This should be true if there are outstanding suggestions,
   *  _&c._.
   */
  const hasUnsavedChanges = React.useMemo(
    () =>
      !!pageUxState.bulkEditMode ||
      !!pageUxState.modal ||
      lastSuggestedAssignments.length > 0,
    [pageUxState.bulkEditMode, pageUxState.modal, lastSuggestedAssignments]
  );

  // Warns about hard browser navigations when in draft mode.
  React.useLayoutEffect(() => {
    if (hasUnsavedChanges) {
      const cb = (ev: Event) => {
        // Show warning if in draft mode.
        //
        // Browsers don’t actually display this message, but returning it
        // triggers the confirm dialog.
        //
        ev.preventDefault();
        (ev.returnValue as any) =
          'You’re still in draft mode. Are you sure you want to leave?';
      };

      window.addEventListener('beforeunload', cb);

      return () => {
        window.removeEventListener('beforeunload', cb);
      };
    }
  }, [hasUnsavedChanges]);

  // Warns about internal navigation changes in draft mode.
  usePrompt({
    when: hasUnsavedChanges,
    message:
      'Are you sure you want to leave this page?\n\nYou have unsaved draft mode changes that will be lost.',
  });

  const pollObserverRegistrationRequirement =
    currentElection.assignment_preferences
      .poll_observer_registration_requirement || 'none';

  return (
    <div className="reset-2023 flex w-full flex-1 flex-col">
      <div className="flex items-center justify-between p-4">
        {pageUxState.bulkEditMode?.type === 'shifts' && (
          <BulkEditShiftsBanner
            pageUxDispatch={pageUxDispatch}
            saveBulkEditShifts={saveBulkEditShifts}
            hoursByLocationId={pageUxState.bulkEditMode.hoursByLocationId}
            isSaving={pageUxState.bulkEditMode.isSaving}
          />
        )}

        {!pageUxState.bulkEditMode && (
          <>
            <PageHeader
              title={
                <PageTitle2 uiVersion="2023">Volunteer Assignments</PageTitle2>
              }
              // Some overrides on the padding to compensate for the overall
              // padding on the flex container.
              className="flex flex-shrink items-center border-0 px-2 py-2"
            />

            <AssignmentLiveModeButtons
              pageUxDispatch={pageUxDispatch}
              assignmentStateLoadingStatus={assignmentStateLoadingStatus}
              isSavingAutoAssignments={isSavingAutoAssignments}
            />
          </>
        )}
      </div>

      <div className="mb-2 flex flex-row-reverse items-center justify-between px-4">
        <AssignmentLoadingStatusLine
          lastLoadTime={assignmentState.lastLoadTime}
          assignmentStateLoadingStatus={assignmentStateLoadingStatus}
          reloadServerState={reloadServerState}
        />

        <LocationTableToolbar
          electionDays={currentElection.days}
          visibleElectionDays={visibleElectionDays}
          changeVisibleElectionDays={(movement: CalendarMovement) =>
            pageUxDispatch({
              type: 'MOVE_CALENDAR',
              days: currentElection.days,
              movement,
            })
          }
          setVisibleElectionDays={(index: number, length: number) => {
            pageUxDispatch({
              type: 'SET_CALENDAR',
              days: currentElection.days,
              startIndex: index,
              length: length,
            });
          }}
          maxVisibleElectionDays={maxVisibleElectionDays}
          maxRanking={maxRanking}
          assignmentViewState={assignmentViewState}
          setAssignmentViewState={setAssignmentViewState}
          locationTierConfiguration={locationTierConfiguration}
          userLanguageTagInfos={allUserLanguageTagInfos}
          municipalities={municipalities}
          hasLastSuggestedAssignments={lastSuggestedAssignments.length > 0}
        />
      </div>

      <Toast toastData={toastData} onDismiss={dismissToast} />

      {/* "relative" so that loading overlay stays within */}
      <div className="relative flex flex-1 flex-col">
        {!isInitialLoadDone && (
          <>
            {assignmentStateLoadingStatus.status === 'loading' && (
              <LoadingOverlay
                text={`Loading ${
                  LOADING_STAGE_TO_NOUN[assignmentStateLoadingStatus.stage]
                }… ${assignmentStateLoadingStatus.count}`}
              />
            )}

            {assignmentStateLoadingStatus.status === 'error' && (
              <div className="absolute top-0 left-0 z-50 flex h-full w-full flex-col items-center gap-4 bg-white bg-opacity-80">
                <div className="flex-1" />
                <div className="text-xl font-bold">
                  There was an error loading assignment data.
                </div>

                <ActionButton
                  onPress={() => reloadServerState()}
                  role="secondary"
                >
                  Try Again
                </ActionButton>
                <div className="flex-1" />
                <div className="flex-1" />
              </div>
            )}
          </>
        )}

        {pageUxState.modal?.type === 'metadata' && (
          <LocationMetadataDialog
            locationsById={assignmentState.locationsById}
            doClose={closeModal}
            applyBulkUpdates={(updates) =>
              assignmentDispatch({
                type: 'APPLY_LOCATION_BULK_UPDATES',
                updates,
              })
            }
          />
        )}

        {pageUxState.modal?.type === 'volunteer-availability' && (
          <VolunteerAvailabilityDialog
            municipalities={municipalities}
            usersById={assignmentState.usersById}
            doClose={closeModal}
            updateAvailabilyForGroup={(availabilityGroupId, availability) =>
              assignmentDispatch({
                type: 'UPDATE_AVAILABILITY_BY_GROUP',
                availabilityGroupId,
                availability,
              })
            }
          />
        )}

        {pageUxState.modal?.type === 'auto-assignment' &&
          currentElection.election_date && (
            <AutoAssignmentDialog
              assignmentStateLoadingStatus={assignmentStateLoadingStatus}
              reloadServerState={reloadServerState}
              doClose={closeModal}
              electionDays={currentElection.days}
              electionState={currentElection.state}
              defaultDate={
                locationDayDetailPaneDate ?? currentElection.election_date
              }
              assignmentState={assignmentState}
              pollObserverRegistrationRequirement={
                pollObserverRegistrationRequirement
              }
              defaultLocationFilters={assignmentViewState.locations.filters}
              locationTierConfiguration={locationTierConfiguration}
              maxRanking={maxRanking}
              municipalities={municipalities}
              reviewAutoAssignments={(
                locationFilters,
                suggestedAssignments
              ) => {
                assignmentDispatch({
                  type: 'CLEAR_ASSIGNMENTS',
                  date: null,
                  source: 'auto',
                });

                assignmentDispatch({
                  type: 'ADD_ASSIGNMENTS',
                  assignments: suggestedAssignments,
                });

                setLastSuggestedAssignments(suggestedAssignments);

                setAssignmentViewState(
                  produce((draft) => {
                    draft.locations.filters = {
                      ...castDraft(locationFilters),
                      // Default to only showing locations that got assignments.
                      withSuggestedAssignments: true,
                    };
                  })
                );

                closeModal();

                const suggestedAssignmentDates = [
                  ...new Set(suggestedAssignments.map((a) => a.date)),
                ];

                suggestedAssignmentDates.sort();

                // If we only have results for one day, switch the details pane
                // to it if it’s already open, otherwise open it up.
                if (suggestedAssignmentDates.length === 1) {
                  const dayIndex = currentElection.days.findIndex(
                    (day) => day.date === suggestedAssignmentDates[0]!
                  );

                  if (dayIndex === -1) {
                    return;
                  }

                  if (pageUxState.detailPane?.type === 'location-day') {
                    // Switches the day, but not the currently-selected
                    // location.
                    pageUxDispatch({
                      type: 'OPEN_FOCUSED_LOCATION_DAY_PANE',
                      dayIndex,
                      locationId: pageUxState.detailPane.locationId,
                    });
                  } else {
                    const locationIdsWithAutoAssignments = new Set(
                      suggestedAssignments
                        .filter((a) => a.source === 'auto')
                        .map((a) => a.locationId)
                    );

                    const firstRowWithAutoAssignments = locationTableRows.find(
                      (
                        row
                      ): row is LocationTableRow & {
                        type: 'item';
                      } =>
                        row.type === 'item' &&
                        locationIdsWithAutoAssignments.has(
                          row.value.location.id
                        )
                    )?.value;

                    if (firstRowWithAutoAssignments) {
                      pageUxDispatch({
                        type: 'OPEN_FOCUSED_LOCATION_DAY_PANE',
                        dayIndex,
                        locationId: firstRowWithAutoAssignments.location.id,
                      });
                    }
                  }
                } else if (suggestedAssignmentDates.length > 1) {
                  // We have assignments on more than one day. Try to get them
                  // into view.

                  const dayIndex = currentElection.days.findIndex(
                    (day) => day.date === suggestedAssignmentDates[0]!
                  );

                  pageUxDispatch({
                    type: 'SET_CALENDAR',
                    days: currentElection.days,
                    startIndex: dayIndex,
                    // We keep the existing calendar size.
                    length: pageUxState.calendar.visibleDaysLength,
                  });
                } else {
                  // If there are no auto-assignments, don’t change the view at
                  // all.
                  //
                  // TODO(fiona): Show an informative note about this in the
                  // auto-assign dialog rather than closing it.
                }
              }}
            />
          )}

        <div className="flex flex-grow basis-0 items-stretch overflow-hidden">
          {/* z-10 so this goes on top of the sidebar’s border where they overlap. Important
              to keep from cutting off the right edge of our selection focus ring. */}
          <div className="z-10 flex flex-grow basis-0 flex-col">
            {assignmentViewState.mode === 'locations' && (
              <AssignmentTable
                tableRef={tableRef}
                // Keyed so we don’t try to re-use anything when switching modes.
                key="locations"
                aria-label="Locations"
                headerRowHeight={HEADER_ROW_HEIGHT_PX}
                estimateSize={() => LOCATION_ROW_HEIGHT_PX}
                overscan={10}
                minWidth={
                  (visibleElectionDays.length === 1
                    ? ROW_HEADER_COLUMN_SINGLE_DAY_WIDTH_PX
                    : ROW_HEADER_COLUMN_WIDTH_PX) +
                  DAY_COLUMN_MIN_WIDTH_PX * visibleElectionDays.length
                }
                flushRight={!!pageUxState.detailPane}
                // We don’t want react-aria’s row-based selection behavior.
                selectionMode="none"
                selectedCellKey={
                  pageUxState.detailPane?.type === 'location-day' &&
                  // This should be set if we’re in location-day mode
                  locationDayDetailPaneDate &&
                  pageUxState.detailPane.locationId !== null
                    ? makeLocationDayKey(
                        pageUxState.detailPane.locationId,
                        locationDayDetailPaneDate
                      )
                    : null
                }
                onCellAction={(key) => {
                  const parsedKey = parseLocationDayKey(key);

                  if (parsedKey?.type === 'location-day') {
                    const location = assignmentState.locationsById.get(
                      parsedKey.locationId
                    );

                    // Only select days that we have an “hours” record for. This
                    // keeps us from selecting EV days for locations that aren’t
                    // enabled for EV.
                    if (
                      location?.hours.find((h) => h.date === parsedKey.date)
                    ) {
                      const dayIndex = currentElection.days.findIndex(
                        (d) => d.date === parsedKey.date
                      );

                      if (dayIndex !== -1) {
                        pageUxDispatch({
                          type: 'OPEN_FOCUSED_LOCATION_DAY_PANE',

                          dayIndex,
                          locationId: parsedKey.locationId,
                        });
                      }
                    }
                  } else if (parsedKey?.type === 'day-column-header') {
                    const dayIndex = currentElection.days.findIndex(
                      (d) => d.date === parsedKey.date
                    );

                    if (dayIndex !== -1) {
                      pageUxDispatch({
                        type: 'OPEN_FOCUSED_LOCATION_DAY_PANE',

                        dayIndex,
                        // Since this was a header and not a location, we don’t
                        // have an id. On the next render,
                        // SYNC_SINGLE_DAY_LOCATION_TO_TABLE will be dispatched
                        // and set the locationId to the first location that
                        // matches the current filters.
                        locationId: null,
                      });
                    }
                  }
                }}
              >
                <TableHeader columns={locationTableColumns}>
                  {makeAssignmentTableColumnRenderer({
                    rowHeaderColumnWidthPx:
                      visibleElectionDays.length === 1
                        ? ROW_HEADER_COLUMN_SINGLE_DAY_WIDTH_PX
                        : ROW_HEADER_COLUMN_WIDTH_PX,
                  })}
                </TableHeader>

                <TableBody items={locationTableRows}>
                  {/* WARNING: anything passed in to makeLocationCellRenderer will be
                      captured by the cached table cell components. Make sure that all
                      of the functions passed in are effectively immutable (like state
                      setters or dispatch functions), and that any data is either
                      immutable or we don’t need to respond to its changes.
                      
                      If you have data that will change interactively, it needs to be
                      passed in per-row. */}
                  {makeLocationCellRenderer({
                    visibleElectionDays,
                    usersById: assignmentState.usersById,
                    tierConfiguration:
                      currentElection.location_tier_configuration,
                    doOpenNewAssignmentModal: (location, hours) => {
                      pageUxDispatch({
                        type: 'OPEN_EDIT_ASSIGNMENT_MODAL',
                        location,
                        hours,
                      });
                    },
                    getDistanceMi,
                    acceptAssignments: (assignments) =>
                      assignments.forEach(acceptAssignment),
                    // hours is passed in because it may be the bulk editing
                    // override hours from the previous render, while
                    // location.hours is always consistent with the server.
                    changeShiftCount: (location, hours, delta) => {
                      const [amShiftCount, pmShiftCount] =
                        shiftCountsForLocation(
                          location,
                          hours,
                          locationTierConfiguration
                        );

                      pageUxDispatch({
                        type: 'ADD_BULK_EDIT_HOURS',
                        hoursByLocationId: new Map([
                          [
                            location.id,
                            [
                              {
                                ...hours,
                                tracks_election_default: 'none',
                                am_shift_count: amShiftCount + delta,
                                pm_shift_count: pmShiftCount + delta,
                              },
                            ],
                          ],
                        ]),
                      });
                    },
                  })}
                </TableBody>
              </AssignmentTable>
            )}

            {assignmentViewState.mode === 'people' && (
              <AssignmentTable
                tableRef={tableRef}
                // Keyed so we don’t try to re-use anything when switching modes.
                key="people"
                aria-label="People"
                headerRowHeight={HEADER_ROW_HEIGHT_PX}
                estimateSize={() => PEOPLE_ROW_HEIGHT_PX}
                overscan={10}
                minWidth={
                  (visibleElectionDays.length === 1
                    ? ROW_HEADER_COLUMN_SINGLE_DAY_WIDTH_PX
                    : ROW_HEADER_COLUMN_WIDTH_PX) +
                  DAY_COLUMN_MIN_WIDTH_PX * visibleElectionDays.length
                }
                flushRight={!!pageUxState.detailPane}
                // We don’t want react-aria’s row-based selection behavior.
                selectionMode="none"
              >
                <TableHeader columns={peopleTableColumns}>
                  {makeAssignmentTableColumnRenderer({
                    rowHeaderColumnWidthPx:
                      visibleElectionDays.length === 1
                        ? ROW_HEADER_COLUMN_SINGLE_DAY_WIDTH_PX
                        : ROW_HEADER_COLUMN_WIDTH_PX,
                  })}
                </TableHeader>

                <TableBody items={peopleTableRows}>
                  {makePeopleCellRenderer({
                    visibleElectionDays,
                    locationsById: assignmentState.locationsById,
                    electionDate: currentElection.election_date,
                    doOpenNewAssignmentModal: (location, hours) => {
                      pageUxDispatch({
                        type: 'OPEN_EDIT_ASSIGNMENT_MODAL',
                        location,
                        hours,
                      });
                    },
                    setSelectedLocationDay: ({ date, locationId }) => {
                      const dayIndex = currentElection.days.findIndex(
                        (d) => d.date === date
                      );

                      if (dayIndex !== -1) {
                        pageUxDispatch({
                          type: 'OPEN_FOCUSED_LOCATION_DAY_PANE',
                          dayIndex,
                          locationId,
                        });
                      }
                    },
                    getDistanceMi,
                  })}
                </TableBody>
              </AssignmentTable>
            )}

            {selectedDayUserCounts &&
              selectedDayUserCounts.suggestionCount > 0 && (
                <ReviewSuggestedAssignmentsBanner
                  assignmentUserCounts={selectedDayUserCounts}
                  acceptAllSuggestions={() =>
                    acceptAllSuggestions(locationDayDetailPaneDate!)
                  }
                  clearSuggestions={() =>
                    clearSuggestions(locationDayDetailPaneDate!)
                  }
                  isSavingAutoAssignments={isSavingAutoAssignments}
                />
              )}

            {!selectedDayUserCounts && lastSuggestedAssignments.length > 0 && (
              <ReviewSuggestedAssignmentsBanner
                totalSuggestionCount={lastSuggestedAssignments.length}
                acceptAllSuggestions={acceptAllSuggestions}
                isSavingAutoAssignments={isSavingAutoAssignments}
                clearSuggestions={clearSuggestions}
              />
            )}
          </div>

          {pageUxState.detailPane?.type === 'location-day' &&
            locationDayDetailPaneDate && (
              <LocationDayDetailPane
                electionId={currentElection.id}
                assignmentsByLocationId={assignmentsByLocationIdForSelectedDay}
                tierConfiguration={currentElection.location_tier_configuration}
                date={locationDayDetailPaneDate}
                dateIsEv={
                  locationDayDetailPaneDate !== currentElection.election_date
                }
                locationId={pageUxState.detailPane.locationId}
                location={
                  pageUxState.detailPane.locationId !== null
                    ? assignmentState.locationsById.get(
                        pageUxState.detailPane.locationId
                      ) ?? null
                    : null
                }
                usersById={assignmentState.usersById}
                bulkEditModeType={pageUxState.bulkEditMode?.type ?? null}
                onClose={() => pageUxDispatch({ type: 'CLOSE_DETAIL_PANE' })}
                onEditAssignment={({
                  location,
                  hours,
                  assignment,
                  defaultStartTime,
                  defaultEndTime,
                  defaultUserId,
                }) =>
                  pageUxDispatch({
                    type: 'OPEN_EDIT_ASSIGNMENT_MODAL',
                    location,
                    hours,
                    assignment,
                    defaultStartTime,
                    defaultEndTime,
                    defaultUserId,
                  })
                }
                onEditHours={({ location, hours }) =>
                  pageUxDispatch({
                    type: 'OPEN_EDIT_HOURS_MODAL',
                    location,
                    hours,
                  })
                }
                getDistanceMi={getDistanceMi}
                acceptAssignment={acceptAssignment}
                rejectAssignment={rejectAssignment}
              />
            )}
        </div>
      </div>

      {pageUxState.modal?.type === 'edit-assignment' &&
        (({
          location,
          hours,
          assignment,
          defaultStartTime,
          defaultEndTime,
          defaultUserId,
        }) => (
          <AssignmentModal
            assignment={assignment ?? null}
            hours={hours}
            isEDay={hours.date === currentElection.election_date}
            location={location}
            locationsById={assignmentState.locationsById}
            allUsersForLocation={getUsersForLocationAssignment(
              assignmentState,
              location.id,
              hours.date,
              pollObserverRegistrationRequirement,
              {
                // For now, hard-code these to true, since we don’t have a setting
                // for their default, and it’s a little weird to use the last
                // suggestion run’s configuration.
                favorExperience: true,
                favorLegalCommunity: true,
              },
              getDistanceMi,
              hours.date === currentElection.election_date
            )}
            defaultStartTime={defaultStartTime}
            defaultEndTime={defaultEndTime}
            defaultUserId={defaultUserId}
            deleteAssignment={deleteAssignment}
            saveAssignment={saveAssignment}
            doClose={closeModal}
          />
        ))(pageUxState.modal)}

      {pageUxState.modal?.type === 'edit-hours' &&
        (({ location, hours }) => (
          <EditHoursModal
            location={location}
            locationHasAssignments={
              !!assignmentState.assignmentsByDate
                .get(hours.date)
                ?.find((a) => a.type === 'poll' && a.locationId === location.id)
            }
            hours={hours}
            election={currentElection}
            doUpdate={async (update) => {
              try {
                const updatedHours = (
                  await AssignmentService.bulkUpdateVotingHours([update])
                )[0];

                assignmentDispatch({
                  type: 'UPDATE_HOURS',
                  locationId: update.location_id,
                  hours: updatedHours!,
                });

                closeModal();
              } catch (e: any) {
                sentryService.captureException(e);
                return `Unable to save: ${e.toString()}`;
              }
            }}
            doClose={closeModal}
          />
        ))(pageUxState.modal)}
    </div>
  );
};

/**
 * Banner that replaces the top half of the header when we’re in bulk edit
 * shifts mode.
 */
const BulkEditShiftsBanner: React.FunctionComponent<{
  pageUxDispatch: AssignmentPageUxDispatch;
  saveBulkEditShifts: (
    hours: Immutable<Map<number, ApiLocationHours[]>>
  ) => void;
  hoursByLocationId: Immutable<Map<number, ApiLocationHours[]>>;
  isSaving: boolean;
}> = ({ pageUxDispatch, saveBulkEditShifts, hoursByLocationId, isSaving }) => (
  <div className="flex flex-1 items-center gap-8 border border-gray-300 bg-gray-100 p-4">
    <strong className="flex-1 text-xl text-gray-700">
      Add or Remove Shifts
    </strong>

    <div className="mt-0.5 text-base">
      Use the{' '}
      <IconButton
        icon="add_circle_outline"
        // small -top to better align with text
        className="relative -top-0.5 align-middle"
        role="secondary"
      />
      /
      <IconButton
        icon="remove_circle_outline"
        // small -top to better align with text
        className="relative -top-0.5 align-middle"
        role="secondary"
      />{' '}
      buttons to add or remove unassigned shifts.
    </div>

    <div className="flex gap-2">
      <ActionButton
        role="secondary"
        onPress={() => pageUxDispatch({ type: 'STOP_BULK_EDIT_SHIFTS' })}
        isDisabled={isSaving}
      >
        Cancel
      </ActionButton>

      <ActionButton
        onPress={() => saveBulkEditShifts(hoursByLocationId)}
        isDisabled={isSaving}
      >
        Save Changes
      </ActionButton>
    </div>
  </div>
);

const ReviewSuggestedAssignmentsBanner: React.FunctionComponent<
  {
    acceptAllSuggestions: () => Promise<void>;
    isSavingAutoAssignments: boolean;
    clearSuggestions: () => void;
  } & (
    | {
        assignmentUserCounts?: Pick<
          AssignmentUserCounts,
          'suggestionCount' | 'suggestedLocationCount'
        > | null;
        totalSuggestionCount?: never;
      }
    | {
        totalSuggestionCount?: number | null;
        assignmentUserCounts?: never;
      }
  )
> = ({
  assignmentUserCounts = null,
  totalSuggestionCount = null,
  acceptAllSuggestions,
  isSavingAutoAssignments,
  clearSuggestions,
}) => {
  return (
    <div className="flex items-center gap-2 bg-purple-300 p-4 text-gray-700">
      <span className="material-icons align-bottom text-lg">smart_toy</span>{' '}
      {assignmentUserCounts && (
        <div className="text-base leading-snug">
          Review suggestions:{' '}
          <strong>
            {assignmentUserCounts.suggestionCount} remaining assignments
          </strong>{' '}
          at{' '}
          <strong>
            {assignmentUserCounts.suggestedLocationCount} locations
          </strong>
        </div>
      )}
      {totalSuggestionCount && (
        <div className="text-base leading-snug">
          We have <strong>{totalSuggestionCount} total assignments</strong>{' '}
          suggested for you. Click date headers for more details.
        </div>
      )}
      <div className="ml-4">
        <TextButton
          size="small"
          onPress={() => acceptAllSuggestions()}
          isDisabled={isSavingAutoAssignments}
        >
          Accept All
        </TextButton>
        {' • '}
        <TextButton
          size="small"
          onPress={() => clearSuggestions()}
          isDisabled={isSavingAutoAssignments}
        >
          Clear
        </TextButton>
      </div>
      <div className="flex-1" />
      <div className="leading-none">
        <IconButton
          icon="close"
          title="Clear all suggestions"
          onPress={() => clearSuggestions()}
          isDisabled={isSavingAutoAssignments}
        />
      </div>
    </div>
  );
};

/**
 * Returns 2 numbers: max number of election day columns that can fit, and max
 * number of _expanded_ election day columns that can fit.
 */
function useMaxVisibleElectionDays(
  tableRef: React.RefObject<HTMLTableElement | undefined>,
  detailPaneOpen: boolean
): [number, number] {
  // Keep a state of the maximum number of election days we can show, based on
  // the current UI width of the table.
  const [maxVisibleElectionDays, setMaxVisibleElectionDays] = React.useState<
    [number, number]
  >([DEFAULT_VISIBLE_DAYS, 2]);

  const recalculateMaxVisibleElectionDays = React.useCallback(() => {
    // We only recalculate when the sidebar is closed because when it’s open it
    // makes the table narrower.
    if (tableRef.current && !detailPaneOpen) {
      const tableWidth = tableRef.current.clientWidth;

      setMaxVisibleElectionDays([
        Math.floor(
          (tableWidth - ROW_HEADER_COLUMN_WIDTH_PX) / DAY_COLUMN_MIN_WIDTH_PX
        ),
        Math.floor(
          (tableWidth - ROW_HEADER_COLUMN_WIDTH_PX) /
            DAY_COLUMN_MIN_EXPANDED_WIDTH_PX
        ),
      ]);
    }
  }, [tableRef, detailPaneOpen]);

  useResizeObserver({
    ref: tableRef,
    onResize: recalculateMaxVisibleElectionDays,
  });

  return maxVisibleElectionDays;
}

/**
 * react-router loader for {@link LocationAssignmentPage}.
 */
export async function loadLocationAssignmentPage(
  fluxStore: AppStore
): Promise<React.ComponentProps<typeof LocationAssignmentPage>> {
  const { currentUserElection } = await loadCurrentUser(fluxStore, {
    allowedRoles: ['vpd', 'deputy_vpd'],
  });

  const userTags = (await getUserTags()).tags;

  const currentElection = currentUserElection
    .get('election')
    .toJS() as ApiElection;

  return { currentElection, userTags };
}

export default LocationAssignmentPage;
