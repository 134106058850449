import { Immutable } from 'immer';
import { sortBy } from 'lodash';
import React from 'react';

import { ApiLocationHours } from '../../services/assignment-service';
import {
  CountySlug,
  DateString,
  formatTime,
  FormatTimeOptions,
  TimeString,
} from '../../services/common';
import {
  ApiLocationTierConfiguration,
  ApiUserTag,
  LanguageTag,
  isActiveLanguageTag,
  ApiElectionDay,
} from '../../services/lbj-shared-service';
import type { ApiVolunteerAvailability } from '../../services/volunteer-availability-service';
import { useAsyncGenerator } from '../../utils/hooks';
import { assertUnreachable } from '../../utils/types';

import type { AssignmentTableRow } from './AssignmentTable';
import type { AssignmentPageGridBulkEditMode } from './LocationAssignmentPage';
import {
  AssignmentDispatch,
  AssignmentLoadingStatus,
  AssignmentLocation,
  AssignmentRecord,
  AssignmentState,
  AssignmentUser,
  getAssignmentsByLocationId,
  getAssignmentsByUserId,
  getAssignmentsByUserIdForDates,
  initializeAssignmentStore,
} from './assignment-state';
import { PeopleTableRow } from './location-table/location-table-utils';

export const EDAY_AVAILABLE_ALL_DAY = 'eday_available_all_day';
export const EDAY_AVAILABLE_AM = 'eday_available_am';
export const EDAY_AVAILABLE_PM = 'eday_available_pm';
export const EDAY_AVAILABLE_AM_OR_PM = 'eday_available_either';

export const EDAY_POLL_OBSERVER_VOLUNTEER =
  'election_day_poll_observer_volunteer';
export const EV_POLL_OBSERVER_VOLUNTEER = 'early_vote_poll_observer_volunteer';

export type LocationTableRow = AssignmentTableRow<{
  location: Omit<AssignmentLocation, 'hours'>;
  assignments: (AssignmentRecord & { type: 'poll' })[];
  /**
   * Hours split out separately to accommodate “draft” hours from bulk editing.
   */
  hours: Readonly<ApiLocationHours[]>;

  /**
   * Whether or not we’re in “shift edit” mode. Needs to be part of the cell
   * data so that the table re-renders cells when the mode is entered.
   */
  bulkEditModeType: 'shifts' | null;

  isExpanded: boolean;
}>;

export type ShiftScoreOptions = {
  favorExperience: boolean;
  favorLegalCommunity: boolean;
};

/**
 * Meta info we need for calculating the shift scores.
 */
export type ShiftScoreConfig = ShiftScoreOptions & {
  maxStateRank: number | null;

  /**
   * Exponent used to reduce the differentiation among polling location
   * priorities as they become lower-priority. This could, for example, allow
   * choosing a 300th-priority location that’s closer than a 299th-priority
   * location.
   */
  blurExp: number;

  /**
   * Exponent to used to (relatively) boost the quality score for
   * higher-priority locations. This is what makes e.g. an experienced volunteer
   * more desireable at a high-priority location than a low-priority one.
   */
  shineExp: number;
};

export function makeShiftScoreConfig(
  options: ShiftScoreOptions,
  state: Pick<AssignmentState, 'locationsById'>
): ShiftScoreConfig {
  const maxStateRank = Math.max(
    ...[...state.locationsById.values()]
      .map(({ state_rank }) => state_rank)
      .filter((n): n is number => n !== null)
  );

  let blurExp;

  if (maxStateRank > 100) {
    // Find the normalized rank value of the 100th rank.
    const hundredthCutoff = 100 / maxStateRank;

    // Set the curve so that the difference in ranks at the 100th location is
    // 75% of the magnitude of difference in ranks at the top.
    blurExp = Math.log(0.75) / Math.log(hundredthCutoff);
  } else {
    // If fewer than 100 ranks, don’t blur.
    blurExp = 1;
  }

  return {
    ...options,

    // NEGATIVE_INFINITY is the case where `Math.max` is given no arguments.
    maxStateRank:
      maxStateRank === Number.NEGATIVE_INFINITY ? null : maxStateRank,

    blurExp,

    // for now, hard-code to 3
    shineExp: 3,
  };
}

/**
 * Returns the value of filling a particular shift. Higher numbers are better.
 *
 * TODO(fiona): Expand on this greatly as we get beyond just distance.
 */
export function getShiftScore(
  location: AssignmentLocation,
  volunteerAttributes: VolunteerAttributes,
  distanceMi: number,
  config: ShiftScoreConfig
) {
  const MAX_DISTANCE_MI = 1000;

  // Inverts rank so that the most important location is maxStateRank and the
  // least important location is 1. Useful because our scoring function is set
  // up to maximize.
  //
  // Locations without a `state_rank` value are set to 0.
  const rankScore =
    config.maxStateRank === null
      ? 1
      : location.state_rank === null
      ? // HACK(fiona): Without this we’re not getting assignments in some test
        // elections that have sporadic state_rank values.
        //
        // This treats locations w/o a rank as having the same rank as the
        // lowest specified rank.
        1
      : config.maxStateRank - location.state_rank + 1;

  const maxStateRank = config.maxStateRank ?? 1;

  // Put an exponent on this to make the difference in value between 1 and 2
  // miles more significant than 49 and 50 miles.
  const closeness = (1 - Math.min(distanceMi / MAX_DISTANCE_MI, 1)) ** 2;

  /**
   * How good the person going to this shift is (regardless of rank). Should be
   * normalized to max out at 1.
   *
   * The “shine” parameter will be later used to affect this, such that a high
   * quality at a prioritized location scores better than the same quality at a
   * lower-priority location.
   *
   * (This promotes shorter distances at our high-priority locations, and also
   * means that experience and legal community will score better at
   * higher-priority locations automatically.)
   */
  let quality: number;

  if (config.favorExperience || config.favorLegalCommunity) {
    quality = 0.5 * closeness;

    if (config.favorExperience && config.favorLegalCommunity) {
      if (
        volunteerAttributes.isExperienced &&
        volunteerAttributes.isLegalCommunity
      ) {
        quality += 0.5;
      } else if (
        volunteerAttributes.isExperienced ||
        volunteerAttributes.isLegalCommunity
      ) {
        quality += 0.35;
      }
    } else if (config.favorExperience) {
      if (volunteerAttributes.isExperienced) {
        quality += 0.5;
      }
    } else if (config.favorLegalCommunity) {
      if (volunteerAttributes.isLegalCommunity) {
        quality += 0.5;
      }
    }
  } else {
    quality = closeness;
  }

  /**
   * Blur makes lower-ranked locations less distinguished from each other, so
   * that quality boosts (like being closer) can be more important than
   * specific differences in rank.
   */
  const blur = (rankScore / maxStateRank) ** config.blurExp;

  /**
   * Shine makes quality more valuable at higher ranks. This is what makes the
   * system put valuable poll observers at higher ranked places.
   */
  const shine = (rankScore / maxStateRank) ** config.shineExp;

  return rankScore * blur + quality * shine;
}

/**
 * Loads initial assignment state from the backend on component mount. Returns
 * the status of the loading as it progresses.
 *
 * Built around {@link useAsyncGenerator} so it will stop fetching data if the
 * user leaves the page.
 *
 * Also returns a function that will reload the server state, in case we need to
 * resync (such as in the case of an error).
 */
export function useLoadInitialAssignmentState(
  assignmentDispatch: AssignmentDispatch
): [AssignmentLoadingStatus, (reset?: boolean) => void] {
  const [loadingGen, setLoadingGen] = React.useState(
    // It’s okay to call this function in the default value position (meaning it
    // executes every render), because unless it actually gets listened to via
    // `useAsyncGenerator` it won’t make any API requests.
    initializeAssignmentStore(assignmentDispatch)
  );

  const reloadServerState = React.useCallback(
    (reset: boolean = false) => {
      if (reset) {
        assignmentDispatch({ type: 'RESET' });
      }

      setLoadingGen(initializeAssignmentStore(assignmentDispatch));
    },
    [assignmentDispatch]
  );

  const result = useAsyncGenerator(loadingGen)?.value ?? { status: 'initial' };

  return [result, reloadServerState];
}

export type LocationGroupBy = 'none' | 'tier' | 'county' | 'city' | 'zip';
export type LocationSortBy = 'ranking' | 'name';

export type LocationRankingFilterOptions =
  | Immutable<{ type: 'all' }>
  | Immutable<{ type: 'custom'; range: [number, number] }>
  | Immutable<{
      type: 'tiers';
      tiers: number[];
      showUnrankedLocations: boolean;
    }>;

export type LocationMunicipalityFilterOptions =
  | Immutable<{ type: 'all' }>
  | Immutable<{ type: 'counties'; countySlugs: CountySlug[] }>
  | Immutable<{ type: 'cities'; cities: string[] }>;

export type LocationTypeFilterOptions = 'all' | 'eday' | 'ev';

export type LocationFilterOptions = Immutable<{
  rankings: LocationRankingFilterOptions;
  municipalities: LocationMunicipalityFilterOptions;
  locationType: LocationTypeFilterOptions;
  withSuggestedAssignments: boolean;
}>;

export const DEFAULT_LOCATION_FILTERS: LocationFilterOptions = {
  rankings: { type: 'all' },
  municipalities: { type: 'all' },
  locationType: 'all',
  withSuggestedAssignments: false,
};

export type PersonGroupBy =
  | 'none'
  | 'county'
  | 'cityState'
  | 'distance'
  | 'zip';
export type PersonSortBy = 'last_name';
export type PersonFilterOptions = Immutable<{
  edayAssignmentStatus: 'assigned' | 'unassigned' | 'any';
  filterForExperience: 'yes' | 'any';
  filterForLegalCommunity: 'yes' | 'any';
  filterForCarAccess: 'yes' | 'any';
  filterForLanguages:
    | { type: 'all' }
    | { type: 'languages'; languages: LanguageTag[] };
}>;

/**
 * Function to filter the list of locations from the assignment state and
 * optionally group them.
 *
 * Will return locations that have hours scheduled on the `focusDate` (if
 * provided) that match the `nameFilter`, or have active assignments from people
 * who match the `nameFilter`.
 *
 * For grouping, will still return a flat map of rows, but a `header` row with
 * the group’s title will preceed the `location` rows for members of the group.
 */
export function getFilteredSortedLocationList({
  assignmentState,
  groupBy,
  sortBy,
  tierConfiguration,
  locationFilters,
  nameFilter,
  bulkEditMode,
  expandCells,
  lastSuggestedAssignments,
}: {
  assignmentState: AssignmentState;
  groupBy: LocationGroupBy;
  sortBy: LocationSortBy;
  /**
   * Needs to be passed in to interpret per-tier filtering options.
   */
  tierConfiguration: ApiLocationTierConfiguration[];
  locationFilters: Immutable<LocationFilterOptions>;
  nameFilter: string;
  bulkEditMode: AssignmentPageGridBulkEditMode | null;
  /**
   * Used to filter when LocationFilterOptions.withSuggestedAssignments is set.
   */
  lastSuggestedAssignments: AssignmentRecord[];
  /**
   * If true, causes the cells to render with their “expanded” UI.
   *
   * Used for single-day view or when there are few-enough day columns.
   */
  expandCells: boolean;
}): LocationTableRow[] {
  const locations = filterLocations({
    assignmentState,
    tierConfiguration,
    nameFilter,
    filterOptions: locationFilters,
    lastSuggestedAssignments,
  });

  const assignmentsByLocationId = getAssignmentsByLocationId(assignmentState);

  // sort locations by rank and name
  locations.sort((locA, locB) => {
    // Use name if we’re sorting by name, but also as a fallback for
    // equally-ranked locations.
    if (sortBy === 'name' || locA.state_rank === locB.state_rank) {
      return locA.name === locB.name ? 0 : locA.name < locB.name ? -1 : 1;
    }

    if (locA.state_rank !== null && locB.state_rank !== null) {
      return locA.state_rank - locB.state_rank;
    } else if (locA.state_rank !== null) {
      return -1;
    } else {
      return 1;
    }
  });

  const locationToLocationRow = (
    location: AssignmentLocation
  ): LocationTableRow => ({
    type: 'item',
    key: `loc-${location.id}`,
    value: {
      location,
      assignments: assignmentsByLocationId.get(location.id) ?? [],
      hours:
        bulkEditMode?.type === 'shifts'
          ? mergeLocationHours(
              location.hours,
              bulkEditMode.hoursByLocationId?.get(location.id) ?? []
            )
          : location.hours,
      bulkEditModeType: bulkEditMode?.type ?? null,
      isExpanded: expandCells,
    },
  });

  if (groupBy !== 'none') {
    const locationsByGroupTitle = new Map<string, AssignmentLocation[]>();

    for (const loc of locations) {
      let groupTitle: string;

      switch (groupBy) {
        case 'city':
          groupTitle = loc.city || 'Unknown City';
          break;

        case 'zip':
          groupTitle = loc.zipcode || 'Unknown ZIP';
          break;

        case 'county':
          groupTitle = `${loc.county?.name || 'Unknown'} County`;
          break;

        case 'tier': {
          const stateRank = loc.state_rank;

          if (stateRank === null) {
            // Hack that this title is alphabetically after the others so we
            // don’t have to manually sort it to the bottom.
            groupTitle = 'Unranked Locations';
          } else {
            const tierIdx = tierConfiguration.findIndex(
              (config) =>
                stateRank >= config.state_rank_gte &&
                stateRank <= config.state_rank_lte
            );
            if (tierIdx === -1) {
              groupTitle = 'Tier Not Specified';
            } else {
              groupTitle = `Tier ${tierIdx + 1}`;
            }
          }
          break;
        }

        default:
          assertUnreachable(groupBy);
      }

      if (!locationsByGroupTitle.has(groupTitle)) {
        locationsByGroupTitle.set(groupTitle, [loc]);
      } else {
        // We’re okay with mutation since we’re building up the data structure.
        locationsByGroupTitle.get(groupTitle)!.push(loc);
      }
    }

    const groupTitles = [...locationsByGroupTitle.keys()];
    groupTitles.sort();

    return groupTitles.flatMap((title) => [
      { type: 'header', key: `header-${title}`, title },
      ...locationsByGroupTitle.get(title)!.map(locationToLocationRow),
    ]);
  } else {
    return locations.map(locationToLocationRow);
  }
}

export type ShiftCoverage = 'none' | 'partial' | 'full';

export function filterLocations({
  assignmentState,
  tierConfiguration,
  nameFilter,
  filterOptions: {
    rankings,
    municipalities,
    locationType,
    withSuggestedAssignments,
  },
  lastSuggestedAssignments,
}: {
  assignmentState: AssignmentState;
  tierConfiguration: ApiLocationTierConfiguration[];
  nameFilter: string;
  filterOptions: Immutable<LocationFilterOptions>;
  lastSuggestedAssignments: AssignmentRecord[];
}) {
  let locations = [...assignmentState.locationsById.values()];

  if (withSuggestedAssignments) {
    const suggestedLocationIds = new Set(
      lastSuggestedAssignments
        .filter(
          (a): a is AssignmentRecord & { type: 'poll' } => a.type === 'poll'
        )
        .map((a) => a.locationId)
    );

    locations = locations.filter(({ id }) => suggestedLocationIds.has(id));
  }

  const allowedRanges: (readonly [number, number])[] = [];
  let showUnrankedLocations: boolean;

  switch (rankings.type) {
    case 'all':
      allowedRanges.push([Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]);
      showUnrankedLocations = true;
      break;

    case 'custom':
      allowedRanges.push(rankings.range);
      showUnrankedLocations = false;
      break;

    case 'tiers':
      for (let idx = 0; idx < tierConfiguration.length; ++idx) {
        if (rankings.tiers.includes(idx)) {
          allowedRanges.push([
            tierConfiguration[idx]!.state_rank_gte,
            tierConfiguration[idx]!.state_rank_lte,
          ]);
        }
      }
      showUnrankedLocations = rankings.showUnrankedLocations;
      break;

    default:
      assertUnreachable(rankings);
  }

  switch (locationType) {
    case 'all':
      break;

    case 'eday':
      locations = locations.filter((l) => l.election_day === true);
      break;

    case 'ev':
      locations = locations.filter((l) => l.early_vote === true);
      break;

    default:
      assertUnreachable(locationType);
  }

  locations = locations.filter((loc) => {
    if (loc.state_rank === null) {
      return showUnrankedLocations;
    } else {
      return !!allowedRanges.find(
        ([min, max]) => loc.state_rank! >= min && loc.state_rank! <= max
      );
    }
  });

  switch (municipalities.type) {
    case 'all':
      break;

    case 'counties':
      if (municipalities.countySlugs.length > 0) {
        locations = locations.filter(
          (l) =>
            l.county.slug !== '' &&
            municipalities.countySlugs.includes(l.county.slug)
        );
      }
      break;

    case 'cities':
      if (municipalities.cities.length > 0) {
        locations = locations.filter((l) =>
          // It’s ok for this to not deal with case stuff because it’s not a
          // freeform user input, they’ve chosen from the cities that are
          // already on `AssignmentLocation` objects.
          municipalities.cities.includes(l.city)
        );
      }
      break;

    default:
      assertUnreachable(municipalities);
  }

  if (nameFilter) {
    // We want to include the rows for these locations, since they have
    // assignments for users who match the filter param.
    const locationIdsMatchingFilteredUsers = new Set<number>();

    const assignmentsByUserId = getAssignmentsByUserId(assignmentState);

    for (const user of assignmentState.usersById.values()) {
      if (
        `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`.indexOf(
          nameFilter.toLowerCase()
        ) >= 0
      ) {
        (assignmentsByUserId.get(user.id) ?? []).forEach((a) => {
          if (a.type === 'poll') {
            locationIdsMatchingFilteredUsers.add(a.locationId);
          }
        });
      }
    }

    locations = locations.filter(
      (loc) =>
        loc.name.toLowerCase().includes(nameFilter.toLowerCase()) ||
        locationIdsMatchingFilteredUsers.has(loc.id)
    );
  }

  return locations;
}

/**
 * Returns a function that checks for a {@link ApiVolunteerAvailability} that
 * matches the given criteria.
 */
export function makeAvailabilityMatcher({
  date,
  location,
  distanceMi,
  user,
}: {
  date: DateString;
  location: Pick<AssignmentLocation, 'city' | 'county'>;
  user: Pick<AssignmentUser, 'max_distance_miles'>;
  /** Distance between the user and the location */
  distanceMi: number | null;
}): (value: Immutable<ApiVolunteerAvailability>) => boolean {
  return (a) => {
    if (a.date !== date) {
      return false;
    }

    if (
      a.allowed_location_cities &&
      !a.allowed_location_cities.includes(location.city)
    ) {
      return false;
    }

    if (
      a.allowed_location_county_slugs &&
      !a.allowed_location_county_slugs.includes(location.county.slug)
    ) {
      return false;
    }

    if (
      a.respect_travel_distance_tag &&
      (distanceMi === null ||
        typeof user.max_distance_miles !== 'number' ||
        distanceMi >= user.max_distance_miles)
    ) {
      return false;
    }

    return true;
  };
}

/**
 * Given 2 lists of {@link ApiLocationHours}, returns a new list that merges the
 * 2, with values from the 2nd list replacing any from the 1st list with the
 * same date.
 *
 * If the 2nd list is empty, just returns the 1st list (rather than a copy of
 * it).
 *
 * Does not preserve the date ordering of the list.
 */
export function mergeLocationHours(
  sourceArr: Readonly<ApiLocationHours[]>,
  overrideArr: Readonly<ApiLocationHours[]>
): Readonly<ApiLocationHours[]> {
  if (overrideArr.length === 0) {
    return sourceArr;
  }

  // Start with a list of everthing from source that’s not in override.
  const mergedArr = sourceArr.filter(
    (curH) => !overrideArr.find((newH) => newH.date === curH.date)
  );

  mergedArr.push(...overrideArr);

  return mergedArr;
}

/**
 * Function that handles the filtering of users/volunteers based on optional
 * search filters.
 *
 * Returns users who have the `poll_observer` role or have a poll observer tag.
 *
 * PersonFilters are manually selected in Auto-assignment -> People view, where
 * users can optionally filter for volunteers with/without assignments, and/or
 * filter volunteers based on VolunteerAttributes (legal community, experienced,
 * car access)
 */
export function filterUsers(
  usersById: Immutable<Map<number, AssignmentUser>>,
  edayAssignmentsByUserId?: Map<number, AssignmentRecord[]>,
  personFilters?: Immutable<PersonFilterOptions>,
  nameFilter = ''
): AssignmentUser[] {
  return [...usersById.values()].filter((u) => {
    const { isEdayVolunteer, isEvVolunteer } = getVolunteerAttributes(u);

    if (u.role !== 'poll_observer' && !isEdayVolunteer && !isEvVolunteer) {
      return false;
    }

    if (nameFilter) {
      const fullName = `${u.first_name} ${u.last_name}`;

      if (
        !fullName.toLowerCase().startsWith(nameFilter.toLowerCase()) &&
        !u.first_name.toLowerCase().startsWith(nameFilter.toLowerCase()) &&
        !u.last_name.toLowerCase().startsWith(nameFilter.toLowerCase())
      ) {
        return false;
      }
    }

    if (personFilters) {
      const {
        filterForCarAccess,
        filterForExperience,
        filterForLegalCommunity,
        filterForLanguages,
      } = personFilters;

      const tagInfoFilters: string[] = [];
      if (filterForCarAccess === 'yes') {
        tagInfoFilters.push('car_access');
      }
      if (filterForExperience === 'yes') {
        tagInfoFilters.push('experienced');
      }
      if (filterForLegalCommunity === 'yes') {
        tagInfoFilters.push('legal_community');
      }
      const userTagsPassInfoFilters = tagInfoFilters.every((filter) => {
        return u.tags.includes(filter);
      });
      if (!userTagsPassInfoFilters) {
        return false;
      }

      if (filterForLanguages.type === 'languages') {
        // language filters look for users who speak
        // any of the matching language filters
        if (
          filterForLanguages.languages.length !== 0 &&
          u.tags.filter((t) =>
            filterForLanguages.languages.includes(t as LanguageTag)
          ).length === 0
        ) {
          return false;
        }
      }
    }

    // TODO(fiona): Need to square this with EV support, but later.
    if (edayAssignmentsByUserId) {
      if (personFilters?.edayAssignmentStatus === 'assigned') {
        // an unassigned user will return false
        if ((edayAssignmentsByUserId.get(u.id) ?? []).length === 0) {
          return false;
        }
      }
      if (personFilters?.edayAssignmentStatus === 'unassigned') {
        // an assigned user will return false
        if ((edayAssignmentsByUserId.get(u.id) ?? []).length > 0) {
          return false;
        }
      }
    }

    return true;
  });
}

/**
 * Function to filter list of volunteers based on:
 * - assignment status
 * - volunteer attributes (see filterUsers)
 * - name search term
 *
 * Returns list of volunteers that match provided filter criteria. If no
 * volunteers meet filter criteria, returns an empty list.
 *
 * Also supports optional grouping of volunteers.
 *
 * Returns anyone who has the poll_observer role as well as anyone with one of
 * the poll observer tags.
 */
export function getFilteredSortedPersonList(
  assignmentState: AssignmentState,
  electionDay: DateString | null,
  groupBy: PersonGroupBy,
  personFilters: Immutable<PersonFilterOptions>,
  nameFilter: string
): PeopleTableRow[] {
  const allAssignmentsByUserId = getAssignmentsByUserId(assignmentState);
  const edayAssignmentsByUserId = electionDay
    ? getAssignmentsByUserIdForDates(assignmentState, [electionDay])
    : undefined;

  const people = filterUsers(
    assignmentState.usersById,
    edayAssignmentsByUserId,
    personFilters,
    nameFilter
  );

  // sort volunteers by name
  people.sort((personA, personB) => {
    return personA.last_name === personB.last_name
      ? 0
      : personA.last_name < personB.last_name
      ? -1
      : 1;
  });

  if (groupBy !== 'none') {
    const volunteersByGroupTitle = new Map<string, AssignmentUser[]>();

    for (const person of people) {
      let groupTitle: string;
      switch (groupBy) {
        case 'cityState':
          if (person.city && person.state) {
            groupTitle = `${person.city}, ${person.state}`;
          } else {
            groupTitle = 'Unknown City, State';
          }
          break;

        case 'county':
          groupTitle = `${person.county_name || 'Unknown'} County`;
          break;

        case 'distance': {
          const travel_max_distance = person.max_distance_miles;

          if (!travel_max_distance) {
            groupTitle = 'Unknown Travel Distance';
          } else {
            groupTitle = `Travel up to ${person.max_distance_miles}mi`;
          }
          break;
        }

        case 'zip': {
          groupTitle = person.zipcode || 'Unknown ZIP';
          break;
        }

        default:
          assertUnreachable(groupBy);
      }

      if (!volunteersByGroupTitle.has(groupTitle)) {
        volunteersByGroupTitle.set(groupTitle, [person]);
      } else {
        // We’re okay with mutation since we’re building up the data structure.
        volunteersByGroupTitle.get(groupTitle)!.push(person);
      }
    }

    const groupTitles = [...volunteersByGroupTitle.keys()];
    if (groupBy === 'distance') {
      // special handling for travel distance strings
      groupTitles.sort((a, b) => {
        // falls back to 10,000 to ensure
        // 'Unknown Travel Distance' is last in groupings
        const distanceA =
          a.split('Travel up to ')[1]?.split('mi')[0] ?? '10000';
        const distanceB =
          b.split('Travel up to ')[1]?.split('mi')[0] ?? '10000';
        return parseInt(distanceA) - parseInt(distanceB);
      });
    } else {
      groupTitles.sort();
    }

    return groupTitles.flatMap((title) => [
      { type: 'header', key: `header-${title}`, title },
      ...volunteersByGroupTitle.get(title)!.map<PeopleTableRow>((user) => ({
        type: 'item',
        key: user.id,
        title: `${user.first_name} ${user.last_name}`,
        value: {
          user,
          assignments: allAssignmentsByUserId.get(user.id) ?? [],
          availability: assignmentState.availabilityByUserId.get(user.id) ?? [],
        },
      })),
    ]);
  } else {
    return people.map((user) => ({
      type: 'item',
      key: user.id,
      title: `${user.first_name} ${user.last_name}`,
      value: {
        user,
        assignments: allAssignmentsByUserId.get(user.id) ?? [],

        availability: assignmentState.availabilityByUserId.get(user.id) ?? [],
      },
    }));
  }
}

/**
 * Returns the AM and PM {@link ShiftCoverage} values for the assignment, given
 * the hours at that location on that day.
 *
 * If the location doesn’t have a shift change time, both return values will
 * reflect whether the location is all day.
 */
export function calculateAssignmentShiftCoverage(
  hours: ApiLocationHours,
  assignment: AssignmentRecord
): [ShiftCoverage, ShiftCoverage] {
  let amCoverage: ShiftCoverage;
  let pmCoverage: ShiftCoverage;

  // All of the time strings are 0-padded 24 hour time, so we can compare them
  // lexographically.

  if (hours.shift_change_time !== null) {
    if (
      assignment.startTime <= hours.open_time &&
      assignment.endTime >= hours.shift_change_time
    ) {
      amCoverage = 'full';
    } else if (assignment.startTime < hours.shift_change_time) {
      amCoverage = 'partial';
    } else {
      amCoverage = 'none';
    }

    if (
      assignment.startTime <= hours.shift_change_time &&
      assignment.endTime >= hours.close_time
    ) {
      pmCoverage = 'full';
    } else if (assignment.endTime > hours.shift_change_time) {
      pmCoverage = 'partial';
    } else {
      pmCoverage = 'none';
    }
  } else {
    if (
      assignment.startTime <= hours.open_time &&
      assignment.endTime >= hours.close_time
    ) {
      amCoverage = 'full';
      pmCoverage = 'full';
    } else {
      amCoverage = 'partial';
      pmCoverage = 'partial';
    }
  }

  return [amCoverage, pmCoverage];
}

/**
 * Returns the appropriate part of `text_hours` for a location based on whether
 * we want early vote or election day. If a location is open for both early vote
 * and election day, the SQL that syncs that data from the VLP concatenates the
 * early vote text hours and the election day text hours values together. This
 * function undoes that operation, returning only the relevant string.
 *
 * If the location is only open for one or the other of EV or EDay, returns the
 * full `text_hours` (if they match `dateIsEv`).
 */
export function extractTextHours(
  location: Pick<
    AssignmentLocation,
    'text_hours' | 'early_vote' | 'election_day'
  >,
  /** True if we want ev hours, false if we want eday hours */
  dateIsEv: boolean
): string | null {
  if (location.early_vote && !location.election_day) {
    return dateIsEv ? location.text_hours : null;
  } else if (!location.early_vote && location.election_day) {
    return dateIsEv ? null : location.text_hours;
  } else if (location.early_vote && location.election_day) {
    // See voting_locations.sql in lbj-backend for how this string is built up
    // in the first place.
    const match = /^EV: (.*), Eday: (.*)$/.exec(location.text_hours ?? '');

    if (match) {
      return match[dateIsEv ? 1 : 2]!;
    } else {
      // Things didn’t match our format… so we’ll return what’s there in case
      // it’s useful.
      return location.text_hours;
    }
  } else {
    // This is a location that’s neither EV or EDay… not sure what’s up, but
    // might as well return what we have.
    return location.text_hours;
  }
}

const DEFAULT_SHIFTS_PER_LOCATION = 1;

/**
 * Returns the number of am/pm shifts for a location based on its rank, hours,
 * and tier configuration.
 *
 * This function has a lot of fallback cases.
 *
 * If a location has shift counts in its {@link ApiLocationHours} record, those
 * take precidence, since they’re specifically set.
 *
 * Otherwise, if the election has tiers set up, we use the config for the tier
 * that the location is part of. If the location has no rank or is not covered
 * by a rank, we return 0. If the location has a rank but that rank doesn’t have
 * any values set, we default to 1, since the config must pre-date adding shift
 * configs.
 *
 * If the election does not have tiers configured, we return 1s.
 */
export function shiftCountsForLocation(
  location: Pick<AssignmentLocation, 'state_rank'>,
  hours: Pick<ApiLocationHours, 'am_shift_count' | 'pm_shift_count'>,
  tierConfiguration: ApiLocationTierConfiguration[]
): [number, number] {
  let amShiftCount = hours.am_shift_count;
  let pmShiftCount = hours.pm_shift_count;

  const hasConfigByRanking = !!tierConfiguration.find(
    (c) =>
      typeof c.eday_am_shift_count === 'number' ||
      typeof c.eday_pm_shift_count === 'number'
  );

  if (hasConfigByRanking) {
    const tier =
      typeof location.state_rank === 'number' &&
      tierConfiguration.find(
        (c) =>
          location.state_rank! >= c.state_rank_gte &&
          location.state_rank! <= c.state_rank_lte
      );

    if (tier) {
      amShiftCount =
        amShiftCount ?? tier.eday_am_shift_count ?? DEFAULT_SHIFTS_PER_LOCATION;
      pmShiftCount =
        pmShiftCount ?? tier.eday_pm_shift_count ?? DEFAULT_SHIFTS_PER_LOCATION;
    } else {
      amShiftCount = amShiftCount ?? 0;
      pmShiftCount = pmShiftCount ?? 0;
    }
  } else {
    amShiftCount = amShiftCount ?? DEFAULT_SHIFTS_PER_LOCATION;
    pmShiftCount = pmShiftCount ?? DEFAULT_SHIFTS_PER_LOCATION;
  }

  return [amShiftCount, pmShiftCount];
}

/**
 * These are here to do a sort of half-assed masonry view in the details pane
 * and the summary cells. We have everything that spans both columns first, then
 * we have two columns, one for AM and one for PM.
 *
 * (Swap “rows” and “columns” for when this is used in table cells… this was
 * written for the details pane first, so the nomenclature reflects its
 * orientation.)
 *
 * `null` in these arrays means “render this but show the ‘unassigned shift’
 * text in the bubble.”
 */
export function shiftRowsFromAssignments(
  location: Pick<AssignmentLocation, 'state_rank'>,
  assignments: (AssignmentRecord & { type: 'poll' })[],
  hours: ApiLocationHours,
  tierConfiguration: ApiLocationTierConfiguration[]
): {
  fullRows: ((AssignmentRecord & { type: 'poll' }) | null)[];
  amRows: ((AssignmentRecord & { type: 'poll' }) | null)[];
  pmRows: ((AssignmentRecord & { type: 'poll' }) | null)[];
} {
  const fullRows: Array<(AssignmentRecord & { type: 'poll' }) | null> = [];
  const amRows: Array<(AssignmentRecord & { type: 'poll' }) | null> = [];
  const pmRows: Array<(AssignmentRecord & { type: 'poll' }) | null> = [];

  for (const assignment of assignments) {
    const [amCoverage, pmCoverage] = calculateAssignmentShiftCoverage(
      hours,
      assignment
    );

    if (amCoverage === 'none') {
      pmRows.push(assignment);
    } else if (pmCoverage === 'none') {
      amRows.push(assignment);
    } else {
      fullRows.push(assignment);
    }
  }

  const [amShiftCount, pmShiftCount] = shiftCountsForLocation(
    location,
    hours,
    tierConfiguration
  );

  if (hours.shift_change_time) {
    // Fill out the rows with “unassigned shift” bubbles. If there’s no shift
    // change time configured then we do this in the full row array for “all
    // day” shifts.
    while (fullRows.length + amRows.length < amShiftCount) {
      amRows.push(null);
    }

    while (fullRows.length + pmRows.length < pmShiftCount) {
      pmRows.push(null);
    }
  } else {
    // If there’s no shift change time, only use the AM value for how many
    // shifts are desired.
    while (fullRows.length < amShiftCount) {
      fullRows.push(null);
    }
  }
  return { fullRows, amRows, pmRows };
}

export type LocationShiftType = 'am' | 'pm' | 'day';

export type ShiftTimes = [TimeString | null, TimeString | null];

/**
 * Returns the times for particular shift types. May return `null` if the
 * location isn’t configured for that type of shift.
 */
export function shiftTimesForType(
  hours: ApiLocationHours,
  shiftType: LocationShiftType
): ShiftTimes {
  switch (shiftType) {
    case 'am':
      return [hours.open_time, hours.shift_change_time];

    case 'pm':
      return [hours.shift_change_time, hours.close_time];

    case 'day':
      return [hours.open_time, hours.close_time];

    default:
      assertUnreachable(shiftType);
  }
}

const DEG_TO_RAD = Math.PI / 180;
const MEAN_EARTH_RADIUS_MI = 3958.8;

export type DistanceMiLookupFunc = (
  locationId: number,
  userId: number
) => number | null;

/**
 * Makes a function that coordinates and calculates the distance in miles.
 * Coordinates are specified as [long, lat] to match GeoJSON spec, _&c._.
 *
 * Uses an eqirectangular projection for speed. We don’t need precise distances
 * since travel times aren’t precise anyway.
 *
 * Based on: https://www.movable-type.co.uk/scripts/latlong.html
 */
export function makeDistanceMiLookupFunc(
  state: Pick<AssignmentState, 'usersById' | 'locationsById'>
): DistanceMiLookupFunc {
  const users = [...state.usersById.values()];
  const locations = [...state.locationsById.values()];

  /**
   * Array for storing distances (or null if something can’t be geocoded).
   *
   * Faux 2D array, indexed by “rows” of users and “columns” of locations.
   *
   * We use a single array rather than the previous “nested `Map`s” solution for
   * speed, since we don’t need to allocate so many things.
   */
  const distances = new Array<number | null>(users.length * locations.length);

  for (let userIndex = 0; userIndex < users.length; ++userIndex) {
    for (
      let locationIndex = 0;
      locationIndex < locations.length;
      ++locationIndex
    ) {
      const userLoc = users[userIndex]!.coordinates;
      const locationLoc = locations[locationIndex]!.coordinates;

      let distanceMi: number | null;

      if (userLoc === null || locationLoc.length === 0) {
        distanceMi = null;
      } else {
        const [lng1, lat1] = userLoc;
        const [lng2, lat2] = locationLoc;

        const lat1Rads = lat1 * DEG_TO_RAD;
        const lat2Rads = lat2 * DEG_TO_RAD;

        const lng1Rads = lng1 * DEG_TO_RAD;
        const lng2Rads = lng2 * DEG_TO_RAD;

        const x = (lng2Rads - lng1Rads) * Math.cos((lat1Rads + lat2Rads) / 2);
        const y = lat2Rads - lat1Rads;

        // Solving for the hypotenuse via Pythagoras’s Theorem
        distanceMi = Math.sqrt(x * x + y * y) * MEAN_EARTH_RADIUS_MI;
      }

      distances[userIndex * locations.length + locationIndex] = distanceMi;
    }
  }

  return (locationId: number, userId: number) => {
    // TODO(fiona): Could make a reverse lookup from ids to indices? To remove
    // the O(n) scan here.
    const userIndex = users.findIndex((u) => u.id === userId);
    const locationIndex = locations.findIndex((loc) => loc.id === locationId);

    if (userIndex === -1 || locationIndex === -1) {
      return null;
    } else {
      return distances[userIndex * locations.length + locationIndex] ?? null;
    }
  };
}

/**
 * Combination of the eday availabilty tags and the more detailed availability
 * from {@link ApiVolunteerAvailability}.
 */
export type VolunteerAvailabilityTime =
  | 'am'
  | 'pm'
  | 'all day'
  | 'either'
  | Immutable<[TimeString, TimeString][]>;

/**
 * Reasons why someone can’t be assigned at a location on a particular day.
 */
export const CANT_ASSIGN_REASONS = [
  'too far',
  'distance unknown',
  'no distance preference',
  'invalid registration',
  'no availability',
  'missing volunteer tag',
] as const;

export type CantAssignReason = typeof CANT_ASSIGN_REASONS[number];

export type VolunteerAttributes = {
  isEdayVolunteer: boolean;
  isEvVolunteer: boolean;

  isExperienced: boolean;
  isLegalCommunity: boolean;
  hasCarAccess: boolean;

  languageTags: string[];

  edayAvailability: VolunteerAvailabilityTime | null;
};

export function getVolunteerAttributes(
  user: Pick<AssignmentUser, 'tags'>
): VolunteerAttributes {
  let edayAvailability: VolunteerAttributes['edayAvailability'] = null;

  if (user.tags.includes(EDAY_AVAILABLE_AM)) {
    edayAvailability = 'am';
  } else if (user.tags.includes(EDAY_AVAILABLE_PM)) {
    edayAvailability = 'pm';
  } else if (user.tags.includes(EDAY_AVAILABLE_AM_OR_PM)) {
    edayAvailability = 'either';
  } else if (user.tags.includes(EDAY_AVAILABLE_ALL_DAY)) {
    edayAvailability = 'all day';
  }

  return {
    isEvVolunteer: user.tags.includes(EV_POLL_OBSERVER_VOLUNTEER),
    isEdayVolunteer: user.tags.includes(EDAY_POLL_OBSERVER_VOLUNTEER),
    isExperienced: user.tags.includes('experienced'),
    isLegalCommunity: user.tags.includes('legal_community'),
    hasCarAccess: user.tags.includes('car_access'),
    // TODO: This should probably look up
    // tags in ApiUserTag[] to look at the
    // tag type - isActiveLanguageTag checks for
    // a tag that starts with 'speaks_'
    languageTags: user.tags.filter((tag) => isActiveLanguageTag(tag)),
    edayAvailability,
  };
}

export function availabilityToString(
  avail: VolunteerAvailabilityTime,
  formatTimeOptions: FormatTimeOptions = {}
): string {
  switch (avail) {
    case 'am':
      return 'morning';
    case 'pm':
      return 'afternoon';
    case 'all day':
      return 'all day';
    case 'either':
      return 'moring or afternoon';
    default:
      // Avail is a time range array
      return avail
        .map(
          ([t1, t2]) =>
            `${formatTime(t1, formatTimeOptions)}-${formatTime(
              t2,
              formatTimeOptions
            )}`
        )
        .join(', ');
  }
}

/**
 * Collects all unique languages spoken by volunteers to support
 * filtering within the relevant/available languages.
 * End result is sorted alphabetically, based on the tag's display name.
 */
export function getUserLanguageTagInfos(
  usersById: Immutable<Map<number, AssignmentUser>>,
  userTags: ApiUserTag[]
) {
  const allLanguageTags = userTags.filter(
    (tag) => tag.type === 'language' && isActiveLanguageTag(tag.name)
  );
  // start with a set, to avoid duplicates
  const matchedLanguageTags = new Set<ApiUserTag>();

  for (const user of usersById.values()) {
    for (const tag of user.tags) {
      const matchedTag = allLanguageTags.find(
        (langTag) => langTag.name === tag
      );
      if (matchedTag) {
        matchedLanguageTags.add(matchedTag);
      }
    }
  }
  // convert to array, for sorting
  const validatedLanguageTags = Array.from(matchedLanguageTags);
  return sortBy(validatedLanguageTags, 'display_name');
}

/**
 * Formats language tags to be human readable.
 * End result is sorted alphabetically.
 */
export function volunteerLanguagesToLanguageNames(languages: string[]) {
  return languages
    .map((t) => {
      // strip out 'speaks_' and any additional '_'
      // handles upcasing/spaces as needed
      return t
        .replace('speaks_', '')
        .split('_')
        .map((s) => `${s[0]!.toUpperCase()}${s.substring(1)}`)
        .join(' ');
    })
    .sort();
}

/**
 * Helper for dealing with an array of {@link ApiElectionDay}s.
 */
export function splitElectionDays(electionDays: ApiElectionDay[]): {
  electionDay: ApiElectionDay;
  /** Days of EV. Might be empty. Will be sorted by date. */
  evDays: ApiElectionDay[];
} {
  // We know there has to be one day that’s not early vote
  const electionDay = electionDays.find((d) => !d.is_early_vote)!;
  const evDays = electionDays.filter((d) => d.is_early_vote);

  evDays.sort((d1, d2) => {
    if (d1.date < d2.date) {
      return -1;
    } else if (d1.date > d2.date) {
      return 1;
    } else {
      return 0;
    }
  });

  return { electionDay, evDays };
}
