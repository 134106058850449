import { parseTime } from '@internationalized/date';
import cx from 'classnames';
import { Immutable } from 'immer';
import { isEqual } from 'lodash';
import React from 'react';
import { Item } from 'react-stately';

import { DateBadge } from '../../../components/common';

import {
  ActionButton,
  Checkbox,
  IconButton,
  ListBox,
  TextButton,
  TextField,
  TextRadioGroup,
} from '../../../components/form';
import { TimeField } from '../../../components/form/DateField';

import { ModalDialog, Popover } from '../../../components/layout';
import { ApiLocationHours } from '../../../services/assignment-service';
import { TimeString } from '../../../services/common';
import { useStateWithDeps } from '../../../utils/hooks';
import { numberToStringWithCommas } from '../../../utils/strings';
import { assertUnreachable, Awaitable } from '../../../utils/types';
import {
  AssignmentLocation,
  AssignmentRecord,
  AssignmentUserForLocation,
  UserCurrentAssignments,
} from '../assignment-state';
import {
  availabilityToString,
  extractTextHours,
  getVolunteerAttributes,
  ShiftTimes,
  shiftTimesForType,
  volunteerLanguagesToLanguageNames,
} from '../assignment-utils';

import {
  describeDistanceMi,
  iconForDistanceMi,
  makeGoogleDirectionsUrl,
} from './location-table-utils';

type UserSortType = 'distance' | 'recommended' | 'greedy';

/**
 * Modal dialog box to allow creating, editing, and deleting an assignment.
 */
const AssignmentModal: React.FunctionComponent<{
  location: Omit<AssignmentLocation, 'hours'>;
  /** Specific {@link ApiLocationHours} for this location/day */
  hours: ApiLocationHours;
  assignment: (AssignmentRecord & { type: 'poll' }) | null;

  /**
   * List of all of the users, including the current assignee and users that are
   * not valid because they’re too far / not geocoded.
   */
  allUsersForLocation: (AssignmentUserForLocation & UserCurrentAssignments)[];

  locationsById: Immutable<Map<number, AssignmentLocation>>;

  doClose: () => void;
  deleteAssignment: (
    assignment: AssignmentRecord & { type: 'poll' }
  ) => Awaitable<void>;
  saveAssignment: (
    newAssignment: AssignmentRecord & { type: 'poll' },
    originalAssignment: (AssignmentRecord & { type: 'poll' }) | null,
    replaceExistingAssignments: boolean
  ) => Awaitable<void>;

  defaultStartTime: TimeString | null;
  defaultEndTime: TimeString | null;
  defaultUserId: number | null;

  isEDay: boolean;
}> = ({
  location,
  hours,
  assignment,
  allUsersForLocation,
  locationsById,
  doClose,
  deleteAssignment,
  saveAssignment,
  defaultStartTime,
  defaultEndTime,
  defaultUserId,
  isEDay,
}) => {
  const [shiftTimes, setShiftTimes] = React.useState<ShiftTimes>([
    defaultStartTime,
    defaultEndTime,
  ]);

  const [userSort, setUserSort] = React.useState<UserSortType>('recommended');

  const [userFilter, setUserFilter] = React.useState('');

  const [currentUserId, setCurrentUserId] = React.useState(
    assignment?.userId ?? defaultUserId
  );

  const [replaceExistingAssignments, setReplaceExistingAssignments] =
    useStateWithDeps(false, [currentUserId]);

  /** Record for the selected user (or null if no one selected). */
  const currentUserForLocation =
    (currentUserId !== null &&
      allUsersForLocation.find((u) => u.user.id === currentUserId)) ||
    null;

  const userListItems = allUsersForLocation
    .filter(
      ({ user, cantAssignReasons }) =>
        // Only show users that can be assigned, unless we’re filtering by name,
        // in which case filter against everyone.
        //
        // TODO(fiona): think about relaxing this to show certain types of
        // un-assignable people, with appropriate UI caveats.
        //
        // Also always show the selected person.
        (cantAssignReasons.length === 0 ||
          userFilter !== '' ||
          user.id === currentUserId ||
          user.id === defaultUserId) &&
        (userFilter === '' ||
          user.first_name.toLowerCase().startsWith(userFilter.toLowerCase()) ||
          user.last_name.toLowerCase().startsWith(userFilter.toLowerCase()))
    )
    .map(
      ({
        user,
        distanceMi,
        currentAssignments,
        currentAssignmentScores,
        score,
        availabilityTime: availability,
      }) => {
        const currentAssignmentLocations = currentAssignments.map((a) =>
          a.type === 'poll' ? locationsById.get(a.locationId) : null
        );

        return {
          ...user,
          distanceMi,
          currentAssignments,
          currentAssignmentLocations,
          score,
          // `scoreDiff` measures the value of putting this user at this location,
          // given that you lose the value of removing them from their current
          // assignment. If the user is already _at_ this location, then there’s
          // nothing to lose.
          //
          // This keeps the current user sorted appropriately in the
          // “recommended” sort.
          scoreDiff:
            user.id === assignment?.userId
              ? score
              : score - Math.max(...currentAssignmentScores, 0),
          availability,
        };
      }
    );

  userListItems.sort((a, b) => {
    if (a.id === defaultUserId) {
      return -1;
    } else if (b.id === defaultUserId) {
      return 1;
    } else {
      switch (userSort) {
        case 'distance':
          // smallest first
          return (
            (a.distanceMi ?? Number.MAX_SAFE_INTEGER) -
            (b.distanceMi ?? Number.MAX_SAFE_INTEGER)
          );

        case 'recommended':
          // largest first
          return b.scoreDiff - a.scoreDiff;

        case 'greedy':
          // largest first
          return b.score - a.score;

        default:
          assertUnreachable(userSort);
      }
    }
  });

  const isValid =
    currentUserId !== null &&
    shiftTimes[0] !== null &&
    shiftTimes[1] !== null &&
    // We don’t allow replacing if the user is assigned to something non-poll on
    // the day.
    (!replaceExistingAssignments ||
      !currentUserForLocation?.currentAssignments.find(
        (a) => a.type !== 'poll'
      ));

  const [isSaving, setSaving] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  return (
    <ModalDialog
      title={
        assignment
          ? assignment.source === 'auto'
            ? 'Edit Suggested Assignment'
            : 'Edit Assignment'
          : 'Create Assignment'
      }
      showClose
      doClose={doClose}
    >
      <div className="flex h-[80vh] max-h-[600px] min-h-[400px] w-[800px] flex-col gap-4 text-base">
        <div className="flex items-start justify-between">
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <DateBadge shiftDate={hours.date} />

              <div className="flex flex-col gap-0.5">
                <div className="font-bold">
                  {location.name}
                  {location.state_rank !== null &&
                    ` (#${numberToStringWithCommas(location.state_rank)})`}
                </div>
                <div>{location.address}</div>
                <div>
                  {location.city}, {location.county.state} {location.zipcode}
                </div>
                <div>{location.county.name} County</div>
              </div>
            </div>
          </div>

          <div className="flex w-60 flex-col gap-2">
            <div className="flex gap-4">
              <TimeField
                label="Shift Start"
                isRequired
                value={shiftTimes[0] && (parseTime(shiftTimes[0]) as any)}
                onChange={(t) =>
                  setShiftTimes(([, endTime]) => [
                    t ? (t.toString() as TimeString) : null,
                    endTime,
                  ])
                }
              />
              <TimeField
                label="Shift End"
                isRequired
                value={shiftTimes[1] && (parseTime(shiftTimes[1]) as any)}
                onChange={(t) =>
                  setShiftTimes(([startTime]) => [
                    startTime,
                    t ? (t.toString() as TimeString) : null,
                  ])
                }
              />
            </div>

            <div className="flex gap-2">
              <TextRadioGroup
                aria-label="Set shift times"
                value={
                  shiftTimes[0] &&
                  shiftTimes[1] &&
                  (isEqual(shiftTimes, shiftTimesForType(hours, 'am'))
                    ? 'am'
                    : isEqual(shiftTimes, shiftTimesForType(hours, 'pm'))
                    ? 'pm'
                    : isEqual(shiftTimes, shiftTimesForType(hours, 'day'))
                    ? 'day'
                    : null)
                }
                options={
                  hours.shift_change_time
                    ? {
                        am: 'morning',
                        pm: 'afternoon',
                        day: 'all-day',
                      }
                    : { day: 'set to default' }
                }
                onChange={(newShiftType) =>
                  setShiftTimes(shiftTimesForType(hours, newShiftType))
                }
              />
            </div>

            {shiftTimes[0] &&
              shiftTimes[1] &&
              // ISO time strings can be compared alphanumerically
              shiftTimes[0] >= shiftTimes[1] && (
                <div className=" text-error">
                  Shift start time must be before shift end time.
                </div>
              )}
          </div>
        </div>

        <div>
          <strong>Voting Hours:</strong> {extractTextHours(location, !isEDay)}
        </div>

        {/* min-h-0 needed for passing container height down so we can do overflow scrolling  */}
        <div className="flex min-h-0 flex-1 flex-col gap-2">
          <div className="flex items-center justify-between">
            <TextField
              placeholder="Filter names…"
              icon="search"
              iconPosition="start"
              value={userFilter}
              inputClassName="w-80"
              onChange={(val) => setUserFilter(val)}
              aria-label="Filter User List"
            />

            <div className="flex items-center gap-2">
              <TextRadioGroup
                label="Sort:"
                value={userSort}
                onChange={setUserSort}
                options={{
                  recommended: 'recommended',
                  distance: 'distance',
                  greedy: 'best match',
                }}
              />

              <Popover
                type="dialog"
                placement="bottom"
                trigger={(props, ref) => (
                  <IconButton icon="help" buttonRef={ref} {...props} />
                )}
              >
                <div className="flex w-80 flex-col gap-4 rounded bg-white p-4">
                  <div>
                    <strong>Recommended:</strong> Takes distance, experience,
                    legal community membership, and the priority of the
                    volunteer’s current assignments into account.
                  </div>

                  <div>
                    <strong>Distance:</strong> “As the crow flies” estimation
                    between the volunteer and the polling location. Does not
                    account for travel directions or, for that matter, the
                    curvature or oblateness of the Earth.
                  </div>

                  <div>
                    <strong>Best Match:</strong> Same as{' '}
                    <strong>Recommended</strong>, but is irrespective of current
                    assignments.
                  </div>
                </div>
              </Popover>
            </div>
          </div>

          <ListBox
            aria-label="User list"
            items={userListItems}
            selectedKeys={currentUserId === null ? [] : [currentUserId]}
            onSelectionChange={(keys) => {
              // Need to cast away strings, which we don’t use.
              setCurrentUserId(([...keys][0] ?? null) as number | null);
            }}
            selectionBehavior="toggle"
            selectionMode="single"
            // Disable users who are already assigned to this location on this day.
            disabledKeys={userListItems
              .filter((u) =>
                u.currentAssignments.find(
                  (a) =>
                    a.type === 'poll' &&
                    a.locationId === location.id &&
                    a !== assignment
                )
              )
              .map(({ id }) => id)}
          >
            {({
              id,
              first_name,
              last_name,
              city,
              state,
              tags,
              distanceMi,
              max_distance_miles,
              currentAssignments,
              currentAssignmentLocations,
              availability,
            }) => {
              const {
                isExperienced,
                isLegalCommunity,
                hasCarAccess,
                languageTags,
              } = getVolunteerAttributes({ tags });
              const languageNames =
                volunteerLanguagesToLanguageNames(languageTags);

              return (
                <Item textValue={`${first_name} ${last_name}`}>
                  <div className="flex justify-between gap-2 text-base">
                    <div className="w-[34%]">
                      <span className="font-bold">
                        {first_name} {last_name}
                      </span>
                      <br />
                      {city}, {state}
                    </div>

                    <div className="flex-1">
                      {id !== assignment?.userId &&
                        currentAssignments.length > 0 && (
                          <div>
                            {currentAssignments.length === 1 &&
                            currentAssignments[0]!.source === 'auto' ? (
                              <>
                                <span className="material-icons relative top-[1px] text-sm">
                                  smart_toy
                                </span>{' '}
                                <strong>Suggested Assignment To:</strong>
                              </>
                            ) : (
                              <strong>Assigned To:</strong>
                            )}
                            <br />
                            {currentAssignmentLocations
                              .map(
                                (loc) =>
                                  loc &&
                                  `${loc.name} ${
                                    loc.state_rank !== null
                                      ? ` (#${numberToStringWithCommas(
                                          loc.state_rank
                                        )})`
                                      : ''
                                  }`
                              )
                              .join(', ')}
                          </div>
                        )}

                      {currentAssignments.find((a) => a.type !== 'poll') && (
                        <div>Hotline or other role scheduled</div>
                      )}

                      {id === assignment?.userId && (
                        <span>(current assignee)</span>
                      )}
                    </div>

                    <div className="self-center text-right leading-none">
                      <span className="material-icons cursor-default align-bottom text-base leading-none">
                        {iconForDistanceMi(distanceMi)}
                      </span>{' '}
                      <span className="text-sm italic">
                        {describeDistanceMi(distanceMi, max_distance_miles)}
                      </span>
                    </div>

                    <div className="w-8 self-center whitespace-nowrap">
                      {isExperienced && (
                        <span
                          className="material-icons cursor-default align-bottom text-base leading-none"
                          title="Experienced Observer"
                        >
                          military_tech
                        </span>
                      )}

                      {isLegalCommunity && (
                        <span
                          className="material-icons cursor-default align-bottom text-base leading-none"
                          title="Legal Community"
                        >
                          gavel
                        </span>
                      )}

                      {hasCarAccess && (
                        <span
                          className="material-icons cursor-default align-bottom text-base leading-none"
                          title="Has Car Access"
                        >
                          garage
                        </span>
                      )}

                      {languageTags.length > 0 && (
                        <>
                          <span
                            className="material-icons cursor-default align-bottom text-base leading-none"
                            title={`Speaks ${languageNames.join(', ')}`}
                          >
                            chat
                          </span>
                          <span
                            className="align-bottom leading-none"
                            title={`Speaks ${languageNames.join(', ')}`}
                          >
                            {languageTags.length}
                          </span>
                        </>
                      )}
                    </div>

                    <div className="w-20 self-center text-right">
                      {(() => {
                        let icon: string | null;
                        let isWithinAvailability: boolean;

                        switch (availability) {
                          case 'am':
                            icon = 'sunny';
                            isWithinAvailability =
                              !hours.shift_change_time ||
                              (!!shiftTimes[1] &&
                                shiftTimes[1] <= hours.shift_change_time);

                            break;

                          case 'pm':
                            icon = 'bedtime';
                            isWithinAvailability =
                              !hours.shift_change_time ||
                              (!!shiftTimes[0] &&
                                shiftTimes[0] >= hours.shift_change_time);
                            break;

                          case 'either':
                            icon = 'schedule';
                            isWithinAvailability =
                              !hours.shift_change_time ||
                              (!!shiftTimes[0] &&
                                shiftTimes[0] >= hours.shift_change_time) ||
                              (!!shiftTimes[1] &&
                                shiftTimes[1] <= hours.shift_change_time);
                            break;

                          case 'all day':
                            icon = 'sunnybedtime';
                            isWithinAvailability = true;
                            break;

                          case null:
                            icon = 'history_toggle_off';
                            isWithinAvailability = false;
                            break;

                          default:
                            icon = null;
                            isWithinAvailability = true;
                        }
                        return (
                          <span
                            className={cx(
                              'leading-none ',
                              // We gray out the icon if the user isn’t
                              // available during the shift’s time, but
                              // highlight it in error red if you choose the
                              // user anyway.
                              {
                                'material-icons text-lg': !!icon,
                                'text-sm': !icon,
                                'text-gray-700': isWithinAvailability,
                                'text-gray-300':
                                  !isWithinAvailability && id !== currentUserId,
                                'text-red-700':
                                  !isWithinAvailability && id === currentUserId,
                              }
                            )}
                            title={
                              availability
                                ? `Availability: ${availabilityToString(
                                    availability
                                  )}`
                                : undefined
                            }
                          >
                            {icon ??
                              (availability &&
                                availabilityToString(availability, {
                                  tight: true,
                                }))}
                          </span>
                        );
                      })()}
                    </div>
                  </div>
                </Item>
              );
            }}
          </ListBox>
        </div>

        {currentUserForLocation &&
          currentUserForLocation.currentAssignments.length > 0 &&
          currentUserId !== assignment?.userId && (
            <>
              <div
                className={cx(
                  'flex gap-2 rounded bg-yellow-300 p-2 text-base leading-tight'
                )}
              >
                {currentUserForLocation.currentAssignments.find(
                  (a) => a.type !== 'poll'
                ) ? (
                  <>
                    <span className="material-icons font-xl text-gray-700">
                      warning
                    </span>
                    <div className="self-center">
                      <strong>
                        {currentUserForLocation.user.first_name}{' '}
                        {currentUserForLocation.user.last_name}
                      </strong>{' '}
                      has an existing assignment to a hotline or other
                      non–polling location.
                    </div>
                  </>
                ) : (
                  <>
                    <Checkbox
                      isSelected={replaceExistingAssignments}
                      onChange={setReplaceExistingAssignments}
                      isDisabled={
                        !!currentUserForLocation.currentAssignments.find(
                          (a) => a.type !== 'poll'
                        )
                      }
                    >
                      <span>
                        Replace{' '}
                        <strong>
                          {currentUserForLocation.user.first_name}{' '}
                          {currentUserForLocation.user.last_name}
                        </strong>
                        ’s{' '}
                        {currentUserForLocation.currentAssignments.length ===
                        1 ? (
                          <>
                            existing assignment to{' '}
                            <strong>
                              {
                                locationsById.get(
                                  // We can do this cast because we know that
                                  // we’re only in this branch if all of the
                                  // current assignments are of type "poll"
                                  (
                                    currentUserForLocation.currentAssignments as Array<
                                      AssignmentRecord & { type: 'poll' }
                                    >
                                  )[0]!.locationId
                                )!.name
                              }
                            </strong>
                          </>
                        ) : (
                          <>
                            {currentUserForLocation.currentAssignments.length}{' '}
                            existing assignments
                          </>
                        )}
                      </span>
                    </Checkbox>
                  </>
                )}
              </div>
            </>
          )}

        {errorMessage && <div className="text-error">{errorMessage}</div>}

        <div className="flex justify-start gap-2 pl-2 text-sm">
          {assignment && (
            <TextButton
              size="unset"
              isDisabled={isSaving}
              onPress={async () => {
                try {
                  setErrorMessage(null);
                  setSaving(true);
                  await deleteAssignment(assignment);
                  doClose();
                } catch (e) {
                  setErrorMessage('Unable to delete the assignment');
                  setSaving(false);
                }
              }}
            >
              {assignment?.source === 'auto'
                ? 'Reject suggestion'
                : 'Delete assignment'}
            </TextButton>
          )}

          {/* Spacer */}
          <div className="flex-1" />

          {currentUserForLocation && (
            <a
              href={makeGoogleDirectionsUrl(
                currentUserForLocation.user,
                location
              )}
              target="_blank"
              rel="noreferrer"
              className="mr-2 self-center text-sm leading-none text-hyperlink"
            >
              <span className="material-icons relative top-[1px] align-bottom text-base leading-none">
                open_in_new
              </span>{' '}
              Open Google travel directions for{' '}
              {currentUserForLocation.user.first_name}{' '}
              {currentUserForLocation.user.last_name}
            </a>
          )}

          <ActionButton role="secondary" onPress={() => doClose()}>
            Cancel
          </ActionButton>

          <ActionButton
            role="primary"
            isDisabled={!isValid}
            onPress={async () => {
              try {
                setErrorMessage(null);
                await saveAssignment(
                  {
                    source: 'manual',
                    type: 'poll',
                    date: hours.date,
                    locationId: location.id,
                    // we know these all are set because otherwise this button is disabled
                    startTime: shiftTimes[0]!,
                    endTime: shiftTimes[1]!,
                    userId: currentUserId!,
                  },
                  assignment,
                  replaceExistingAssignments
                );
                doClose();
              } catch (e) {
                console.error(e);
                setErrorMessage('Unable to save that assignment');
                setSaving(false);
              }
            }}
          >
            {/* If you don’t edit anything for an auto-created assignment, the “Save” is “Accept” */}
            {assignment?.source === 'auto' &&
            shiftTimes[0] === assignment.startTime &&
            shiftTimes[1] === assignment.endTime &&
            currentUserId === assignment.userId
              ? 'Accept'
              : 'Save'}
          </ActionButton>
        </div>
      </div>
    </ModalDialog>
  );
};

export default AssignmentModal;
