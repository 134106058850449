import keyMirror from 'key-mirror';
import React from 'react';

import Autocomplete from '../../components/presentational/form/autocomplete';
import DatetimeInput from '../../components/presentational/form/datetime';
import DebouncedInput from '../../components/presentational/form/debounced-input';
import ToggleInput from '../../components/presentational/form/toggle';
import UserAutocomplete, {
  SelectableUser,
} from '../../components/presentational/form/user-autocomplete';

import {
  issueCategories,
  issueClasses,
  issuePriorities,
  issueQaChoices,
  IssueScope,
  issueScopes,
  issueSources,
  issueStatuses,
  State,
  states,
  US_NATIONAL_STATE_CODE,
  voteStatuses,
} from '../../constants';
import { LbjInputEvent } from '../../decorators/input-props';
import { CountySlug } from '../../services/common';

import ChoiceFilter from './issue-choice-filter';
import {
  BOILER_ROOOM_LEVEL_LABELS,
  IssuesListFilters,
  IssuesListFilterUpdater,
  IssuesListLbjData,
} from './issues-list-utils';

type FilterProps = {
  filters: IssuesListFilters;
  updateFilters: IssuesListFilterUpdater;
};

export const SearchTermFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <DebouncedInput
    wait={600}
    name="search_term"
    title="Search"
    placeholder="Add search term here"
    defaultValue={filters.search_term}
    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
      updateFilters({ search_term: e.target.value })
    }
  />
);

export const VoterSearchFilters: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <>
    <DebouncedInput
      wait={600}
      name="voter_name"
      title="Voter Name"
      placeholder="Start typing…"
      defaultValue={filters.voter_name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        updateFilters({ voter_name: e.target.value })
      }
    />

    <DebouncedInput
      wait={600}
      name="voter_phone_number"
      title="Voter Phone Number"
      placeholder="Start typing…"
      defaultValue={filters.voter_phone_number}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        updateFilters({ voter_phone_number: e.target.value })
      }
    />
  </>
);

export const ElectionFilter: React.FunctionComponent<
  FilterProps & Pick<IssuesListLbjData, 'elections'>
> = ({ filters, updateFilters, elections }) => (
  <ChoiceFilter
    name="query_election_id"
    title="Election"
    value={filters.query_election_id?.toString()}
    onChange={({ target: { value } }) =>
      updateFilters({
        query_election_id: value ? Number(value) : undefined,
      })
    }
    choices={makeChoices(elections, 'id', 'name')}
  />
);

export const BoilerRoomFilter: React.FunctionComponent<
  FilterProps & Pick<IssuesListLbjData, 'boilerRooms'>
> = ({ filters, updateFilters, boilerRooms }) => (
  <ChoiceFilter
    name="boiler_room"
    title="Boiler room"
    isDisabled={!boilerRooms.length}
    onChange={({ target: { value } }) =>
      updateFilters({
        boiler_room: value ? value.split(',').map((n) => Number(n)) : undefined,
      })
    }
    choices={makeChoices(boilerRooms, 'id', 'name')}
    value={filters.boiler_room.join(',')}
    multi
  />
);

export const BoilerRoomLevelFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ChoiceFilter
    name="boiler_room__level"
    title="Boiler Room Level"
    value={filters.boiler_room__level}
    onChange={({ target: { value } }) =>
      updateFilters({
        boiler_room__level: value ? value : undefined,
      })
    }
    choices={BOILER_ROOOM_LEVEL_LABELS}
  />
);

export const StateRegionCountyFilters: React.FunctionComponent<
  FilterProps &
    Pick<IssuesListLbjData, 'counties' | 'regions'> & {
      currentElectionState: State;
    }
> = ({ filters, updateFilters, currentElectionState, counties, regions }) => (
  <>
    <ChoiceFilter
      name="state"
      title="State"
      choices={getStateChoices(currentElectionState)}
      value={filters.state}
      onChange={({ target: { value } }) =>
        updateFilters({ state: value === '' ? undefined : value })
      }
    />

    <ChoiceFilter
      name="region"
      title="Region"
      onChange={({ target: { value } }) =>
        updateFilters({
          region: value ? value.split(',').map(Number) : undefined,
        })
      }
      choices={makeChoices(regions ?? [], 'id', 'name')}
      value={filters.region.join(',')}
      isDisabled={!regions?.length}
      multi
    />

    <Autocomplete
      name="county"
      title="County"
      disabled={!counties?.length}
      onChange={({ target: { value } }) => {
        updateFilters({
          county: value ? (value?.split(',') as CountySlug[]) : undefined,
        });
      }}
      choices={
        makeChoices(counties ?? [], 'slug', 'name') as {
          [slug: CountySlug]: string;
        }
      }
      value={filters.county.join(',') ?? ''}
      multi
      type="filter"
    />
  </>
);

export const PrecinctLocationFilters: React.FunctionComponent<
  FilterProps & Pick<IssuesListLbjData, 'locations' | 'precincts'>
> = ({ filters, updateFilters, locations, precincts }) => (
  <>
    <Autocomplete
      name="precinct"
      title="Precinct"
      placeholder="-"
      disabled={!precincts?.length}
      onChange={({ target: { value } }) =>
        updateFilters({ precinct: value ? Number(value) : undefined })
      }
      choices={makeChoices(precincts ?? [], 'id', 'name')}
      value={filters.precinct?.toString() ?? ''}
    />

    <Autocomplete
      name="location"
      title="Location"
      placeholder="-"
      disabled={!locations?.length}
      onChange={({ target: { value } }) =>
        updateFilters({
          location: value ? value.split(',').map(Number) : undefined,
        })
      }
      choices={makeChoices(locations ?? [], 'id', 'name')}
      value={filters.location?.join(',') ?? ''}
      multi
    />
  </>
);

export const DistrictFilters: React.FunctionComponent<
  FilterProps & Pick<IssuesListLbjData, 'districts'>
> = ({ filters, updateFilters, districts }) => (
  <>
    <Autocomplete
      name="us_house"
      title="U.S. House"
      placeholder="-"
      disabled={!districts?.us_house?.length}
      onChange={({ target: { value } }) =>
        updateFilters({
          us_house: value ? value.split(',').map(Number) : undefined,
        })
      }
      choices={makeChoices(districts?.us_house ?? [], 'id', 'name')}
      value={filters.us_house.join(',')}
      multi
    />

    <Autocomplete
      name="state_house"
      title="State House"
      placeholder="-"
      disabled={!districts?.state_house?.length}
      onChange={({ target: { value } }) =>
        updateFilters({
          state_house: value ? value.split(',').map(Number) : undefined,
        })
      }
      choices={makeChoices(districts?.state_house ?? [], 'id', 'name')}
      value={filters.state_house.join(',')}
      multi
    />

    <Autocomplete
      name="state_senate"
      title="State Senate"
      placeholder="-"
      disabled={!districts?.state_senate?.length}
      onChange={({ target: { value } }) =>
        updateFilters({
          state_senate: value ? value.split(',').map(Number) : undefined,
        })
      }
      choices={makeChoices(districts?.state_senate ?? [], 'id', 'name')}
      value={filters.state_senate.join(',')}
      multi
    />
  </>
);

export const StartEndDateFilters: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <>
    <DatetimeInput
      name="start_date"
      title="Start Date"
      type="date"
      value={filters.start_date ?? '-'}
      dateFormat="MMMM Do, YYYY"
      onChange={({ target: { value } }) => {
        updateFilters({
          start_date: value === '' ? undefined : new Date(value),
        });
      }}
    />

    <DatetimeInput
      name="end_date"
      title="End Date"
      type="date"
      value={filters.end_date ?? '-'}
      dateFormat="MMMM Do, YYYY"
      onChange={({ target: { value } }) => {
        updateFilters({
          end_date: value === '' ? undefined : new Date(value),
        });
      }}
    />
  </>
);

export const StatusFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ChoiceFilter
    name="status"
    title="Status"
    value={filters.status}
    onChange={({ target: { value } }) => {
      updateFilters({ status: value ? value : undefined });
    }}
    choices={issueStatuses}
  />
);

export const SourceFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ChoiceFilter
    name="source"
    title="Source"
    value={filters.source}
    onChange={({ target: { value } }) => {
      updateFilters({ source: value === '' ? undefined : value });
    }}
    choices={issueSources}
  />
);

export const IssueClassFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ChoiceFilter
    name="type__in"
    title="Class"
    value={filters.type__in}
    onChange={({ target: { value } }) => {
      updateFilters({ type__in: value === '' ? undefined : value });
    }}
    choices={issueClasses}
  />
);

export const VoteStatusScopesFilters: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <>
    <ChoiceFilter
      name="vote_status"
      title="Vote Status"
      value={filters.vote_status}
      onChange={({ target: { value } }) => {
        updateFilters({ vote_status: value === '' ? undefined : value });
      }}
      choices={voteStatuses}
    />
    <ChoiceFilter
      name="scope"
      title="Scope"
      value={filters.scope.join(',')}
      onChange={({ target: { value } }) =>
        updateFilters({
          scope: value ? value.split(',').filter(isScope) : undefined,
        })
      }
      choices={issueScopes}
      multi
    />
  </>
);

export const PriorityFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ChoiceFilter
    name="priority"
    title="Priority"
    value={filters.priority}
    onChange={({ target: { value } }) => {
      updateFilters({ priority: value === '' ? undefined : value });
    }}
    choices={issuePriorities}
  />
);

export const QaReviewedFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ChoiceFilter
    name="qa_reviewed"
    title="QA Reviewed"
    value={filters.qa_reviewed?.toString()}
    onChange={({ target: { value } }) => {
      updateFilters({
        qa_reviewed: value === '' ? undefined : value === 'true',
      });
    }}
    choices={issueQaChoices}
  />
);

export const UnclaimedIssuesFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ToggleInput
    name="unclaimed"
    title="Show only unclaimed issues"
    checked={!!filters.owner__isnull}
    onChange={({ target: { value } }: LbjInputEvent<boolean>) => {
      updateFilters({
        // We don’t want to be explicitly false, just indifferent.
        owner__isnull: value || undefined,
      });
    }}
  />
);

export const CategoryFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ChoiceFilter
    name="category"
    title="Issue Category"
    value={filters.category.join(',')}
    onChange={({ target: { value } }) => {
      updateFilters({
        category: value ? value.split(',') : undefined,
      });
    }}
    choices={{
      ...keyMirror(issueCategories.inquiry),
      ...keyMirror(issueCategories.incident),
    }}
    multi
  />
);

export const FromPermanentFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ToggleInput
    name="from_permanent"
    title="Show only tickets from a permanent hotline"
    checked={!!filters.from_permanent}
    onChange={({ target: { value } }: LbjInputEvent<boolean>) => {
      updateFilters({
        // We don’t want to be explicitly false, just indifferent.
        from_permanent: value || undefined,
      });
    }}
  />
);

export const RequiresFollowupFilter: React.FunctionComponent<FilterProps> = ({
  filters,
  updateFilters,
}) => (
  <ToggleInput
    name="requires_followup"
    title="Show only tickets that require follow up"
    checked={!!filters.requires_followup}
    onChange={({ target: { value } }: LbjInputEvent<boolean>) => {
      updateFilters({
        // We don’t want to be explicitly false, just indifferent.
        requires_followup: value || undefined,
      });
    }}
  />
);

export const AssigneeFilter: React.FunctionComponent<
  FilterProps & {
    preloadedUsers: SelectableUser[];
  }
> = ({ filters, updateFilters, preloadedUsers }) => (
  <UserAutocomplete
    name="owner"
    title="Assignee"
    preloadedUsers={preloadedUsers}
    userId={filters.owner ?? null}
    setUserId={(newId) =>
      updateFilters({ owner: newId === null ? undefined : newId })
    }
    // Disable this box if we’re already filtering on “no owner”
    disabled={filters.owner__isnull === true}
  />
);

export const ReporterFilter: React.FunctionComponent<
  FilterProps & {
    preloadedUsers: SelectableUser[];
  }
> = ({ filters, updateFilters, preloadedUsers }) => (
  <UserAutocomplete
    name="user"
    title="Observer"
    preloadedUsers={preloadedUsers}
    userId={filters.user ?? null}
    setUserId={(newId) =>
      updateFilters({ user: newId === null ? undefined : newId })
    }
  />
);

/**
 * Returns true if the string is a valid {@link IssueScope}.
 */
function isScope(x: string): x is IssueScope {
  return x in issueScopes;
}

/**
 * Returns a choices object from a list of objects that have an id and name
 * field.
 *
 * @param id The key of T that contains the value identifier for the objects.
 * @param name The key of T that contains the label for the choice.
 */
function makeChoices<
  Id extends string,
  Name extends string,
  T extends { [k in Id | Name]: unknown }
>(objs: T[], id: Id, name: Name) {
  return objs.reduce<{ [id in string]: string }>(
    (out, obj) => ({
      ...out,
      [String(obj[id]) as Id]: String(obj[name]),
    }),
    {}
  );
}

/**
 * Returns the choices we want to allow for picking a state. If the currently
 * chosen election is national, allow all states (minus "US"). Otherwise limit
 * just to the election’s state.
 *
 * This is derived from {@link StateFilter} to remove the connectedness.
 */
function getStateChoices(currentElectionState: State) {
  const stateChoices: { [key in State]?: string } = {};

  if (currentElectionState !== US_NATIONAL_STATE_CODE) {
    stateChoices[currentElectionState] = states[currentElectionState];
  } else {
    Object.assign(stateChoices, states);
    delete stateChoices[US_NATIONAL_STATE_CODE];
  }

  return stateChoices;
}
