import { isEqual } from 'lodash';
import React, { SetStateAction, useEffect, useState } from 'react';

import { DEFAULT_POLLING_INTERVAL } from '../modules/notifications/reducers';
import * as ravenService from '../services/sentry-service';

/**
 * Sets up a function to be called pollingly. This is a hook version of the
 * polling logic built into `App` and `IssueIndex`.
 *
 * If the callback throws an error, polling will back off. If the callback
 * returns a number, it will be use as the new polling interval. If the callback
 * resolves but doesn’t return a number, the interval will be reset to the
 * default.
 *
 * Pass `false` as the `active` option to disable polling, regardless of the
 * current interval.
 */
export function usePolling(
  func: () => number | void | Promise<number | void>,
  {
    active = true,
    defaultIntervalMs = DEFAULT_POLLING_INTERVAL,
  }: {
    active?: boolean;
    defaultIntervalMs?: number;
  } = {}
) {
  // Keep these from being explicit dependencies in our useEffect.
  const defaultIntervalMsRef = React.useRef(defaultIntervalMs);
  const funcRef = React.useRef(func);

  React.useLayoutEffect(() => {
    defaultIntervalMsRef.current = defaultIntervalMs;
    funcRef.current = func;
  });

  const [pollingInterval, setPollingInterval] =
    React.useState(defaultIntervalMs);

  React.useEffect(() => {
    if (!active) {
      return;
    }

    const intervalNum = setInterval(async () => {
      try {
        const newPollingInterval = await funcRef.current();

        if (typeof newPollingInterval === 'number') {
          setPollingInterval(newPollingInterval);
        } else {
          setPollingInterval(defaultIntervalMsRef.current);
        }
      } catch (e: any) {
        // exponential backoff
        setPollingInterval((cur) => cur * 2);

        ravenService.captureException(e, {
          method: 'usePolling',
        });
      }
    }, pollingInterval);

    return () => {
      clearInterval(intervalNum);
    };
  }, [pollingInterval, active]);
}

/**
 * Hook that provides `useReducer`-like behavior but synchronously resets to a
 * default state when the deps change.
 *
 * When deps change the value of the `dispatch` function will also change, and
 * previous functions will be ignored.
 */
export function useReducerWithDeps<R extends React.Reducer<any, any>>(
  reducer: R,
  initialState: React.ReducerState<R>,
  deps: any[]
): [React.ReducerState<R>, React.Dispatch<React.ReducerAction<R>>] {
  // Part of the implementation complexity of this hook is that we want to
  // maintain the useMemo-like behavior where a change in deps _synchronously_
  // resets the hook: the passed-in initialState is returned immediately, not on
  // a second render.

  // This initial value for deps will be unique (since we use shallow equality)
  // to guarantee that depsChangedThisRender will be true on the first render.
  const prevDepsRef = React.useRef<any[]>([{}]);
  const depsChangedThisRender = haveDepsChanged(prevDepsRef.current, deps);

  // To match `useReducer` we keep a ref of the most recent reducer passed in,
  // and always use that when calling `dispatch`.
  const reducerRef = React.useRef(reducer);

  React.useLayoutEffect(() => {
    prevDepsRef.current = deps;
    reducerRef.current = reducer;
  });

  // affordance to cause a re-render
  const [, setRerenderObj] = React.useState({});

  // We close over this state, which our dispatch function will update. If it’s
  // the “current” state object we’ll return it as well from the hook.
  //
  // This keeps old dispatch functions from modifying the current state, since
  // they would have closed over / are modifying an object that will be ignored.
  const stateObj = { state: initialState };

  // (Note: we create a dispatch function every render, but only save the ones
  // from renders where the deps change.)
  const dispatch: React.Dispatch<React.ReducerAction<R>> = (action) => {
    const newState = reducerRef.current(stateObj.state, action);

    if (newState !== stateObj.state) {
      stateObj.state = newState;
      setRerenderObj({});
    }
  };

  const currentStateObjRef = React.useRef(stateObj);
  const currentDispatchRef = React.useRef(dispatch);

  React.useLayoutEffect(() => {
    if (depsChangedThisRender) {
      currentStateObjRef.current = stateObj;
      currentDispatchRef.current = dispatch;
    }
  });

  if (depsChangedThisRender) {
    // This render is synchronous with the deps changing, so our refs haven’t
    // been updated by the useLayoutEffect block yet, so we return the local
    // variables.
    return [stateObj.state, dispatch];
  } else {
    return [currentStateObjRef.current.state, currentDispatchRef.current];
  }
}

/**
 * Similar to React’s `useState` but takes an array of deps that will cause the
 * state to synchronously revert to its initialState if they change.
 */
export function useStateWithDeps<S>(initialState: S, deps: any[]) {
  return useReducerWithDeps(
    (prevState: S, action: SetStateAction<S>) => {
      if (typeof action === 'function') {
        // We assume that we’re passed a function that it’s a state updater.
        // This is not exactly true per TypeScript since S could include other
        // functions, but it matches React’s behavior.
        return (action as any)(prevState) as S;
      } else {
        return action;
      }
    },
    initialState,
    deps
  );
}

/**
 * Provides a deep-equals continuity of values.
 *
 * If the object passed to this hook stays deep-equal to a previous version,
 * this hook will return the previous version.
 *
 * Useful for smoothing over non-existant differences between object values that
 * are used as deps to other hooks.
 */
export function useContinuity<S>(val: S, comp = isEqual) {
  const lastValRef = React.useRef(val);

  const isSameAsLast = comp(lastValRef.current, val);

  React.useLayoutEffect(() => {
    if (!isSameAsLast) {
      lastValRef.current = val;
    }
  });

  return isSameAsLast ? lastValRef.current : val;
}

/**
 * `useEffect`-like hook that passes the previous deps values to its callback.
 *
 * Note: Unlike `useEffect`, this is _not_ called when the component mounts.
 * It’s only called on changes.
 */
export function useChanges<Deps extends Readonly<unknown[]>>(
  cb: (...prev: Deps) => void | (() => void),
  // Need a second Readonly since TS 4.9 or else we get errors at call sites
  // that we’re assigning to a mutable parameter.
  deps: Readonly<Deps>
) {
  const prevDepsRef = React.useRef<Deps | null>(null);

  useEffect(
    () => {
      const prevDeps = prevDepsRef.current;
      // as any to deal with readonly shenanigans
      prevDepsRef.current = [...deps] as any;

      if (prevDeps) {
        return cb(...prevDeps);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
}

/**
 * Hook to await the {@link IteratorResult}s of an {@link AsyncGenerator} and
 * return them synchronously. Will return `undefined` until the first async
 * response comes in.
 *
 * If the async generator throws an exception, this hook will re-throw it.
 *
 * This hook is useful for tying long-running, multi-step async jobs to
 * component rendering. Because of how it’s tied in to the component lifecycle,
 * if its parent component is no longer rendered, it will stop requesting new
 * values from the generator.
 *
 * Note that this is built for cases where the function just `yield`s values and
 * does not expect any to be passed back in.
 */
export function useAsyncGenerator<T, TReturn>(
  gen: AsyncGenerator<T, TReturn, void> | null
): IteratorResult<T, TReturn> | undefined {
  const [result, setResult] = useStateWithDeps<
    IteratorResult<T, TReturn> | undefined
  >(undefined, [gen]);

  // We have to store the error in our own state if we want to throw it during
  // render since it would have been caught in an async function.
  const [error, setError] = useStateWithDeps<any>(undefined, [gen]);

  // This check is here purely to support react-refresh, which will re-run all
  // `useEffect`s regardless of their dependencies. Without it, re-running
  // `useEffect` could call `next()` on a generator that has already returned.
  // `next()` would return a promise that resolves to a value of `undefined` in
  // that case, which would then get set as the `result` of the generator,
  // overwriting the previous return value.
  //
  // Including this check means that `result` will stay stable once a given
  // generator has completed, regardless of react-refresh re-running
  // `useEffect`.
  //
  // Since `result` is in a `useStateWithDeps` that depends on `gen`, we know
  // that if there’s a new `gen` passed in that `result` will synchronously be
  // `undefined` and `alreadyDone` will evaluate to false.
  //
  // Note that the overall behavior of this hook is somewhat undefined if
  // react-refresh triggers in the middle of an evaluation. It is likely that a
  // yielded `result` will be thrown away.
  const alreadyDone = !!result?.done;

  React.useEffect(() => {
    if (!gen || alreadyDone) {
      return;
    }

    let stopped = false;

    (async () => {
      for (;;) {
        try {
          const result = await gen.next();

          // We check to make sure we haven’t been unmounted or re-rendered with
          // a different generator. It’s very important to run this check
          // between getting the async result and calling setResult, since
          // during that time a re-render might have happened that makes this no
          // longer current.
          if (stopped) {
            break;
          }

          setResult(result);

          if (result.done) {
            break;
          }
        } catch (e) {
          setError(e);
        }
      }
    })();

    // Setting stopped in the cleanup function keeps us from storing stale
    // generators’ values in our state, and also halts the iteration.
    return () => {
      stopped = true;
    };
  }, [gen, setResult, setError, alreadyDone]);

  if (error) {
    throw error;
  }

  return result;
}

/**
 * Makes an {@link Iterable} from a callback function that generates an iterable
 * (such as one that returns an array).
 *
 * Useful for passing to react-aria’s `items` prop in cases where the list may
 * or may not be currently visible. This delays calculating the array until it
 * is actually needed.
 */
export function useLazyIterable<T>(
  fn: () => Iterable<T>,
  deps: any[]
): Iterable<T> {
  return React.useMemo(
    () => {
      let arr: Iterable<T>;

      return {
        [Symbol.iterator]: () => {
          if (!arr) {
            arr = fn();
          }

          return arr[Symbol.iterator]();
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
}

/**
 * Compares two arrays of hook dependencies, using Object.is to match React.
 *
 * @return true if the deps have changed
 */
function haveDepsChanged(oldDeps: any[], newDeps: any[]) {
  if (oldDeps.length !== newDeps.length) {
    return true;
  } else {
    for (let i = 0; i < newDeps.length; ++i) {
      if (!Object.is(oldDeps[i], newDeps[i])) {
        return true;
      }
    }

    return false;
  }
}
/**
 * Hook used to evaluate the screen size of a user's device
 * and returns True if the device is below a certain screen size.
 * Input: width, minimum value to determine mobile vs. desktop screen size
 */
export const useIsMobile = (width: number) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= width);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= width);
    }

    // Add event listener to window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  return isMobile;
};
