import { Immutable } from 'immer';
import React from 'react';

import { ActionButton } from '../../../components/form';
import {
  ApiVolunteerAvailabilityGroup,
  NewApiVolunteerAvailability,
} from '../../../services/volunteer-availability-service';
import * as Sets from '../../../utils/sets';
import { assertUnreachable, Awaitable } from '../../../utils/types';

import { AssignmentUser } from '../assignment-state';

import type { VolunteerAvailabilityCsvFormat } from './VolunteerAvailabilityDialog';
import {
  matchVanSignupRowsToUsers,
  mergeVanSignupTimes,
  ParsedVanSignupRow,
  parseVanSignupRow,
  uniqueVanUsersFromSignups,
  vanSignupToAvailability,
  VAN_SIGNUP_ROW_FIELDS,
} from './van-signup-format';

export type UploadAvailabilityFn = (
  availabilityGroup: ApiVolunteerAvailabilityGroup,
  availability: NewApiVolunteerAvailability[]
) => Awaitable<void>;

/**
 * Component for showing the parsed results from the availability CSV upload.
 */
const VolunteerAvailabilityDialogResults: React.FunctionComponent<{
  usersById: Immutable<Map<number, AssignmentUser>>;
  availabilityGroup: ApiVolunteerAvailabilityGroup;

  isParsing: boolean;
  csvFormat: VolunteerAvailabilityCsvFormat;
  csvResults: Papa.ParseResult<{ [key: string]: string }>;
  csvFileName: string | null;

  onBack: () => void;
  uploadAvailability: UploadAvailabilityFn;
}> = ({
  csvFormat,
  csvResults,
  usersById,
  availabilityGroup,
  onBack,
  uploadAvailability,
}) => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadError, setUploadError] = React.useState<string | null>(null);

  const csvInterpretation = React.useMemo(
    () => interpretAvailabilityCsv(csvFormat, csvResults, usersById),
    [csvFormat, csvResults, usersById]
  );

  return (
    <div className="flex flex-1 flex-col gap-4 overflow-hidden">
      {csvInterpretation.type === 'no-rows' && (
        <div>No rows found in the CSV</div>
      )}

      {csvInterpretation.type === 'bad-schema' && (
        <div className="flex flex-1 flex-col overflow-hidden">
          There was a problem with the format of your CSV:{' '}
          {csvInterpretation.message}
        </div>
      )}

      {csvInterpretation.type === 'bad-format' && (
        <div className="flex flex-1 flex-col overflow-hidden">
          Errors processing the CSV:
          <div className="overflow-auto">
            {csvInterpretation.problems.map(([rowIdx, message], idx) => (
              <div key={idx}>
                {/* +1 to change to 1-index, +1 for the CSV header */}
                <b>{rowIdx + 2}:</b> {message}
              </div>
            ))}
          </div>
        </div>
      )}

      {csvInterpretation.type === 'success' && (
        <div className="flex flex-1 flex-col gap-4 overflow-hidden">
          <div>
            Matched{' '}
            <strong>{csvInterpretation.rowAndUserId.length} signups</strong> to
            volunteers on this election.
          </div>

          {csvInterpretation.unmatchedRows.length > 0 && (
            <>
              <div>
                <b>Warning!</b> Could not find the following people in this
                election by e-mail or VAN Id:
              </div>

              <div className="flex-1 overflow-auto">
                {uniqueVanUsersFromSignups(csvInterpretation.unmatchedRows).map(
                  (row, idx) => (
                    <div key={idx}>
                      {row.Name} ({row.Email}) – ID: {row.VanID ?? <i>none</i>}
                    </div>
                  )
                )}
              </div>
            </>
          )}

          {uploadError && (
            <div>
              <strong>Error during upload:</strong> {uploadError}
            </div>
          )}
        </div>
      )}

      <div className="flex justify-end gap-2">
        <ActionButton role="secondary" onPress={() => onBack()}>
          Go Back
        </ActionButton>

        <ActionButton
          isDisabled={csvInterpretation.type !== 'success' || isUploading}
          onPress={async () => {
            if (csvInterpretation.type !== 'success') {
              // Make types below happy, but this button should be disabled if this
              // isn’t success.
              return;
            }

            const availability = csvInterpretation.rowAndUserId.map(
              ([row, userId]) =>
                vanSignupToAvailability(availabilityGroup, row, userId)
            );

            try {
              setIsUploading(true);
              setUploadError(null);
              await uploadAvailability(availabilityGroup, availability);
            } catch (e) {
              setUploadError(`${e}`);
            } finally {
              setIsUploading(false);
            }
          }}
        >
          Upload Availability
        </ActionButton>
      </div>
    </div>
  );
};

export default VolunteerAvailabilityDialogResults;

export type AvailabilityCsvInterpretation =
  | {
      type: 'success';
      rowAndUserId: [ParsedVanSignupRow, number][];
      unmatchedRows: ParsedVanSignupRow[];
    }
  | { type: 'no-rows' }
  | { type: 'bad-schema'; message: string }
  | { type: 'bad-format'; problems: [number, string][] };

export function interpretAvailabilityCsv(
  csvFormat: VolunteerAvailabilityCsvFormat,
  results: Papa.ParseResult<{ [key: string]: string }>,
  usersById: Immutable<Map<number, AssignmentUser>>
): AvailabilityCsvInterpretation {
  switch (csvFormat) {
    case 'van-signup': {
      if (results.data.length === 0) {
        return { type: 'no-rows' };
      }

      // Quick check to make sure that the overall CSV has the right columns,
      // since that should be shown as a global error instead of per-row.
      const csvFields = results.meta.fields ?? [];

      const missingSchemaFields = Sets.difference(
        new Set(VAN_SIGNUP_ROW_FIELDS),
        new Set(csvFields)
      );

      if (missingSchemaFields.size > 0) {
        return {
          type: 'bad-schema',
          message: `Missing columns: ${[...missingSchemaFields].join(', ')}`,
        };
      }

      const successRows: ParsedVanSignupRow[] = [];
      const problemRowPairs: [number, string][] = [];

      results.data.map(parseVanSignupRow).forEach((parsedResult, idx) => {
        switch (parsedResult.type) {
          case 'success':
            successRows.push(parsedResult.row);
            break;

          case 'failure':
            problemRowPairs.push([idx, parsedResult.message]);
            break;
        }
      });

      if (problemRowPairs.length > 0) {
        return { type: 'bad-format', problems: problemRowPairs };
      }

      const mergedTimeRows = mergeVanSignupTimes(successRows);

      const { matched, unmatched } = matchVanSignupRowsToUsers(
        mergedTimeRows,
        usersById
      );

      return {
        type: 'success',
        rowAndUserId: matched,
        unmatchedRows: unmatched,
      };
    }

    case 'custom':
      throw new Error('custom CSV currently unsupported');

    default:
      assertUnreachable(csvFormat);
  }
}

export type RowParseResult<T> =
  | { type: 'success'; row: T }
  | { type: 'failure'; message: string };
