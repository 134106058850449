import { produce, Immutable, castImmutable, Draft, castDraft } from 'immer';

import * as AssignmentService from '../../services/assignment-service';
import {
  ExpandApiLocationHours,
  ExpandApiAllPrecinctsIds,
  ApiLocationBulkUpdate,
} from '../../services/assignment-service';
import { DateString, OffsetResponse, TimeString } from '../../services/common';
import {
  ApiLocation,
  ApiPrecinct,
  PollObserverRegistrationRequirement,
} from '../../services/lbj-shared-service';
import { captureException } from '../../services/sentry-service';
import * as UserService from '../../services/user-service';
import { ApiUser, ExpandApiUserPii } from '../../services/user-service';
import type { ApiVolunteerAvailability } from '../../services/volunteer-availability-service';
import * as VolunteerAvailabilityService from '../../services/volunteer-availability-service';
import { assertUnreachable } from '../../utils/types';

import {
  CantAssignReason,
  DistanceMiLookupFunc,
  getShiftScore,
  getVolunteerAttributes,
  makeAvailabilityMatcher,
  makeShiftScoreConfig,
  mergeLocationHours,
  ShiftScoreConfig,
  ShiftScoreOptions,
  VolunteerAttributes,
  VolunteerAvailabilityTime,
} from './assignment-utils';

/**
 * Full-page state for the new assignments UI.
 *
 * Since this is large and internally-synchronized, we update it using the
 * reducer pattern.
 *
 * @see assignmentReducer
 */
export type AssignmentState = Immutable<{
  /** Map of location API objects, keyed by their ID. */
  locationsById: Map<number, AssignmentLocation>;

  /** Map of user API objects, keyed by their ID. */
  usersById: Map<number, AssignmentUser>;

  /** Map of {@link AssignmentRecord}s, keyed by their date. */
  assignmentsByDate: Map<DateString, AssignmentRecord[]>;

  /** Map of userID to list of availability records. */
  availabilityByUserId: Map<number, ApiVolunteerAvailability[]>;

  /**
   * Date of the last load of volunteers, locations, and assignments from the
   * server.
   */
  lastLoadTime: null | Date;
}>;

// We pick relevant attributes from these API responses so that it’s clear
// what’s important and relevant for this page.

export type AssignmentLocation = Immutable<
  Pick<
    ApiLocation & ExpandApiLocationHours & ExpandApiAllPrecinctsIds,
    | 'id'
    | 'name'
    | 'address'
    | 'county'
    | 'hours'
    | 'text_hours'
    | 'coordinates'
    | 'zipcode'
    | 'city'
    | 'state_rank'
    // For the most part we work off of `hours`, but we include this data to
    // distinguish between cases where the async hours process might not have
    // completed, and the location just not enabled for that voting.
    | 'early_vote'
    | 'election_day'
    | 'all_precincts_ids'
  >
>;

export type AssignmentUser = Immutable<
  Pick<
    ApiUser & ExpandApiUserPii,
    | 'id'
    | 'first_name'
    | 'last_name'
    | 'address'
    | 'city'
    | 'state'
    | 'zipcode'
    | 'county_name'
    | 'role'
    | 'coordinates'
    | 'email'
    | 'phone_number'
    | 'tags'
    | 'max_distance_miles'
    | 'van_id'
  >
>;

export type AssignmentPrecinct = Pick<ApiPrecinct, 'id' | 'name' | 'county'>;

/**
 * Type for a user along with the relevant information that’s relative to a
 * particular location on a given day.
 *
 * @see getUsersForLocationAssignment
 */
export type AssignmentUserForLocation = {
  user: AssignmentUser;

  /**
   * The availability time for this person on this day, for this location. Will
   * either be the eday availability tag or data derived from a
   * {@link ApiVolunteerAvailability} record.
   *
   * If this is null, it means we don’t have availability info for the person.
   * They will not be automatically assigned.
   */
  availabilityTime: VolunteerAvailabilityTime | null;

  /**
   * Array of reasons we aren’t recommending this assignment. Surfaced so that
   * VPDs can over-ride it during manual assignment.
   *
   * If this is empty then they are eligible for auto-assignment.
   */
  cantAssignReasons: CantAssignReason[];

  /**
   * Optimization score for this hypothetical assignment. Can be used to sort
   * users, often by subtracting their current assignment’s score.
   *
   * Note that a positive score does not mean that someone _should_ be assigned,
   * if `cantAssignReasons` is non-empty.
   */
  score: number;
  distanceMi: number | null;
};

/**
 * Compliment to {@link AssignmentUserForLocation} that includes current
 * assignments. Used during manual assignment, when it’s helpful to surface the
 * current assignments for a user.
 *
 * Split out because we don’t need it for auto-assignment, so it would be a
 * waste to calculate it for every location/user pair.
 */
export type UserCurrentAssignments = {
  /** If user is assigned somewhere else, this is that info. */
  currentAssignments: AssignmentRecord[];

  /** Score for the current assignments. */
  currentAssignmentScores: number[];
};

/**
 * Keeps track of whether the assignment was loaded from the server, explicitly
 * made by a person, our automatically made via the solver.
 *
 * Deletion and clearing is different for each one.
 */
export type AssignmentSource = 'server' | 'manual' | 'auto';

export type AssignmentRecord = {
  userId: number;
  date: DateString;
  startTime: TimeString;
  endTime: TimeString;
} & (
  | {
      source: 'manual' | 'auto';
      type: 'poll';
      locationId: number;
    }
  | ({
      source: 'server';
      id: number;
    } & (
      | {
          type: 'poll';
          locationId: number;
        }
      | {
          // We support other types for server assignments, e.g. BoE
          // assignments. We want to keep track of those so that volunteers
          // can’t get auto-assigned if they have a boe or hotline shift.
          type: 'board_of_elections' | 'hotline_center' | 'boiler_room';
        }
    ))
);

/**
 * Subset of assignment that we can use to uniquely identify it.
 */
export type AssignmentKey = Pick<
  AssignmentRecord & { type: 'poll' },
  'date' | 'userId' | 'locationId'
>;

export const DEFAULT_ASSIGNMENT_STATE: AssignmentState = {
  locationsById: castImmutable(new Map()),
  usersById: castImmutable(new Map()),

  assignmentsByDate: castImmutable(new Map()),
  availabilityByUserId: castImmutable(new Map()),

  lastLoadTime: null,
};

/**
 * Thrown when we attempt to dispatch an action that doesn’t match the current
 * mode.
 */
export class WrongModeError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export type AssignmentAction =
  | {
      /** Copy locations from the API response into state. */
      type: 'SYNC_LOCATIONS';
      // `Draft` since these will be writable (and AssignmentLocation by default
      // is not).
      locations: Draft<AssignmentLocation>[];
    }
  | {
      /** Copy users from the API response into state. */
      type: 'SYNC_USERS';
      // `Draft` since these will be writable (and AssignmentUser by default is
      // not).
      users: Draft<AssignmentUser>[];
    }
  | {
      /**
       * Overwrites all availability across all users. Meant to be applied from
       * server updates. Unlike other SYNC_ operations, must include all
       * availability records.
       */
      type: 'SYNC_ALL_AVAILABILITY';
      availability: ApiVolunteerAvailability[];
    }
  | {
      /** Updates `lastLoadTime` */
      type: 'FINISH_SYNC';
      time: Date;
    }
  | {
      /**
       * Add a single assignment to the state.
       *
       * Caller is responsible for deleting / soft-deleting any previous
       * assignments for the user on that date.
       */
      type: 'ADD_ASSIGNMENT';
      assignment: AssignmentRecord;
    }
  | {
      /** Add multiple assignments to the state. */
      type: 'ADD_ASSIGNMENTS';
      assignments: AssignmentRecord[];
    }
  | {
      /** Removes assignments on one or all days that match a given source. */
      type: 'CLEAR_ASSIGNMENTS';
      date: DateString | null;
      source: AssignmentSource;
    }
  | {
      /**
       * Removes any objects that don’t have one of these IDs. Used to clear
       * out stale things after syncing.
       */
      type: 'PRUNE_OBJECTS';
      locationIdSet: Set<number>;
      userIdSet: Set<number>;
      assignmentIdSet: Set<number>;
      precinctIdSet: Set<number>;
    }
  | {
      /** Deletes everything, sets to the default, empty state. */
      type: 'RESET';
    }
  | {
      /**
       * Removes an assignment. If we’re in live mode, assumes it has been
       * deleted from the server already. If we’re in draft mode, adds it to the
       * soft-deleted list if it’s a server assignment.
       */
      type: 'REMOVE_ASSIGNMENT';
      assignment: AssignmentKey;
    }
  | {
      /**
       * Given successfully-saved bulk updates, includes them in our copy of the
       * data.
       */
      type: 'APPLY_LOCATION_BULK_UPDATES';
      updates: ApiLocationBulkUpdate[];
    }
  | {
      /**
       * Applies an updated hours entry to a location.
       */
      type: 'UPDATE_HOURS';
      locationId: number;
      hours: AssignmentService.ApiLocationHours;
    }
  | {
      /**
       * Applies many new hours entries to locations.
       *
       * Used to apply the saved results of a bulk shift edit.
       */
      type: 'UPDATE_BULK_HOURS';
      hoursByLocationId: Immutable<
        Map<number, AssignmentService.ApiLocationHours[]>
      >;
    }
  | {
      /**
       * Resets the availability just for a single group, removing all existing
       * records and adding in the new ones.
       */
      type: 'UPDATE_AVAILABILITY_BY_GROUP';
      availabilityGroupId: number;
      availability: ApiVolunteerAvailability[];
    };

export type AssignmentDispatch = (action: AssignmentAction) => void;

/**
 * Immer-based reducer for {@link AssignmentState} that accepts
 * {@link AssignmentAction}s.
 */
export function assignmentReducer(
  state: AssignmentState,
  action: AssignmentAction
): AssignmentState {
  return produce(state, (draft) => {
    // Quick note in here that `draft` means Immer’s “draft of the next state”
    // and is not the same thing as whether or not the state is in “draft” or
    // “live” mode. Just keep that in mind when reading.
    switch (action.type) {
      case 'RESET':
        return castDraft(DEFAULT_ASSIGNMENT_STATE);

      case 'SYNC_LOCATIONS':
        action.locations.forEach((loc) => {
          draft.locationsById.set(loc.id, loc);
        });
        break;

      case 'APPLY_LOCATION_BULK_UPDATES':
        action.updates.forEach(({ id, ...update }) => {
          Object.assign(draft.locationsById.get(id) ?? {}, update);
        });
        break;

      case 'SYNC_USERS':
        action.users.forEach((user) => {
          draft.usersById.set(user.id, user);
        });
        break;

      case 'SYNC_ALL_AVAILABILITY':
        draft.availabilityByUserId = makeAvailabilityByUserIdMap(
          action.availability
        );
        break;

      case 'FINISH_SYNC':
        draft.lastLoadTime = action.time;
        break;

      case 'PRUNE_OBJECTS':
        for (const id of draft.locationsById.keys()) {
          if (!action.locationIdSet.has(id)) {
            draft.locationsById.delete(id);
          }
        }

        for (const id of draft.usersById.keys()) {
          if (!action.userIdSet.has(id)) {
            draft.usersById.delete(id);
          }
        }

        for (const date of draft.assignmentsByDate.keys()) {
          draft.assignmentsByDate.set(
            date,
            draft.assignmentsByDate
              .get(date)!
              .filter(
                (a) => a.source !== 'server' || action.assignmentIdSet.has(a.id)
              )
          );
        }

        break;

      case 'ADD_ASSIGNMENT':
        addAssignment(draft, action.assignment);
        break;

      case 'ADD_ASSIGNMENTS':
        action.assignments.forEach((assignment) =>
          addAssignment(draft, assignment)
        );
        break;

      case 'CLEAR_ASSIGNMENTS':
        (action.date
          ? [action.date]
          : [...draft.assignmentsByDate.keys()]
        ).forEach((date) => {
          const assignments = draft.assignmentsByDate.get(date);
          if (assignments) {
            draft.assignmentsByDate.set(
              date,
              assignments.filter((a) => a.source !== action.source)
            );
          }
        });
        break;

      case 'REMOVE_ASSIGNMENT': {
        // This action is dispatched when an assignment is removed. If we’re in
        // live mode, we assume that a successful API call to delete has already
        // happened.
        //
        // If we’re in draft mode, we soft-delete the assignment if it’s
        // server-source.
        const assignmentsOnDate =
          draft.assignmentsByDate.get(action.assignment.date) ?? [];
        const idx = assignmentsOnDate.findIndex(
          (a) =>
            a.type === 'poll' &&
            a.locationId === action.assignment.locationId &&
            a.userId === action.assignment.userId
        );

        if (idx !== -1) {
          assignmentsOnDate.splice(idx, 1)[0]!;
        }

        break;
      }

      case 'UPDATE_HOURS': {
        const location = draft.locationsById.get(action.locationId);
        if (location) {
          const idx = location.hours.findIndex(
            (h) => h.date === action.hours.date
          );

          if (idx >= 0) {
            location.hours[idx] = action.hours;
          }
        }

        break;
      }

      case 'UPDATE_BULK_HOURS':
        for (const [locId, hoursArr] of action.hoursByLocationId.entries()) {
          const location = draft.locationsById.get(locId);
          if (location) {
            location.hours = castDraft(
              mergeLocationHours(location.hours, hoursArr)
            );
          }
        }

        break;

      case 'UPDATE_AVAILABILITY_BY_GROUP': {
        // This action has 2 goals:
        //
        // - Remove any existing availability records that reference the given
        //   availability group.
        // - Add the new availability records to all relevant users.
        const newAvailabilityByUserId = makeAvailabilityByUserIdMap(
          action.availability
        );

        // Go through all existing availability records by user, removing stale
        // entries and adding new ones.
        for (const userId of draft.availabilityByUserId.keys()) {
          const prevAvailArr = draft.availabilityByUserId.get(userId)!;

          // For slightly better performance and maintaining mutability, we
          // only update the existing availability if we know it might change.
          if (
            prevAvailArr.findIndex(
              (a) => a.availability_group_id === action.availabilityGroupId
            ) !== -1 ||
            newAvailabilityByUserId.has(userId)
          ) {
            draft.availabilityByUserId.set(
              userId,
              prevAvailArr
                .filter(
                  (a) => a.availability_group_id !== action.availabilityGroupId
                )
                .concat(newAvailabilityByUserId.get(userId)!)
            );

            // Clear this from the map so we know what users we’ll need to add
            // at the end.
            newAvailabilityByUserId.delete(userId);
          }
        }

        // At this point there may still be entries in newAvailabilityByUserId
        // because they’re for users who didn’t previously have any availability
        // information, so we can simply copy them over now..
        for (const [
          newUserId,
          newAvailability,
        ] of newAvailabilityByUserId.entries()) {
          draft.availabilityByUserId.set(newUserId, newAvailability);
        }

        break;
      }

      default:
        assertUnreachable(action);
    }

    return draft;
  });
}

/**
 * Converts an array of availability records into a Map by userID.
 */
function makeAvailabilityByUserIdMap(availability: ApiVolunteerAvailability[]) {
  return availability.reduce((m, avail) => {
    if (!m.has(avail.user_id)) {
      m.set(avail.user_id, []);
    }

    // We’re ok with mutation while building up the data structure.
    m.get(avail.user_id)!.push(avail);

    return m;
  }, new Map());
}

/**
 * Returns an existing assignments for the user on a date.
 */
export function findExistingAssignments(
  state: AssignmentState,
  userId: number,
  date: DateString
): AssignmentRecord[] {
  return (
    state.assignmentsByDate.get(date)?.filter((a) => a.userId === userId) ?? []
  );
}

/**
 * Adds a single assignment record to the draft assignment state. (Immer’s
 * “draft” not to be confused with “draft mode.”)
 */
function addAssignment(
  draft: Draft<AssignmentState>,
  assignment: AssignmentRecord
) {
  let assignments = draft.assignmentsByDate.get(assignment.date);

  if (!assignments) {
    assignments = [];
    draft.assignmentsByDate.set(assignment.date, assignments);
  }

  if (assignment.source === 'server') {
    const existingIdx = assignments.findIndex(
      (a) => a.source === 'server' && a.id === assignment.id
    );

    if (existingIdx !== -1) {
      assignments.splice(existingIdx, 1);
    }
  }

  assignments.push(assignment);
}

/**
 * Returns users scored for the given location. Sorted by the biggest score
 * change in putting the user there (vs. their current assignment, if it
 * exists).
 *
 * Returns a record for all users in the system, but populates the
 * `cantAssignReasons` property to indicate problems with specific people.
 */
export function getUsersForLocationAssignment(
  assignmentState: AssignmentState,
  locationId: number,
  date: DateString,
  pollObserverRegistrationRequirement: PollObserverRegistrationRequirement,
  shiftScoreOptions: ShiftScoreOptions,
  getDistanceMi: DistanceMiLookupFunc,
  isEday: boolean
): (AssignmentUserForLocation & UserCurrentAssignments)[] {
  const location = assignmentState.locationsById.get(locationId)!;
  const assignmentsByUserId = getAssignmentsByUserIdForDates(assignmentState, [
    date,
  ]);

  const scoreConfig = makeShiftScoreConfig(shiftScoreOptions, assignmentState);
  const usersForLocation: (AssignmentUserForLocation &
    UserCurrentAssignments)[] = [];

  for (const user of assignmentState.usersById.values()) {
    const volunteerAttributes = getVolunteerAttributes(user);

    const currentAssignments = assignmentsByUserId.get(user.id) ?? [];
    const currentAssignmentScores = [];

    // Get scores for current assignment on this day so that the relative
    // difference in score can be used in recommendations.
    for (const currentAssignment of currentAssignments) {
      let currentAssignmentScore = 0;

      if (currentAssignment.type === 'poll') {
        const currentAssignmentLocation = assignmentState.locationsById.get(
          currentAssignment.locationId
        );

        // This should exist, but let’s not crash if it doesn’t.
        if (currentAssignmentLocation) {
          const currentAssignmentDistance = getDistanceMi(
            currentAssignment.locationId,
            user.id
          );

          currentAssignmentScore = getShiftScore(
            currentAssignmentLocation,
            volunteerAttributes,
            currentAssignmentDistance ?? 1000,
            scoreConfig
          );
        }
      }

      currentAssignmentScores.push(currentAssignmentScore);
    }

    const userForLocation = makeUserForLocation({
      user,
      location,
      date,
      isEday,
      availability: assignmentState.availabilityByUserId.get(user.id),
      pollObserverRegistrationRequirement,
      volunteerAttributes,
      scoreConfig,
      getDistanceMi,
    });

    usersForLocation.push({
      ...userForLocation,
      currentAssignments,
      currentAssignmentScores,
    });
  }

  return usersForLocation;
}

/**
 * Returns a {@link AssignmentUserForLocation} to be used in manual and
 * auto-assignment.
 */
export function makeUserForLocation({
  user,
  location,
  availability,
  date,
  isEday,
  volunteerAttributes,
  getDistanceMi,
  scoreConfig,
  maxDistanceCoeff = 1.0,
  pollObserverRegistrationRequirement = 'none',
}: {
  user: AssignmentUser;
  location: AssignmentLocation;
  availability: Immutable<ApiVolunteerAvailability[]> | undefined;
  date: DateString;
  isEday: boolean;
  volunteerAttributes: VolunteerAttributes;
  scoreConfig: ShiftScoreConfig;
  getDistanceMi: DistanceMiLookupFunc;
  /**
   * Factor to apply to the max distance. Used in auto-assignment so we don’t
   * assign someone within 90% of their max distance, to hand-wavily accommodate
   * “as the crow flies” distances.
   */
  maxDistanceCoeff?: number;
  pollObserverRegistrationRequirement?: PollObserverRegistrationRequirement;
}): AssignmentUserForLocation {
  const cantAssignReasons: CantAssignReason[] = [];

  if (
    (isEday && !volunteerAttributes.isEdayVolunteer) ||
    (!isEday && !volunteerAttributes.isEvVolunteer)
  ) {
    cantAssignReasons.push('missing volunteer tag');
  }

  // Some states require poll observers
  // be registered in the respective county
  if (pollObserverRegistrationRequirement === 'county') {
    if (
      user.county_name !== location.county.name ||
      user.state !== location.county.state
      // two counties in different states may have same name
    ) {
      cantAssignReasons.push('invalid registration');
    }
  }

  const distanceMi = getDistanceMi(location.id, user.id);

  const matchingAvailability = (availability ?? []).find(
    makeAvailabilityMatcher({ date, location, distanceMi, user })
  );

  let score: number;

  if (
    matchingAvailability &&
    !matchingAvailability.respect_travel_distance_tag
  ) {
    // We still want to score based on distance, even if we’re ignoring the
    // limitations, but we can make up a distance if we don’t have one.
    score = getShiftScore(
      location,
      volunteerAttributes,
      distanceMi ?? 1000,
      scoreConfig
    );
  } else {
    if (typeof user.max_distance_miles === 'number' && distanceMi !== null) {
      if (distanceMi > user.max_distance_miles * maxDistanceCoeff) {
        cantAssignReasons.push('too far');
      }
    } else {
      if (typeof user.max_distance_miles !== 'number') {
        cantAssignReasons.push('no distance preference');
      }

      if (distanceMi === null) {
        cantAssignReasons.push('distance unknown');
      }
    }

    score = getShiftScore(
      location,
      volunteerAttributes,
      distanceMi ?? 1000,
      scoreConfig
    );
  }

  return {
    user,
    distanceMi,
    score,
    cantAssignReasons,
    availabilityTime: isEday
      ? volunteerAttributes.edayAvailability
      : matchingAvailability
      ? availabilityTimeFromRecord(matchingAvailability)
      : null,
  };
}

export function availabilityTimeFromRecord(
  record: Immutable<ApiVolunteerAvailability>
): VolunteerAvailabilityTime | null {
  if (record.time === 'custom') {
    return record.custom_times;
  } else if (record.time === 'all_day') {
    return 'all day';
  } else {
    return record.time;
  }
}

export type AssignmentLoadingStage =
  | 'locations'
  | 'users'
  | 'precincts'
  | 'availability';

export type AssignmentLoadingStatus =
  | { status: 'initial' }
  | { status: 'loading'; stage: AssignmentLoadingStage; count: number }
  | { status: 'error' }
  | { status: 'done' };

export const LOADING_STAGE_TO_NOUN: {
  [stage in AssignmentLoadingStage]: string;
} = {
  locations: 'locations',
  users: 'volunteers',
  precincts: 'precincts',
  availability: 'availability',
};

/**
 * Loads all of the locations and users in the election and dispatches `SYNC`
 * actions as they come in. Also loads precincts.
 */
export async function* initializeAssignmentStore(
  dispatch: AssignmentDispatch
): AsyncGenerator<AssignmentLoadingStatus, AssignmentLoadingStatus> {
  const precinctIdSet = new Set<number>();
  const locationIdSet = new Set<number>();
  const userIdSet = new Set<number>();
  const assignmentIdSet = new Set<number>();

  // Max num of simultaneous asynchronous requests for locations and users
  const MAX_PARALLEL_REQUESTS = 4;

  try {
    // To notify user that we have started loading locations
    yield { status: 'loading', stage: 'locations', count: 0 };

    const locationRequestSize = 500;
    let lastLocationSize: number;
    let locationCount = 0;

    // While there are still locations to request, fire off
    // MAX_PARALLEL_REQUESTS asynchronously and wait for all
    // responses to be handled (add to locationIdSet and sync)
    // before firing the next batch of requests or moving on
    do {
      const promises: Promise<
        OffsetResponse<
          'locations',
          ApiLocation &
            AssignmentService.ExpandApiLocationHours &
            AssignmentService.ExpandApiAllPrecinctsIds
        >
      >[] = [];

      for (let i = 0; i < MAX_PARALLEL_REQUESTS; i += 1) {
        promises.push(
          AssignmentService.getLocationsWithHours({
            offset: locationCount + i * locationRequestSize,
            size: locationRequestSize,
          }).then((res) => {
            if (res) {
              res.locations.forEach((loc) => locationIdSet.add(loc.id));
              dispatch({
                type: 'SYNC_LOCATIONS',
                locations: res.locations,
              });
            }

            return res;
          })
        );
      }

      const responseArray = await Promise.all(promises);

      lastLocationSize = responseArray
        .map((i) => i.locations.length)
        .reduce((a, b) => a + b);
      locationCount += lastLocationSize;

      yield { status: 'loading', stage: 'locations', count: locationCount };
    } while (lastLocationSize === locationRequestSize * MAX_PARALLEL_REQUESTS);

    const userRequestSize = 500;
    let lastUserSize: number;
    let userCount = 0;

    // While there are still users to request, fire off
    // MAX_PARALLEL_REQUESTS asynchronously and wait for all
    // responses to be handled (add assignments and sync users)
    // before yielding an updated count and
    // firing the next batch of requests or moving on
    do {
      const promises: Promise<
        OffsetResponse<
          'users',
          UserService.ApiUser &
            UserService.ExpandApiUserPii &
            UserService.ExpandApiUserRelated
        >
      >[] = [];

      for (let i = 0; i < MAX_PARALLEL_REQUESTS; i += 1) {
        // TODO(fiona): Use an endpoint that doesn’t expand the contents of locations, &c.
        promises.push(
          UserService.getUserList<'pii,related'>({
            expand: 'pii,related',
            offset: userCount + i * userRequestSize,
            size: userRequestSize,
          }).then((res) => {
            res.users.forEach((u) => userIdSet.add(u.id));

            const assignments = res.users.flatMap((user) =>
              user.related.map<AssignmentRecord>((related) => {
                const assignment = related.assignment;

                assignmentIdSet.add(assignment.id);

                if (assignment.type === 'poll') {
                  return {
                    source: 'server',
                    id: assignment.id,
                    type: 'poll',
                    date: assignment.shift_date,
                    locationId: assignment.location!,
                    userId: assignment.user,
                    startTime: assignment.start_time,
                    endTime: assignment.end_time,
                  };
                } else {
                  return {
                    source: 'server',
                    id: assignment.id,
                    type: assignment.type,
                    date: assignment.shift_date,
                    userId: assignment.user,
                    startTime: assignment.start_time,
                    endTime: assignment.end_time,
                  };
                }
              })
            );

            dispatch({
              type: 'ADD_ASSIGNMENTS',
              assignments,
            });

            dispatch({ type: 'SYNC_USERS', users: res.users });

            return res;
          })
        );
      }

      const responseArray = await Promise.all(promises);

      lastUserSize = responseArray
        .map((i) => i.users.length)
        .reduce((a, b) => a + b);
      userCount += lastUserSize;

      yield { status: 'loading', stage: 'users', count: userCount };
    } while (lastUserSize === userRequestSize * MAX_PARALLEL_REQUESTS);

    const availabilitySize = 500;
    let availabilityOffset = 0;
    let lastAvailabilitySize: number;
    // We collect all availability and add it to our store at once.
    const allAvailability: ApiVolunteerAvailability[] = [];

    do {
      const availResp = await VolunteerAvailabilityService.getAvailabilityList({
        size: availabilitySize,
        offset: availabilityOffset,
      });

      lastAvailabilitySize = availResp.availability.length;
      availabilityOffset += availabilitySize;

      allAvailability.push(...availResp.availability);

      yield {
        status: 'loading',
        stage: 'availability',
        count: allAvailability.length,
      };
    } while (lastAvailabilitySize === availabilitySize);

    dispatch({
      type: 'SYNC_ALL_AVAILABILITY',
      availability: allAvailability,
    });

    dispatch({
      type: 'PRUNE_OBJECTS',
      locationIdSet,
      userIdSet,
      assignmentIdSet,
      precinctIdSet,
    });

    dispatch({ type: 'FINISH_SYNC', time: new Date() });

    return { status: 'done' };
  } catch (e: any) {
    console.error(e);
    captureException(e);

    return { status: 'error' };
  }
}

/**
 * Helper to return a map of all assignments by location, for a given date.
 */
export function getAssignmentsByLocationId(
  state: Pick<AssignmentState, 'assignmentsByDate' | 'locationsById'>
): Map<number, (AssignmentRecord & { type: 'poll' })[]> {
  const outMap = new Map<number, (AssignmentRecord & { type: 'poll' })[]>();

  for (const locationId of state.locationsById.keys()) {
    outMap.set(locationId, []);
  }

  for (const assignments of state.assignmentsByDate.values()) {
    assignments.forEach((assignment) => {
      if (assignment.type === 'poll') {
        outMap.get(assignment.locationId)?.push(assignment);
      }
    });
  }

  return outMap;
}

/**
 * Helper to return a map of all assignments by location, for a set of dates.
 *
 * Note that if there are multiple dates they’re all smooshed together in the
 * same array.
 */
export function getAssignmentsByLocationIdForDates(
  state: Pick<AssignmentState, 'assignmentsByDate' | 'locationsById'>,
  dates: DateString[],
  { ignoreAuto }: { ignoreAuto?: boolean } = {}
): Map<number, AssignmentRecord[]> {
  const outMap = new Map<number, AssignmentRecord[]>();

  for (const locationId of state.locationsById.keys()) {
    outMap.set(locationId, []);
  }

  for (const date of dates) {
    for (const assignment of state.assignmentsByDate.get(date) ?? []) {
      if (
        assignment.type === 'poll' &&
        (!ignoreAuto || assignment.source !== 'auto')
      ) {
        outMap.get(assignment.locationId)?.push(assignment);
      }
    }
  }

  return outMap;
}

/**
 * Helper to return all {@link AssignmentRecord}s, collected by user ID.
 */
export function getAssignmentsByUserId(
  state: Pick<AssignmentState, 'assignmentsByDate' | 'usersById'>
): Map<number, AssignmentRecord[]> {
  const outMap = new Map<number, AssignmentRecord[]>();

  for (const userId of state.usersById.keys()) {
    outMap.set(userId, []);
  }

  for (const assignments of state.assignmentsByDate.values()) {
    assignments.forEach((assignment) => {
      outMap.get(assignment.userId)?.push(assignment);
    });
  }

  return outMap;
}

/**
 * Helper to return all {@link AssignmentRecord}s for a given user within a set
 * of dates.
 */
export function getAssignmentsByUserIdForDates(
  state: Pick<AssignmentState, 'assignmentsByDate' | 'usersById'>,
  dates: DateString[],
  { ignoreAuto }: { ignoreAuto?: boolean } = {}
): Map<number, AssignmentRecord[]> {
  const outMap = new Map<number, AssignmentRecord[]>();

  for (const userId of state.usersById.keys()) {
    outMap.set(userId, []);
  }

  for (const date of dates) {
    for (const assignment of state.assignmentsByDate.get(date) ?? []) {
      if (!ignoreAuto || assignment.source !== 'auto')
        outMap.get(assignment.userId)?.push(assignment);
    }
  }

  return outMap;
}
