import React from 'react';

import Sidebar from '../../components/presentational/lbj/sidebar';
import { State } from '../../constants';

import * as Filters from './issues-list-filters';
import {
  IssuesListFilters,
  IssuesListFilterUpdater,
  IssuesListLbjData,
} from './issues-list-utils';

/**
 * Sidebar for {@link ResultsIndexView} to control filtering.
 */
const ResultsFilterSidebar: React.FunctionComponent<
  {
    canUseElectionSpecificFilters: boolean;
    filters: IssuesListFilters;
    updateFilters: IssuesListFilterUpdater;
    /**
     * The state for the currently nav-chosen election. This is equivalent of the
     * current user’s `assignment_state` but we’re getting at it a bit more
     * directly.
     *
     * This is different from the currently filtered state, for cases where the
     * election is national but we want to look at a particular state.
     */
    currentElectionState: State;
  } & IssuesListLbjData
> = ({
  canUseElectionSpecificFilters,
  filters,
  updateFilters,
  currentElectionState,
  elections,
  regions,
  counties,
  precincts,
  locations,
  districts,
}) => {
  const filterProps = { filters, updateFilters };

  return (
    <Sidebar title="FILTER / SEARCH" collapsible>
      <div className="lbj-column-content lbj-issue-filter-sidebar">
        <Filters.SearchTermFilter {...filterProps} />
        <Filters.ElectionFilter {...filterProps} elections={elections} />
      </div>

      <div className="lbj-column-label lbj-divider">
        <h4>Location Filters</h4>
      </div>

      <div className="lbj-column-content lbj-issue-filter-sidebar">
        <Filters.StateRegionCountyFilters
          {...filterProps}
          currentElectionState={currentElectionState}
          counties={counties}
          regions={regions}
        />

        {canUseElectionSpecificFilters && (
          <>
            <Filters.PrecinctLocationFilters
              {...filterProps}
              locations={locations}
              precincts={precincts}
            />

            <Filters.DistrictFilters {...filterProps} districts={districts} />
          </>
        )}
      </div>

      <div className="lbj-column-label lbj-divider">
        <h4>Other Filters</h4>
      </div>

      <div className="lbj-column-content lbj-issue-filter-sidebar">
        <Filters.StartEndDateFilters {...filterProps} />
        <Filters.StatusFilter {...filterProps} />
        <Filters.SourceFilter {...filterProps} />
      </div>
    </Sidebar>
  );
};

export default ResultsFilterSidebar;
