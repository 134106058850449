import React from 'react';

import trackGoogleAnalyticsEvent, {
  RawAnalyticsEvent,
} from '../../services/analytics-service';
import { DateString } from '../../services/common';
import type {
  ApiElectionDay,
  ApiLocationTierConfiguration,
  PollObserverRegistrationRequirement,
} from '../../services/lbj-shared-service';
import { useAsyncGenerator, useContinuity } from '../../utils/hooks';
import { solveMipProblem } from '../../utils/mip-solver/cbc-solver';
import {
  LpBooleanVariable,
  LpMaximumConstraint,
  LpProblem,
  LpSolution,
} from '../../utils/mip-solver/lp-problem';
import { numberToStringWithCommas } from '../../utils/strings';
import { neverResolve, neverReturn } from '../../utils/types';

import {
  AssignmentLocation,
  AssignmentRecord,
  AssignmentState,
  AssignmentUser,
  getAssignmentsByLocationIdForDates,
  getAssignmentsByUserIdForDates,
  makeUserForLocation,
} from './assignment-state';
import {
  DistanceMiLookupFunc,
  filterLocations,
  getShiftScore,
  getVolunteerAttributes,
  LocationFilterOptions,
  LocationShiftType,
  makeDistanceMiLookupFunc,
  makeShiftScoreConfig,
  shiftCountsForLocation,
  ShiftScoreOptions,
  shiftTimesForType,
} from './assignment-utils';

export type AssignmentSolvingStatus =
  | 'idle'
  | 'building-problem'
  | 'solving'
  | 'error';

/**
 * Generates assignments from the given state and voting dates.
 */
export async function* solveAssignments(
  state: AssignmentState,
  days: ApiElectionDay[],
  tierConfiguration: ApiLocationTierConfiguration[],
  locationFilters: LocationFilterOptions,
  pollObserverRegistrationRequirement: PollObserverRegistrationRequirement,
  userIdsToExclude: Set<number>,
  shiftScoreOptions: ShiftScoreOptions
): AsyncGenerator<
  AssignmentSolvingStatus,
  (AssignmentRecord & { source: 'auto' })[]
> {
  /* eslint-disable no-console */
  console.time('solveAssignments');

  yield 'building-problem';

  console.time('precomputing-distances');
  const getDistanceMi = makeDistanceMiLookupFunc(state);
  console.timeEnd('precomputing-distances');

  console.time('building-problem');
  // TODO(fiona): Make this an asynchronous process if it seems like it takes
  // too long and the UI freezes up.
  const problem: LpProblem = createAssignmentLpProblem(
    state,
    days,
    tierConfiguration,
    locationFilters,
    pollObserverRegistrationRequirement,
    userIdsToExclude,
    shiftScoreOptions,
    getDistanceMi
  );
  console.timeEnd('building-problem');

  yield 'solving';

  try {
    console.time('solving');
    // We put in a small gap to speed up solving, since we don’t need an optimal
    // optimal solution, just one that’s “usefully good”.
    const solution = await solveMipProblem(problem, { allowableGap: 4 });
    console.timeEnd('solving');

    console.time('building-assignments');
    const assignments = createAssignmentsFromLpSolution(state, solution);
    console.timeEnd('building-assignments');

    console.log(
      `Score: ${numberToStringWithCommas(solution.objectiveValue, {
        precision: 4,
      })}`
    );

    console.log(`Assignment count: ${assignments.length}`);

    console.log(
      `Total distance: ${numberToStringWithCommas(
        assignments.reduce(
          (val, assignment) =>
            // We know there will be a distance for every assignment made
            val + getDistanceMi(assignment.locationId, assignment.userId)!,
          0
        ),
        { precision: 0 }
      )}mi`
    );

    return assignments;
  } catch (e) {
    // In the error case we want to yield that there was a problem (and report
    // it to Sentry), but never actually _return_ from the generator, since we
    // want to keep our return type as purely `AssignmentRecord[]`.
    //
    // These errors are unlikely but possible since we don’t know 100% how CBC
    // is going to behave out in the wild.
    reportError(e);
    yield 'error';

    await neverResolve();
    neverReturn();
  } finally {
    console.timeEnd('solveAssignments');
  }
  /* eslint-enable no-console */
}

type LocationShiftVariables = { [shiftType in LocationShiftType]: string[] };

const SHIFT_TYPES: LocationShiftType[] = ['am', 'pm', 'day'];

/**
 * Generates the {@link LpProblem} for the given dates and location filters
 * based on the current state of users, locations, and assignments.
 *
 * The problem is structured as a set of boolean variables, with those variables
 * representing if a given user is assigned to a given location. This means that
 * there are potentially M x N x D variables (with M being the number of
 * locations, N being the number of users, and D the number of dates), but we
 * pre-prune assignments that we won’t allow, like if the location is beyond the
 * user’s willingness to travel or the location has no shifts on the date.
 *
 * Right now each day is modeled independently from the others. We combine them
 * all into the same problem to support the potential future work of:
 *
 * - Total shift constraints per volunteer (“only schedule me 3 times”)
 * - Affinity for locations (boost to schedule a volunteer somewhere they’re
 *   already assigned on a different day)
 */
export function createAssignmentLpProblem(
  state: AssignmentState,
  days: ApiElectionDay[],
  tierConfiguration: ApiLocationTierConfiguration[],
  locationFilters: LocationFilterOptions,
  pollObserverRegistrationRequirement: PollObserverRegistrationRequirement,
  userIdsToExclude: Set<number>,
  shiftScoreOptions: ShiftScoreOptions,
  getDistanceMi: DistanceMiLookupFunc
): LpProblem {
  const isEday = days.length === 1 && !days[0]!.is_early_vote;
  const dates = days.map((d) => d.date);

  const config = makeShiftScoreConfig(shiftScoreOptions, state);

  // We use the current assignments in the state to prevent those users from
  // being re-assigned, or those locations from being over-assigned.
  const assignmentsByLocationId = getAssignmentsByLocationIdForDates(
    state,
    dates,
    { ignoreAuto: true }
  );

  const assignmentsByUserId = getAssignmentsByUserIdForDates(state, dates, {
    ignoreAuto: true,
  });

  // Each of these variables represents an assignment of a particular user at a
  // particular location on a given day.
  const shiftVariables: LpBooleanVariable[] = [];

  // We keep these two maps so that it’s easy to write the constraints that keep
  // a user from getting more than one assignment or a location from getting
  // more assignments than necessary.
  //
  // We pre-popluate the maps with all of our dates so we can just `get(date)!`
  // later without checking.
  const shiftVariableNamesByDateAndLocationId = new Map<
    DateString,
    Map<number, LocationShiftVariables>
  >();
  const shiftVariableNamesByDayAndUserId = new Map<
    DateString,
    Map<number, string[]>
  >();

  /**
   * Keep track of which locations have shift change times, so we know to solve
   * for am/pm shifts for them.
   */
  const locationIdsWithShiftChangeByDate = new Map<DateString, Set<number>>();

  // Pre-populate our maps with all of the dates.
  for (const date of dates) {
    shiftVariableNamesByDateAndLocationId.set(date, new Map());
    shiftVariableNamesByDayAndUserId.set(date, new Map());

    locationIdsWithShiftChangeByDate.set(date, new Set());
  }

  const locations = filterLocations({
    assignmentState: state,
    tierConfiguration,
    // We don’t want to accidentally limit by a name string.
    nameFilter: '',
    filterOptions: locationFilters,
    lastSuggestedAssignments: [],
  });

  /**
   * Pre-calculated {@link VolunteerAttributes} entries for all of our users.
   */
  const volunteerAttributesByUserId = new Map(
    [...state.usersById.values()].map((u) => [u.id, getVolunteerAttributes(u)])
  );

  for (const loc of locations) {
    for (const date of dates) {
      const locationHours = loc.hours.find((h) => h.date === date);

      // Throw out locations that aren’t open on this day.
      if (!locationHours || locationHours.closed) {
        continue;
      }

      if (locationHours.shift_change_time !== null) {
        locationIdsWithShiftChangeByDate.get(date)!.add(loc.id);
      }

      shiftVariableNamesByDateAndLocationId.get(date)!.set(loc.id, {
        am: [],
        pm: [],
        day: [],
      });
    }
  }

  for (const user of state.usersById.values()) {
    if (userIdsToExclude.has(user.id)) {
      continue;
    }

    const { isEdayVolunteer, isEvVolunteer } = volunteerAttributesByUserId.get(
      user.id
    )!;

    // Short-circuit out users we know won’t be assigned due to their tags.
    // Reduces the amount of user iteration we need to do later. (We’d still
    // reject them later due to cantAssignReasons, but might as well avoid them
    // now.)
    //
    // (We do this outside of the date loop because we’re guaranteed that
    // `dates` is either just Eday or all EV.)
    if ((isEday && !isEdayVolunteer) || (!isEday && !isEvVolunteer)) {
      continue;
    }

    for (const date of dates) {
      // Throw out users who have already been assigned on this day. Unlike with
      // locations, we don’t bother to do this in the constraints. If we know the
      // user can’t be assigned today, it’s best to leave them out entirely to
      // keep the problem smaller.
      if (
        (assignmentsByUserId.get(user.id) ?? []).find((a) => a.date === date)
      ) {
        continue;
      }

      shiftVariableNamesByDayAndUserId.get(date)!.set(user.id, []);
    }
  }

  for (const date of dates) {
    // At this point, the keys for shiftVariableNamesByLocationId and
    // shiftVariableNamesByUserId are the only location IDs and user IDs we want
    // to consider (respectively).

    for (const locationId of shiftVariableNamesByDateAndLocationId
      .get(date)!
      .keys()) {
      const location = state.locationsById.get(locationId)!;
      // We know hours is defined for this date, otherwise we wouldn’t be trying
      // to schedule here.
      const hours = location.hours.find((d) => d.date === date)!;

      for (const userId of shiftVariableNamesByDayAndUserId.get(date)!.keys()) {
        const user = state.usersById.get(userId)!;
        const volunteerAttributes = volunteerAttributesByUserId.get(user.id)!;

        const userForLocation = makeUserForLocation({
          user,
          location,
          availability: state.availabilityByUserId.get(userId),
          date,
          getDistanceMi,
          isEday,
          scoreConfig: config,
          volunteerAttributes,
          // We only auto-assign up to 90% of the stated max distance because
          // these distances are “as the crow flies” rather than travel
          // distance.
          maxDistanceCoeff: 0.9,
          pollObserverRegistrationRequirement,
        });

        // If there’s any reason, we skip.
        if (userForLocation.cantAssignReasons.length > 0) {
          continue;
        }

        for (const shiftType of SHIFT_TYPES) {
          if (
            shiftType !== 'day' &&
            !locationIdsWithShiftChangeByDate.get(date)!.has(locationId)
          ) {
            continue;
          }

          switch (userForLocation.availabilityTime) {
            case 'am':
              if (shiftType !== 'am') {
                continue;
              }
              break;

            case 'pm':
              if (shiftType !== 'pm') {
                continue;
              }
              break;

            case 'either':
              if (shiftType === 'day') {
                continue;
              }
              break;

            case 'all day':
              // We’re okay with all shifts.
              break;

            case null:
              // No availability, can’t schedule them here.
              continue;

            default: {
              const shiftStart =
                shiftType === 'am' || shiftType === 'day'
                  ? hours.open_time
                  : // We know there’s a shift change time if we’re populating a
                    // non-day shift.
                    hours.shift_change_time!;

              const shiftEnd =
                shiftType === 'pm' || shiftType === 'day'
                  ? hours.close_time
                  : // We know there’s a shift change time if we’re populating a
                    // non-day shift.
                    hours.shift_change_time!;

              const hasAvailableTimeRangeForShift =
                !!userForLocation.availabilityTime.find(
                  ([start, end]) => start <= shiftStart && end >= shiftEnd
                );

              if (!hasAvailableTimeRangeForShift) {
                continue;
              }

              break;
            }
          }

          // The variable name is something where we can easily parse back out
          // the relevant date, location ID, and user ID. (We could keep a map
          // of variable index to metadata, but this is more resilient.)
          //
          // Remove the dashes from the date string since they’re not legal in
          // variable names.
          const varName = `LxU~${date.replaceAll(
            '-',
            ''
          )}~${locationId}~${userId}~${shiftType}`;

          let score = userForLocation.score;

          if (shiftType === 'day') {
            // We simulate the value of "day" assignments by saying it’s as if
            // they did both a morning and afternoon and didn’t have to travel
            // to the afternoon, which should typically make this preferrable to
            // having two different people come.
            score =
              score + getShiftScore(location, volunteerAttributes, 0, config);
          }

          shiftVariables.push({
            name: varName,
            coefficient: score,
          });

          shiftVariableNamesByDateAndLocationId
            .get(date)!
            .get(locationId)!
            [shiftType].push(varName);

          shiftVariableNamesByDayAndUserId
            .get(date)!
            .get(userId)!
            .push(varName);
        }
      }
    }
  }

  /**
   * Makes constraints among all of a location’s variables. Ensures that there
   * are no more shifts than the given day’s limit, which is determined by
   * calling {@link shiftCountsForLocation} and takes into account per-location
   * overrides and the location’s ranking.
   *
   * Makes one constraint for AM and one for PM. “All Day” shifts appear in both
   * lists.
   */
  const makeLocationConstraints = (
    date: DateString,
    location: Pick<AssignmentLocation, 'id' | 'state_rank' | 'hours'>,
    perDayVariables: LocationShiftVariables,
    tierConfiguration: ApiLocationTierConfiguration[]
  ): LpMaximumConstraint[] => {
    // We know this will exist.
    const hours = location.hours.find((h) => h.date === date)!;

    /**
     * Existing assignments at this location for this date, so that we can count
     * them when limiting the # of shifts for the location.
     */
    const existingAssignments = (
      assignmentsByLocationId.get(location.id) ?? []
    ).filter((a) => a.date === date);

    // Because hours strings are 0-padded, 24-hour time, we can do string
    // comparisons with them.
    //
    // We consider any shift that starts on or before the location opens to
    // fulfill an am shift, and any shift that ends on or after the location
    // closes to fulfill a pm shift.
    //
    // If the location only has all-day shifts these constraints will end up
    // being identical, which is no big deal because the solver will throw one
    // set out.
    //
    // TODO(fiona): Use calculateAssignmentShiftCoverage

    const amAssignmentCount = existingAssignments.filter(
      (a) => a.startTime <= hours.open_time
    ).length;

    const pmAssignmentCount = existingAssignments.filter(
      (a) => a.endTime >= hours.close_time
    ).length;

    const [amShiftCount, pmShiftCount] = shiftCountsForLocation(
      location,
      hours,
      tierConfiguration
    );

    return [
      {
        variableNames: [...perDayVariables.am, ...perDayVariables.day],
        maximum: Math.max(0, amShiftCount - amAssignmentCount),
      },
      {
        variableNames: [...perDayVariables.pm, ...perDayVariables.day],
        maximum: Math.max(0, pmShiftCount - pmAssignmentCount),
      },
    ];
  };

  /**
   * All users are constrained to one assignment per day. We don’t need to
   * consider existing assignments since we already pruned them out above.
   */
  const makeUserConstraint = (
    perDayVariables: string[]
  ): LpMaximumConstraint => ({
    variableNames: perDayVariables,
    maximum: 1,
  });

  // TODO(fiona): Cross-day location affinity?
  //
  // Make per-user/location variables for being there twice, three times, 4
  // times, 5 times, (either &c. or stop at some point). Give them increasing
  // boosts (either linear or more) in the scoring function. (E.g. 0.05, 0.1,
  // 0.15).
  //
  // Add constraints that sum all of a user’s shifts at a particular location to
  // be >= 0. Add the boost variables with negative coefficients:
  //
  // (shifts for user at loc) + -2 * (boost for 2) + -3 * (boost for 3) >= 0.
  //
  // The particular “boost” variables will not be able to be 1 unless there are
  // enough shifts to keep these constraints from going into the negative.
  //
  // Can account for already-assigned shifts at the locations by adding constant
  // 1s for each shift.
  //
  // Could also use binary to reduce the number of boost variables: boost for 1,
  // boost for 2, boost for 4 would cover the cases 0–7. Side effect is the
  // constant 1 boost for all user/location pairs with an assignment, which
  // could be turned off with constraint: -1 * b1 + 2 * b2 + 2 * b4 >= 0. (Keeps
  // b1 from activating on its own.)
  //
  // Should likely be an option because it could add some time to the solve.

  return {
    goal: 'maximize',
    constraints: [
      ...dates.flatMap((date) =>
        [...shiftVariableNamesByDateAndLocationId.get(date)!.entries()].flatMap(
          ([locId, variables]) =>
            makeLocationConstraints(
              date,
              state.locationsById.get(locId)!,
              variables,
              tierConfiguration
            )
        )
      ),
      ...dates.flatMap((date) =>
        [...shiftVariableNamesByDayAndUserId.get(date)!.entries()].map(
          ([, variables]) => makeUserConstraint(variables)
        )
      ),
    ],
    booleans: shiftVariables,
  };
}

export function getUserLanguageNames(user: Pick<AssignmentUser, 'tags'>) {
  return user.tags
    .filter((t) => t.startsWith('speaks'))
    .map((t) => {
      const language = t.replace('speaks_', '');
      return language.charAt(0).toUpperCase() + language.substring(1);
    });
}

/** Regexp to undo the YYYY-MM-DD -> YYYYMMDD we did in variable names. */
const VARIABLE_DATE_REGEXP = /([0-9]{4})([0-9]{2})([0-9]{2})/;

/**
 * Given a solution to an LP problem created by
 * {@link createAssignmentLpProblem}, returns a list of the assignments
 * represented by the “true” variables the solver found.
 */
export function createAssignmentsFromLpSolution(
  state: AssignmentState,
  solution: LpSolution
): (AssignmentRecord & { source: 'auto' })[] {
  return (
    solution.variables
      // Only consider variables that are set. (In some cases CBC will also
      // return variables set to 0.)
      .filter(({ value }) => value === 1)
      .map(({ name }) => {
        // variable names are of the form "LxU~20241031-1234~5555~am" where the
        // bits separated by tildes are the date, the location id, the user id,
        // and the shift time, respectively.
        const [, dateStr, locIdStr, userIdStr, shiftTypeStr] = name.split('~');

        const [, yearStr, monthStr, dayStr] =
          dateStr!.match(VARIABLE_DATE_REGEXP)!;
        const date = `${yearStr}-${monthStr}-${dayStr}` as DateString;

        const locationId = parseInt(locIdStr!);
        const locationHours = state.locationsById
          .get(locationId)
          ?.hours.find((h) => h.date === date);

        if (!locationHours) {
          // This should not come up, since the only locations provided to the
          // solver for a date are ones with `hours` for that solver.
          throw new Error(
            `Missing location hours for location ${locationId} on ${date}`
          );
        }

        const [startTime, endTime] = shiftTimesForType(
          locationHours,
          shiftTypeStr as LocationShiftType
        );

        return {
          type: 'poll',
          date,
          locationId: locationId,
          userId: parseInt(userIdStr!),
          // We know these will be defined because the assigner won’t be
          // generating partial-day shifts if they’re not configured.
          startTime: startTime!,
          endTime: endTime!,
          source: 'auto',
        };
      })
  );
}

export type AssignmentSolverStats = {
  timeMs: number;
};

/**
 * Hook to trigger solving of assignments. Returns a function that takes filter
 * options and, when called, starts a solving process. On successful solve, will
 * call its callback with the results.
 *
 * Also returns the ongoing status of the solve.
 */
export function useAssignmentSolver(
  assignmentState: AssignmentState,
  locationTierConfiguration: ApiLocationTierConfiguration[],
  shiftScoreOptions: ShiftScoreOptions,
  pollObserverRegistrationRequirement: PollObserverRegistrationRequirement,
  onSuccess: (
    assignments: (AssignmentRecord & { source: 'auto' })[],
    stats: AssignmentSolverStats
  ) => void
): [
  (
    days: ApiElectionDay[],
    locationFilters: LocationFilterOptions,
    userIdsToExclude: Set<number>
  ) => void,
  AssignmentSolvingStatus
] {
  const startTimeMsRef = React.useRef(0);

  shiftScoreOptions = useContinuity(shiftScoreOptions);

  const onSuccessRef = React.useRef(onSuccess);
  React.useEffect(() => {
    onSuccessRef.current = onSuccess;
  });

  const [assignmentSolverGen, setAssignmentSolverGen] =
    React.useState<ReturnType<typeof solveAssignments> | null>(null);

  const assignmentSolverResult = useAsyncGenerator(assignmentSolverGen);

  React.useEffect(() => {
    if (assignmentSolverResult?.done) {
      const durationMs = Date.now() - startTimeMsRef.current;
      onSuccessRef.current(assignmentSolverResult.value, {
        timeMs: durationMs,
      });
      const eventArgs: RawAnalyticsEvent = {
        category: 'Auto-Assignments - Solve Complete (ms)',
        action: 'solve_complete',
        label: `Selected Date: ${assignmentSolverResult.value[0]?.date} ; Assignments generated: ${assignmentSolverResult.value.length}`,
        value: durationMs,
      };
      trackGoogleAnalyticsEvent('auto_assign_solve_complete', eventArgs);
    }
  }, [assignmentSolverResult]);

  const solve = React.useCallback(
    (
      days: ApiElectionDay[],
      locationFilters: LocationFilterOptions,
      userIdsToExclude: Set<number>
    ) => {
      startTimeMsRef.current = Date.now();
      setAssignmentSolverGen(
        solveAssignments(
          assignmentState,
          days,
          locationTierConfiguration,
          locationFilters,
          pollObserverRegistrationRequirement,
          userIdsToExclude,
          shiftScoreOptions
        )
      );
    },
    [
      assignmentState,
      locationTierConfiguration,
      shiftScoreOptions,
      pollObserverRegistrationRequirement,
    ]
  );

  const status: AssignmentSolvingStatus =
    assignmentSolverResult?.done === true
      ? 'idle'
      : assignmentSolverResult?.value ?? 'idle';

  return [solve, status];
}
