import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { boilerRoomLevels, ROLE_CODES, UserRole } from '../../constants';
import { ExistingIssueInProgress } from '../../modules/issue/reducers';
import { ApiIssueHistory } from '../../services/issue-service';
import { ApiBoilerRoom } from '../../services/lbj-shared-service';
import { ApiUser, ExpandApiUserPii } from '../../services/user-service';
import RIT from '../../utils/render-if-truthy';
import { MapFromJs } from '../../utils/types';

export default class IssueHistory extends React.Component<
  {
    issueInProgress: MapFromJs<ExistingIssueInProgress>;
    historyIsLoading: boolean;
  },
  {
    showAll: boolean;
  }
> {
  constructor(props: IssueHistory['props']) {
    super(props);

    this.state = {
      showAll: false,
    };

    // TODO(fiona): Move this somewhere common!
    moment.updateLocale('en', {
      relativeTime: {
        future: '1s ago',
        s: '%ds',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1 month',
        MM: '%d months',
        y: '1 year',
        yy: '%d years',
      },
    });
  }

  getUserName(
    user: MapFromJs<ApiUser> | MapFromJs<ApiUser & ExpandApiUserPii>
  ) {
    const userRole: UserRole = user.get('role');
    const roleCode = ROLE_CODES[userRole] || 'N/A';
    const stateCode = user.get('assignment_state') || 'N/A';
    const label = `${stateCode} ${roleCode}`.trim();

    // "as any" in here since user could either have PII or not, depending on
    // the access level of the viewer.
    if (
      user.get('phone_number' as any) &&
      user.get('role') === 'poll_observer'
    ) {
      return `${user.get('first_name')} ${user.get(
        'last_name'
      )} (${label}) Cell Phone: ${user.get('phone_number' as any)} `;
    }

    return `${user.get('first_name')} ${user.get('last_name')} (${label}) `;
  }

  getBoilerRoomLevel(boilerRoom: MapFromJs<ApiBoilerRoom>) {
    return boilerRoomLevels.indexOf(boilerRoom.get('level'));
  }

  getEscalationPath(
    oldRoom: MapFromJs<ApiBoilerRoom>,
    newRoom: MapFromJs<ApiBoilerRoom>
  ) {
    const newLevel = this.getBoilerRoomLevel(newRoom);
    const oldLevel = this.getBoilerRoomLevel(oldRoom);
    let verbed;

    if (newLevel > oldLevel) {
      verbed = 'escalated';
    } else if (newLevel < oldLevel) {
      verbed = 'deescalated';
    } else {
      verbed = 'assigned';
    }

    return verbed;
  }

  showAll(event: React.MouseEvent) {
    event.preventDefault();
    this.setState({
      showAll: true,
    });
  }

  renderCreationAction(action: MapFromJs<ApiIssueHistory>) {
    const assignedBoilerRoom = action.getIn([
      'changes',
      'boiler_room',
      'current',
      'name',
    ]);

    return (
      <span className="action-text">
        created this issue (routed to {assignedBoilerRoom}).
      </span>
    );
  }

  renderResolutionModification(action: MapFromJs<ApiIssueHistory>) {
    const isResolved = action.getIn(['changes', 'resolved', 'current']);
    let copy;

    if (isResolved) {
      copy = 'marked this issue as resolved';
    } else {
      copy = 'marked this issue as unresolved.';
    }

    return <span className="action-text">{copy}</span>;
  }

  renderBoilerRoomModification(action: MapFromJs<ApiIssueHistory>) {
    const newRoom = action.getIn([
      'changes',
      'boiler_room',
      'current',
    ]) as MapFromJs<ApiBoilerRoom>;
    const oldRoom = action.getIn([
      'changes',
      'boiler_room',
      'previous',
    ]) as MapFromJs<ApiBoilerRoom>;
    const escalationPath = this.getEscalationPath(oldRoom, newRoom);

    return (
      <span className="action-text">
        {escalationPath} this issue from {oldRoom.get('name')} to{' '}
        {newRoom.get('name')}.
      </span>
    );
  }

  renderOwnerModification(action: MapFromJs<ApiIssueHistory>) {
    const newOwner = action.getIn([
      'changes',
      'owner',
      'current',
    ]) as MapFromJs<ApiUser>;
    const user = action.get('user');

    if (newOwner && user.get('id') === newOwner.get('id')) {
      return <span className="action-text">claimed this issue.</span>;
    } else if (newOwner) {
      return (
        <span className="action-text">
          assigned this issue to{' '}
          <Link to={`/users/${newOwner.get('id')}`}>
            <strong>{this.getUserName(newOwner)}</strong>
          </Link>
          .
        </span>
      );
    }

    return <span className="action-text">unassigned this issue.</span>;
  }

  renderModificationAction(action: MapFromJs<ApiIssueHistory>) {
    if (action.hasIn(['changes', 'resolved'])) {
      return this.renderResolutionModification(action);
    }

    if (action.hasIn(['changes', 'boiler_room'])) {
      return this.renderBoilerRoomModification(action);
    }

    if (action.hasIn(['changes', 'owner'])) {
      return this.renderOwnerModification(action);
    }
  }

  renderFallbackAction() {
    return <span className="action-text">Updated this issue.</span>;
  }

  renderAction(action: MapFromJs<ApiIssueHistory>, key: string | number) {
    const type = action.get('action') as ApiIssueHistory['action'];
    let text;

    if (type === 'creation') {
      text = this.renderCreationAction(action);
    } else if (type === 'modification') {
      text = this.renderModificationAction(action);
    } else {
      text = this.renderFallbackAction();
    }

    if (text) {
      const userName = this.getUserName(action.get('user'));
      const timestamp = action.get('timestamp');
      const time = moment(timestamp);
      const timeLabel = time.fromNow();
      const timeTitle = time.format('MMM. Do, YYYY @ h:mma');

      return (
        <p key={key}>
          <time className="action-time" title={timeTitle} dateTime={timestamp}>
            {timeLabel}
          </time>
          <Link to={`/users/${action.getIn(['user', 'id'])}`}>
            <strong>{userName}</strong>
          </Link>
          {text}
        </p>
      );
    }
  }

  renderActions() {
    const { issueInProgress } = this.props;
    const { showAll } = this.state;
    const actions = issueInProgress.get('history');

    if (actions) {
      if (!showAll) {
        return actions.reverse().take(5).map(this.renderAction.bind(this));
      }

      return actions.reverse().map(this.renderAction.bind(this));
    }
  }

  renderShowAll() {
    const { showAll } = this.state;
    const { issueInProgress } = this.props;
    const actions = issueInProgress.get('history');

    if (!showAll && actions.size > 5) {
      return (
        <p className="issue-history-more">
          <a href="#" onClick={this.showAll.bind(this)}>
            More
          </a>
        </p>
      );
    }
  }

  render() {
    const { issueInProgress, historyIsLoading } = this.props;

    return (
      <div className="issue-history">
        {RIT(issueInProgress.has('history'), () => {
          return (
            <div>
              <div className="issue-history-actions">
                {this.renderActions()}
              </div>

              {this.renderShowAll()}
            </div>
          );
        })}

        {RIT(historyIsLoading && !issueInProgress.has('history'), () => {
          return <span>Loading issue history...</span>;
        })}
      </div>
    );
  }
}
